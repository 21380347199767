import React, { useState } from "react";
import Layout from "../WebsiteComponents/Layout";
import "../WebsiteComponents/HeaderCss.css";

const ProductsScreen = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Layout>
      {/*  */}
      <div
        className="py-2 sm:py-40"
        style={{
          backgroundImage: "url(../static/72.png)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "absolute", // Corrected typo
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundOrigin: "content-box",
        }}
      >
        <p
          style={{ "-webkit-text-stroke": "1px black", color: "#13123D" }}
          className="text-3xl font-bold sm:text-6xl  text-center pt-12  font-serif"
        >
          One-stop integrated EV
        </p>
        <p
          style={{ "-webkit-text-stroke": "1px black", color: "#13123D" }}
          className="text-3xl font-bold sm:text-6xl text-white text-center  font-serif  sm:mt-6"
        >
          charging Solution provider
        </p>
        <p
          style={{ "-webkit-text-stroke": "1px black", color: "#13123D" }}
          className="text-3xl font-bold sm:text-6xl text-white text-center  font-serif  sm:mt-6"
        >
          with state-of-the-art EV
        </p>
        <p
          style={{ "-webkit-text-stroke": "1px black", color: "#13123D" }}
          className="text-3xl font-bold sm:text-6xl text-white text-center pb-12 font-serif  sm:mt-6"
        >
          charging equipments
        </p>
      </div>

      {/*  */}
      <div className="bg-white flex justify-around sm:py-24 flex-col sm:flex-row">
        <div className=" mt-7 sm:ml-20">
          <p
            style={{ letterSpacing: 2 }}
            className="text-3xl text-center sm:hidden mb-5 sm:mb-0 font-serif   font-semibold mt-2 "
          >
            Fast AC Chargers
          </p>
          <div>
            <p
              style={{ letterSpacing: 2 }}
              className="sm:text-6xl text-3xl text-left hidden font-serif  sm:block   font-bold my-2 "
            >
              Fast AC Chargers
            </p>
          </div>

          <div>
            <div className="hidden sm:block mt-9">
              <p className="text-5xl font-serif  text-left ">
                Smart Chargers for Home and Commercial Spaces
              </p>
              <p className=" text-2xl text-left font-serif  mt-4">
                {" "}
                • Intuitive user interface
              </p>
              <p className="text-2xl text-left font-serif ">
                {" "}
                • RFID reader for user authentication
              </p>
              <p className="text-2xl text-left font-serif ">
                {" "}
                • OCPP Charging Protocol
              </p>
              <p className="text-left text-2xl font-serif ">
                {" "}
                • Output Rating of 3.6 or 7.4 kW
              </p>
              <p className="text-2xl text-left mb-10 font-serif ">
                {" "}
                • CE, ICE 61851-1, ICE 61851-1 compliant{" "}
              </p>
            </div>
          </div>
        </div>
        <div className=" flex h-32 sm:h-auto justify-center  items-center sm:w-2/4  overflow-hidden">
          <img
            // style={{ position: "absolute" }}
            alt="Img"
            className="h-32 sm:h-80 "
            src="../../static/28.png"
          />
        </div>
        <div className="sm:hidden font-serif block mt-9 px-5">
          <p className="text-2xl font-serif text-left ">
            Smart Chargers for Home and Commercial Spaces
          </p>

          <p className="text-left mt-4"> • Intuitive user interface</p>
          <p className="text-left"> • RFID reader for user authentication</p>
          <p className="text-left"> • OCPP Charging Protocol</p>
          <p className="text-left"> • Output Rating of 3.6 or 7.4 kW</p>
          <p className="text-left mb-10">
            {" "}
            • CE, ICE 61851-1, ICE 61851-1 compliant{" "}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ProductsScreen;
