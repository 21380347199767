import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const UserDetailsScreen = () => {
  function onChange(date, dateString) {
    setDateRange(dateString);
  }
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const [idTags, setIdTags] = useState();
  const [formatedUserdata, setFormatedUserData] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [updatedRfidIssuedStatus, setUpdatedRfidIssueStatus] = useState();

  const [updatedWalletAmount, setUpdatedWalletAmount] = useState();
  const [updatedRFIDblockedAmount, setUpdatedRFIDblockedAmount] = useState();
  const [updatedIdTag, setUpdatedidTag] = useState();
  const [messageE, setMessage] = useState();

  const [selectedMobile, setSelectedMobile] = useState();
  const [selectedIdTag, setSelectedIdTag] = useState();
  const [selectedUserCaptiveCodes, setSelectedUserCaptiveCodes] = useState();
  const [dateRange, setDateRange] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [showsUserCaptiveCodesModelOpen, setShowsUserCaptiveCodesModelOpen] =
    useState(false);

  const [newlyaddedLocationId, setnewlyAddedLocationId] = useState();
  const [updated3PinSocketAccess, setUpdated3PinSocketAccess] = useState();

  useEffect(() => {
    setSelectedUserCaptiveCodes(selectedUser?.captiveCodes);
  }, [selectedUser]);

  useEffect(() => {
    if (!selectedIdTag && !selectedMobile && !selectedLocation) {
      setfilterSet(false);
      filterData();
    } else {
      setfilterSet(true);
      filterData();
    }
  }, [selectedIdTag, selectedMobile, selectedLocation]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/admin/users/details`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
        
        setLocations(res?.data?.locations);
        setIdTags(res?.data?.idTags);
        formatUserData(res?.data?.users, res?.data?.locations);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function convertToDottedForm(inputString) {
    // Split the input string into parts
    let parts = inputString.split("");
    let dottedForm = "";

    for (let i = 0; i < inputString.length; i++) {
      if (i === inputString?.length - 1) {
        dottedForm = dottedForm + inputString[i];
      } else {
        dottedForm = dottedForm + inputString[i] + ".";
      }
    }
    return dottedForm;
  }

  const formatUserData = (users, location) => {
    setLoading(true);
    try {
      let array = users;
      array?.map((user) => {
        user.appVersion = user?.appVersion
          ? convertToDottedForm(user?.appVersion)
          : "";

        let vehicles = "";
        let vehicleNumbers = "";
        user.phone = user?.phone?.toString();
        user.createdAt = user?.createdAt?.split("T")[0];
        user.sortingDate =
          user?.createdAt?.split("T")[0].split("-").join("") || 0;

        user?.vehicles?.map((vName, index) => {
          if (index === 0) {
            // console.log(vName?.manufacturerName);
            if (vName?.manufacturerName === "Two Wheeler") {
              vehicles =
                vName?.manufacturerName + " (" + vName?.vehicleNumber + ") ";
            } else {
              vehicles = vName?.fullName + " (" + vName?.vehicleNumber + ") ";
            }
          } else {
            if (vName?.manufacturerName === "Two Wheeler") {
              vehicles =
                vehicles +
                " , " +
                vName?.manufacturerName +
                " (" +
                vName?.vehicleNumber +
                ") ";
            } else {
              vehicles =
                vehicles +
                " , " +
                vName?.fullName +
                " (" +
                vName?.vehicleNumber +
                ") ";
            }
          }
        });

        user.totalCaptiveCodes = user?.captiveCodes?.length;
        user.Access3PinSocket = user?.Access3PinSocket?.toString() || "false";
        user.rfidIssued = user?.rfidIssued?.toString() || "false";

        const captiveArray = [];

        user?.captiveCodes?.map((captiveCode, index) => {
          if (index === 0) {
            location?.map((location) => {
              if (captiveCode === location?._id) {
                captiveArray?.push({
                  captiveCode: location?.address,
                  locationId: captiveCode,
                });
              }
            });
          } else {
            location?.map((location) => {
              if (captiveCode === location?._id) {
                captiveArray?.push({
                  captiveCode: location?.address,
                  locationId: captiveCode,
                });
              }
            });
          }
        });
        user.vehicles = vehicles;
        user.vehicleNumbers = vehicleNumbers;
        user.captiveCodes = captiveArray;
      });
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setFormatedUserData(array);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterData = () => {
    try {
      if (selectedLocation && selectedMobile && selectedIdTag) {
        // If Alll filters are applied
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          user?.captiveCodes?.map((captiveCode) => {
            if (
              captiveCode?.locationId === selectedLocation &&
              user?.phone === selectedMobile &&
              user?.idTag === selectedIdTag
            ) {
              resultArray.push(user);
            }
          });
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedLocation && selectedIdTag) {
        // If Alll filters are applied
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          user?.captiveCodes?.map((captiveCode) => {
            if (
              captiveCode?.locationId === selectedLocation &&
              user?.idTag === selectedIdTag
            ) {
              resultArray.push(user);
            }
          });
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedMobile && selectedIdTag) {
        // If Alll filters are applied
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.phone === selectedMobile && user?.idTag === selectedIdTag) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedLocation && selectedMobile) {
        // If Alll filters are applied
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          user?.captiveCodes?.map((captiveCode) => {
            if (
              captiveCode?.locationId === selectedLocation &&
              user?.phone === selectedMobile
            ) {
              resultArray.push(user);
            }
          });
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedMobile) {
        // If only selectedmobile is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.phone === selectedMobile) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedLocation) {
        // If only IdTag is Selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          user?.captiveCodes?.map((captiveCode) => {
            if (captiveCode?.locationId === selectedLocation) {
              resultArray.push(user);
            }
          });
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedIdTag) {
        // If only IdTag is Selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.idTag === selectedIdTag) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else {
        setFormatedUserData(unchangedFormatedTransactions);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 8,
          },
          wrapperCol: {
            span: 92,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 50,
            offset: 40,
            position: "absolute",
          },
        }
      : null;

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      title: "Date of Registration",
      dataIndex: "createdAt",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.sortingDate - b.sortingDate,
      width: 120,
    },
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: 120,
    },
    {
      title: "Email",
      dataIndex: `email`,
      width: 230,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: 120,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      sorter: (a, b) => a.rating - b.rating,
      width: 120,
    },
    {
      title: "Wallet Amount",
      dataIndex: "walletAmount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.walletAmount - b.walletAmount,
      width: 120,
    },
    {
      title: "IdTag",
      dataIndex: "idTag",
      width: 150,
    },
    {
      title: "RFID Card Issued",
      dataIndex: "rfidIssued",
      width: 100,
    },

    {
      title: "RFID Blocked Amount",
      dataIndex: "rfidBlockedAmount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.rfidBlockedAmount - b.rfidBlockedAmount,
      width: 100,
    },
    {
      title: "3 Pin Socket Access",
      dataIndex: "Access3PinSocket",
      width: 100,
    },

    {
      title: "Vehicles",
      dataIndex: "vehicles",
      width: 150,
    },
    {
      title: "Access Locations",
      dataIndex: "totalCaptiveCodes",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.totalCaptiveCodes - b.totalCaptiveCodes,
      width: 80,
    },
    {
      title: "App Version",
      dataIndex: "appVersion",
      width: 100,
    },
    {
      title: "  Action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            className="m-5"
            onClick={() => {
              setSelectedUser(record);
              setShowsUserCaptiveCodesModelOpen(true);
            }}
          >
            <i style={{ color: "green" }} class="fa-solid fa-circle-info"></i>
          </a>
          <a
            className="m-5"
            onClick={() => {
              setSelectedUser(record);
              setEditUserModalOpen(true);
            }}
          >
            <i
              style={{ color: "blue" }}
              class="fa-regular fa-pen-to-square"
            ></i>
          </a>
        </div>
      ),
    },
  ];

  const updateUserDetails = async () => {
    try {
      setLoading(true);
      if (
        !updatedIdTag &&
        !updatedWalletAmount &&
        updatedRFIDblockedAmount &&
        updatedRfidIssuedStatus === undefined &&
        updated3PinSocketAccess === undefined
      ) {
        message.error("No change found");
      } else {
        const res = await axios.post(
          `${BaseUrl}/update/user/details/by/admin`,
          {
            token: localStorage.getItem("token"),
            _id: selectedUser?._id,
            idTag: updatedIdTag || selectedUser?.idTag,
            walletAmount: updatedWalletAmount || selectedUser?.walletAmount,
            rfidBlockedAmount:
              updatedRFIDblockedAmount || selectedUser?.rfidBlockedAmount,
            rfidIssued: updatedRfidIssuedStatus,
            Access3PinSocket: updated3PinSocketAccess,
          }
        );
        if (res?.data?.success) {
          setfilterSet(false);
          setSelectedLocation(undefined);
          setSelectedMobile(undefined);
          setSelectedIdTag(undefined);
          getUsers();
          setEditUserModalOpen(false);
          setLoading(false);
          message.success("Updated successfully");
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserCaptiveCodes = async (locationId) => {
    try {
      setLoading(true);
      if (!locationId) {
        message.error("No change found");
      } else {
        const res = await axios.post(
          `${BaseUrl}/delete/user/captiveCode/by/admin`,
          {
            token: localStorage.getItem("token"),
            locationId,
            userId: selectedUser?._id,
          }
        );
        if (res?.data?.success) {
          setfilterSet(false);
          setSelectedLocation(undefined);
          setSelectedMobile(undefined);
          setSelectedIdTag(undefined);
          getUsers();
          setShowsUserCaptiveCodesModelOpen(false);
          setLoading(false);
          message.success("Deleted successfully");
        } else {
          message?.warning(res?.data?.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addUserCaptiveCode = async () => {
    try {
      if (!newlyaddedLocationId) {
        message.warning("Location not selected...");
      } else {
        setLoading(true);
        const res = await axios.post(
          `${BaseUrl}/add/user/captiveCode/by/admin`,
          {
            token: localStorage.getItem("token"),
            locationId: newlyaddedLocationId,
            userId: selectedUser?._id,
          }
        );
        if (res?.data?.success) {
          setfilterSet(false);
          setSelectedLocation(undefined);
          setSelectedMobile(undefined);
          setSelectedIdTag(undefined);
          getUsers();
          setShowsUserCaptiveCodesModelOpen(false);
          setLoading(false);
          message.success("Added successfully");
        } else {
          message?.warning(res?.data?.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Edit User Modal */}
      <div>{messageE}</div>
      <Modal
        title="Edit User Details"
        centered
        open={editUserModalOpen}
        onCancel={() => setEditUserModalOpen(false)}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={form}
        >
          <Form.Item label="Name">
            <Input disabled placeholder={selectedUser?.name} />
          </Form.Item>
          <Form.Item placeholder="Email" label="Email">
            <Input disabled placeholder={selectedUser?.email} />
          </Form.Item>
          <Form.Item label="Phone">
            <Input disabled placeholder={selectedUser?.phone} />
          </Form.Item>
          <Form.Item label="IdTag">
            <Input
              maxLength={14}
              showCount
              onChange={(e) => setUpdatedidTag(e.target.value)}
              placeholder={selectedUser?.idTag}
            />
          </Form.Item>
          <Form.Item label="Access 3 Pin">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={selectedUser?.Access3PinSocket}
              onChange={(value) => setUpdated3PinSocketAccess(value)}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </Form.Item>
          <Form.Item label="Wallet Amount">
            <Input
              itemType="numeric"
              onChange={(e) => setUpdatedWalletAmount(e.target.value)}
              placeholder={selectedUser?.walletAmount}
            />
          </Form.Item>
          <Form.Item label="RFID Blocked Amount">
            <Input
              itemType="numeric"
              onChange={(e) => setUpdatedRFIDblockedAmount(e.target.value)}
              placeholder={selectedUser?.rfidBlockedAmount}
            />
          </Form.Item>
          <Form.Item label="RFID Card Issued">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={selectedUser?.rfidIssued}
              onChange={(value) => setUpdatedRfidIssueStatus(value)}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                updateUserDetails();
              }}
              style={{ position: "absolute", right: 10 }}
              type="default"
            >
              Update
            </Button>
            <Button
              onClick={() => setEditUserModalOpen(false)}
              style={{ position: "absolute", right: 100 }}
              type="dashed"
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        // title={` Captive codes`}
        centered
        open={showsUserCaptiveCodesModelOpen}
        onCancel={() => setShowsUserCaptiveCodesModelOpen(false)}
        footer={[null]}
      >
        <p className="font-bold mt-2">Accepted CGL Policy </p>
        <p
          style={{
            paddingRight: "2%",
            paddingLeft: "2%",
          }}
        >
          {selectedUser?.acceptedCGLPolicy?.split("GMT")[0] || "No"}
        </p>
        <p className="font-bold mt-4">Accepted Two Wheeler Policy</p>
        <p
          style={{
            paddingRight: "2%",
            paddingLeft: "2%",
          }}
        >
          {selectedUser?.acceptedTwoWheelerPolicy?.split("GMT")[0] || "No"}
        </p>

        <p className="font-bold mt-4">Captive Codes </p>
        <div className="mt-3">
          {" "}
          <a style={{ color: "black" }}>Add CC </a>
          <Select
            className="ml-2"
            showSearch
            style={{ width: 300 }}
            placeholder="Select location"
            optionFilterProp="children"
            onChange={(value) => setnewlyAddedLocationId(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations &&
              locations?.map((location) => {
                return (
                  <Option style={{ fontSize: 12 }} value={location?._id}>
                    {location?.address}
                  </Option>
                );
              })}
          </Select>
          <a
            onClick={() => addUserCaptiveCode()}
            style={{
              borderWidth: 1,
              paddingRight: 20,
              paddingLeft: 20,
              paddingTop: 7,
              paddingBottom: 8,
              borderRadius: 10,
              marginLeft: 5,
              backgroundColor: "#ADD8E6",
              color: "black",
            }}
          >
            Submit
          </a>
        </div>

        <div className="flex flex-ror flex-wrap mt-4">
          {selectedUserCaptiveCodes?.map((captiveCode, index) => {
            return (
              <div className="border-2 flex justify-center w-1/2 p-3">
                <p
                  style={{
                    // backgroundColor: index % 2 ? "#C5C6D0" : "",
                    paddingRight: "2%",
                    paddingLeft: "2%",
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  {captiveCode?.captiveCode?.slice(0, 40)}...{" "}
                </p>
                <i
                  onClick={() => {
                    updateUserCaptiveCodes(captiveCode?.locationId);
                  }}
                  class="fa-solid fa-trash-can  cursor-pointer mt-10"
                ></i>
              </div>
            );
          })}
        </div>
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          User Details
        </p>
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        {/* Select User */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}> User</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 300 }}
            placeholder="Select User"
            optionFilterProp="children"
            onChange={(value) => setSelectedMobile(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {/* <Option style={{ fontSize: 12 }} value={undefined}>
              {"🧑🏻‍💼  All Users"}
            </Option> */}
            {users &&
              users?.map((user) => {
                return (
                  <Option style={{ fontSize: 12 }} value={user?.phone}>
                    {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                  </Option>
                );
              })}
          </Select>
        </div>

        {/* Select IdTag */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>IdTag</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select IdTag"
            optionFilterProp="children"
            onChange={(value) => setSelectedIdTag(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {idTags &&
              idTags?.map((idTag) => {
                return (
                  <Option style={{ fontSize: 12 }} value={idTag}>
                    {idTag}
                  </Option>
                );
              })}
          </Select>
        </div>

        {/* For location Filter */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>Location</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 300 }}
            placeholder="Select location"
            optionFilterProp="children"
            onChange={(value) => setSelectedLocation(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations &&
              locations?.map((location) => {
                return (
                  <Option style={{ fontSize: 12 }} value={location?._id}>
                    {location?.address}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <div className="flex">
          <a
            style={{ display: filterSet ? "block" : "none" }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md "
          >
            Filters :
          </a>
          <div className="ml-4 mt-1 flex">
            {filterSet && selectedLocation ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                Location Id : {selectedLocation}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedLocation(undefined);
                      filterData();
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}
            {filterSet && selectedMobile ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                Mobile : {selectedMobile}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedMobile(undefined);
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}

            {filterSet && selectedIdTag ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                IdTag : {selectedIdTag}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedIdTag(undefined);
                      filterData();
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        {filterSet ? (
          <a
            onClick={() => {
              setfilterSet(false);
              setSelectedLocation(undefined);
              setSelectedMobile(undefined);
              setSelectedIdTag(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Showing Locations */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedUserdata && formatedUserdata}
          onChange={onChange}
          scroll={{
            y: 600,
          }}
        />
      </div>
    </div>
  );
};

export default UserDetailsScreen;
