import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  Button,
  Form,
  Input,
  Modal,
  message,
  InputNumber,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";
import FormItem from "antd/es/form/FormItem";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const LocationDetailsScreen = () => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState();
  const [formatedlocationsData, setLocationFormatedData] = useState();
  const [captiveCodeModalOpen, setCaptiveCodeModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState(false);
  const [blockDetailsModalOpen, setBlocksDetailsModalOpen] = useState(false);
  const [editBlockDetailsModalOpen, setEditBlockDetailsModalOpen] =
    useState(false);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedBlockChargers, setSelectedBlockChargers] = useState([]);
  const [addNewBlockModelOpen, setAddNewBlockModelOpen] = useState(false);

  const [allChargersAtLocation, setAllChargersAtLocation] = useState([]);

  const [connfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState();
  const [allStates, setAllStates] = useState();
  const [allPinCodes, setAllPinCodes] = useState();
  const [confirmDeleteBlockDataModalOpen, setCinfirmDeleteBlockDataModalOpen] =
    useState(false);

  const [selectedPinCode, setSelectedPinCode] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [dateRange, setDateRange] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();

  const [newLocationAddress, setNewLocationAddress] = useState();
  const [newLocationState, setNewLocationState] = useState();
  const [newLocationPincode, setNewLocationPincode] = useState();
  const [newLocationCity, setNewLocationCity] = useState();
  const [newLocationLongitude, setNewLocationLongitude] = useState();
  const [newLocationLatitude, setNewLocationLatitude] = useState();
  const [newLocationRestricted, setNewLocationRestricted] = useState();
  const [newCGLPrice, setNewCGLPrice] = useState();
  const [newBlockName, setNewBlockName] = useState("");
  const [enableLoadBalancingForNewBlock, setEnabledLoadBalancingForNewBlock] =
    useState(false);

  const [updatedCGLPrice, setUpdatedCGLPrice] = useState();
  const [updatedLocationAddress, setUpdatedLocationAddress] = useState(
    selectedLocation?.address
  );
  const [updatedLocationState, setUpdatedLocationState] = useState(
    selectedLocation?.state
  );
  const [updatedLocationPincode, setUpdatedLocationPincode] = useState(
    selectedLocation?.pincode
  );
  const [updatedLocationCity, setUpdatedLocationCity] = useState(
    selectedLocation?.city
  );
  const [updatedLocationLongitude, setUpdatedLocationLongitude] = useState(
    selectedLocation?.longitude
  );
  const [updatedLocationLatitude, setUpdatedLocationLatitude] = useState(
    selectedLocation?.latitude
  );
  const [updatedLocationRestricted, setUpdatedLocationRestricted] = useState(
    selectedLocation?.restricted
  );

  const [totalInput, setTotalInput] = useState(null);
  const [totalInputForNewBlock, setTotalInputForNewBlock] = useState(null);

  const [enableLoadBalancing, setEnabledLoadBalancing] = useState(null);

  useEffect(() => {
    setUpdatedLocationAddress(selectedLocation?.address);
    setUpdatedLocationState(selectedLocation?.state);
    setUpdatedLocationPincode(selectedLocation?.pincode);
    setUpdatedLocationCity(selectedLocation?.city);
    setUpdatedLocationLongitude(selectedLocation?.longitude);
    setUpdatedLocationLatitude(selectedLocation?.latitude);
    setUpdatedLocationRestricted(selectedLocation?.restricted);
    setUpdatedCGLPrice(selectedLocation?.CGLfees);
  }, [selectedLocation]);

  function onChange(date, dateString) {
    setDateRange(dateString);
  }

  useEffect(() => {
    if (!selectedAddress && !selectedPinCode && !selectedState) {
      setfilterSet(false);
      filterData();
    } else {
      setfilterSet(true);
      filterData();
    }
  }, [selectedAddress, selectedPinCode, selectedState]);

  const getLocations = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/chargers/with/captiveCode`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setLocations(res?.data?.locations);

        setAllStates(res?.data?.states);
        setAllPinCodes(res?.data?.pincodes);
        filterData();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);
  useEffect(() => {
    formatBookingData(locations);
  }, [locations]);

  const formatBookingData = (locations) => {
    setLoading(true);
    try {
      let array = locations;
      array?.map((location) => {
        location.restricted = location.restricted.toString();
        location.createdAt = location.createdAt.split("T")[0];
        location.sortingDate = location.createdAt
          .split("T")[0]
          .split("-")
          .join("");
      });
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setLocationFormatedData(array);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  // Filter Daata
  const filterData = async () => {
    try {
      setLoading(true);

      // If All Filters were Added
      if (selectedAddress && selectedPinCode && selectedState) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          // let createdAt = location?.createdAt
          //   ?.split("T")[0]
          //   .split("-")
          //   .join("");
          // let from = dateRange[0].split("-").join("");
          // let to = dateRange[1].split("-").join("");
          if (
            // createdAt >= from &&
            // createdAt <= to &&
            location?.address === selectedAddress &&
            location?.pincode === Number(selectedPinCode) &&
            location?.state === selectedState
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (selectedAddress && selectedPinCode) {
        // If Date Range and Pin code is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          // let createdAt = location?.createdAt
          //   ?.split("T")[0]
          //   .split("-")
          //   .join("");
          // let from = dateRange[0].split("-").join("");
          // let to = dateRange[1].split("-").join("");
          if (
            // createdAt >= from &&
            // createdAt <= to &&
            location?.address === selectedAddress &&
            location?.pincode === Number(selectedPinCode)
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (selectedAddress && selectedState) {
        // If DateRange and state is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          // let createdAt = location?.createdAt
          //   ?.split("T")[0]
          //   .split("-")
          //   .join("");
          // let from = dateRange[0].split("-").join("");
          // let to = dateRange[1].split("-").join("");
          if (
            // createdAt >= from &&
            // createdAt <= to &&
            location?.address === selectedAddress &&
            location?.state === selectedState
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (selectedState && selectedPinCode) {
        // If State and Pincode is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          if (
            location?.pincode === Number(selectedPinCode) &&
            location?.state === selectedState
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (selectedAddress) {
        // If only Date range is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          // let createdAt = location?.createdAt
          //   ?.split("T")[0]
          //   .split("-")
          //   .join("");
          // let from = dateRange[0].split("-").join("");
          // let to = dateRange[1].split("-").join("");
          if (
            // createdAt >= from && createdAt <= to
            location?.address == selectedAddress
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (selectedPinCode) {
        // If only Pincode is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array.map((location) => {
          if (location?.pincode === Number(selectedPinCode)) {
            resultedArray.push(location);
          }
        });
        setLocationFormatedData(resultedArray);
        setLoading(false);
      } else if (selectedState) {
        // If only State is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array.map((location) => {
          if (location?.state === selectedState) {
            resultedArray.push(location);
          }
        });
        setLocationFormatedData(resultedArray);
        setLoading(false);
      } else {
        setLocationFormatedData(unchangedFormatedTransactions);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    // {
    //   title: "Date of Installation",
    //   dataIndex: "createdAt",
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.sortingDate - b.sortingDate,
    // },
    {
      title: "Address",
      dataIndex: "address",
      fixed: "left",
      width: 200,
    },
    {
      title: "Pin code",
      dataIndex: `pincode`,
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "latitude",
      dataIndex: "latitude",
    },
    {
      title: "longitude",
      dataIndex: "longitude",
    },
    {
      title: "Restricted",
      dataIndex: `restricted`,
    },
    {
      title: "CGL Fee",
      dataIndex: "gunLockCharges",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <a
            onClick={() => {
              setSelectedLocation(record);
              setCaptiveCodeModalOpen(true);
            }}
          >
            <i
              style={{ color: "green" }}
              class="fa-brands fa-codiepie ml-3"
            ></i>
          </a>

          <a
            onClick={() => {
              setSelectedLocation(record);
              setBlocksDetailsModalOpen(true);
            }}
          >
            <i style={{ color: "red" }} class="fa-solid fa-chart-line ml-3"></i>
          </a>

          <a
            className="ml-6"
            onClick={() => {
              setSelectedLocation(record);
              setEditLocationModalOpen(true);
            }}
          >
            <i
              style={{ color: "blue" }}
              class="fa-regular fa-pen-to-square"
            ></i>
          </a>
        </>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [AddLocationForm] = Form.useForm();

  const handleAddNewLocation = async () => {
    try {
      if (
        !newLocationAddress ||
        !newLocationPincode ||
        !newLocationLatitude ||
        !newLocationLongitude ||
        !newLocationCity ||
        !newLocationState ||
        !newLocationRestricted ||
        !newCGLPrice
      ) {
        message.error("All fields required");
      } else {
        setLoading(true);
        const res = await axios.post(`${BaseUrl}/add/new/charger/location`, {
          token: localStorage.getItem("token"),
          address: newLocationAddress,
          pincode: newLocationPincode,
          latitude: newLocationLatitude,
          longitude: newLocationLongitude,
          city: newLocationCity,
          state: newLocationState,
          restricted: newLocationRestricted,
          CGLfees: newCGLPrice,
        });
        if (res?.data?.success) {
          message.success("New Location Added");
          setAddNewLocationModalOpen(false);
          getLocations();
          setLoading(false);
        } else {
          message.error(res?.data?.message);
          setLoading(false);
        }
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateLocationDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${BaseUrl}/update/location/details`, {
        token: localStorage.getItem("token"),
        _id: selectedLocation?._id,
        address: updatedLocationAddress,
        pincode: updatedLocationPincode,
        latitude: updatedLocationLatitude,
        longitude: updatedLocationLongitude,
        city: updatedLocationCity,
        state: updatedLocationState,
        restricted: updatedLocationRestricted,
        CGLfees: updatedCGLPrice,
      });
      if (res?.data?.success) {
        message.success("Location details updated");
        setEditLocationModalOpen(false);
        setfilterSet(false);
        setSelectedAddress(undefined);
        setSelectedPinCode(undefined);
        setSelectedState(undefined);
        getLocations();
        setLoading(false);
      } else {
        message.error("Error in updating details");
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteLocation = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${BaseUrl}/delete/location`, {
        token: localStorage.getItem("token"),
        _id: selectedLocation._id,
      });
      if (res?.data?.success) {
        message.success("Location deleted");
        getLocations();
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Error in deleting location");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const [formLayout, setFormLayout] = useState("horizontal");
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 50,
            offset: 40,
            position: "absolute",
          },
        }
      : null;

  const handleGetAllChargersAtLocation = async (locationId, block) => {
    try {
      setBlocksDetailsModalOpen(false);
      setLoading(true);
      const res = await axios.post(
        `${BaseUrl}/admin/get/chargers/at/specific/location`,
        {
          token: localStorage.getItem("token"),
          locationId,
        }
      );

      if (res?.data?.success) {
        setAllChargersAtLocation(res?.data?.chargers);
        // console.log(res?.data?.chargers);

        const BlockChargers = res?.data?.chargers
          ?.filter((data) => data?.block === block)
          ?.map((data) => data?.chargerName);
        // console.log("Block Charegrs => " + BlockChargers);
        console.log(res?.data?.chargers);

        setSelectedBlockChargers(BlockChargers);

        setEditBlockDetailsModalOpen(true);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Error in fetching chargers");
      }
    } catch (error) {
      setLoading(false);
      message.error("Error in fetching chargers");
      // console.log(error);
    }
  };

  function toggleBlockCharger(BlockChargers, chargerName) {
    try {
      setLoading(true);
      // Check if chargerName exists in the array
      if (BlockChargers.includes(chargerName)) {
        // Remove the chargerName if it exists
        setSelectedBlockChargers(
          BlockChargers.filter((name) => name !== chargerName)
        );
        return setLoading(false);
      } else {
        // Add the chargerName if it doesn't exist
        setSelectedBlockChargers([...BlockChargers, chargerName]);
        return setLoading(false);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  const handleUpdateBlockDetails = async (
    locationId,
    blockName,
    chargerNames,
    TotalInput,
    d
  ) => {
    try {
      setLoading(true);
      // console.log(d, enableLoadBalancing);

      const res = await axios.post(
        `${BaseUrl}/admin/update/block/details/ofSpecific/location`,
        {
          token: localStorage.getItem("token"),
          locationId,
          blockName,
          chargerNames,
          TotalInput,
          enableLoadBalancing,
        }
      );
      
      if (res?.data?.success) {
        message.success("Block Updated Successfully");
        getLocations();
        setEditBlockDetailsModalOpen(false);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Error in updating block details");
      }
      setEnabledLoadBalancing(null);
      setTotalInput(null);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSaveNewBlockDetails = async (
    locationId,
    Block,
    TotalInput,
    enableLoadBalancing
  ) => {
    try {
      setLoading(true);
      if (!Block || !TotalInput) {
        message.error("Please fill all the fields");
        setLoading(false);
      } else {
        const res = await axios.post(
          `${BaseUrl}/admin/add/block/toSpecific/location`,
          {
            token: localStorage.getItem("token"),
            locationId,
            blockData: {
              Block,
              TotalInput,
              enableLoadBalancing,
            },
          }
        );
        if (res?.data?.success) {
          message.success("Block Added Successfully");
          getLocations();
          setNewBlockName(null);
          setTotalInputForNewBlock(null);
          setAddNewBlockModelOpen(false);
          setEditBlockDetailsModalOpen(false);
          setBlocksDetailsModalOpen(false);
          setLoading(false);
        } else {
          setLoading(false);
          message.error(res?.data?.message);
        }
        setEnabledLoadBalancing(null);
        setTotalInput(null);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteBlockData = async (locationId, blockName) => {
    try {
      // setLoading(true);
      // console.log(d, enableLoadBalancing);

      const res = await axios.post(
        `${BaseUrl}/admin/delete/block/data/ofSpecific/location`,
        {
          token: localStorage.getItem("token"),
          locationId,
          blockName,
        }
      );

      if (res?.data?.success) {
        message.success("Block Deleted Successfully");
        getLocations();
        setEditBlockDetailsModalOpen(false);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Error in updating block details");
      }
      setEnabledLoadBalancingForNewBlock(null);
      setTotalInputForNewBlock(null);
    } catch (error) {
      // console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* CaptiveCodeModal */}
      <Modal
        title="Captive Code"
        centered
        open={captiveCodeModalOpen}
        onCancel={() => setCaptiveCodeModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setCaptiveCodeModalOpen(false)}>Close</Button>,
        ]}
      >
        <p className="pt-2">
          Captive Code for {selectedLocation && selectedLocation?.address}
        </p>
        <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p>
      </Modal>

      {/* Block Details Modal */}
      <Modal
        title="Blocks Details :"
        centered
        open={blockDetailsModalOpen}
        onCancel={() => setBlocksDetailsModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setBlocksDetailsModalOpen(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedLocation?.blocksDetails?.map((block, index) => (
          <div
            key={index}
            style={{
              borderColor: block.enableLoadBalancing ? "green" : "gray",
            }}
            className="relative block-card border-2  p-2 rounded-lg mt-5"
          >
            <h3 className="text-lg font-semibold text-gray-900">
              {block.Block}
            </h3>
            <p className="text-sm mt-3 font-semibold text-black">
              Total Input :{" "}
              <span className="font-normal ">{block.TotalInput} kW</span>
            </p>
            <p className="text-sm font-semibold text-black">
              Load Balancing Enabled:{" "}
              <span className="font-normal ">
                {" "}
                {block.enableLoadBalancing ? "Yes" : "No"}
              </span>
            </p>
            <p className="text-sm mt-5 font-semibold text-black">
              Chargers :{" "}
              {block?.chargers?.length ? (
                <div className="mt-2 flex flex-wrap ">
                  {block?.chargers?.map((charger, index) => {
                    return (
                      <p className="border mx-2 px-2 py-1 rounded-lg  my-1 bg-blue-50">
                        {charger}
                      </p>
                    );
                  })}
                </div>
              ) : (
                <a
                  onClick={() => {
                    setSelectedBlock(block);
                    handleGetAllChargersAtLocation(
                      selectedLocation?._id,
                      block?.Block
                    );
                  }}
                  className="font-normal text-blue-600 text-sm cursor-pointer"
                >
                  {" "}
                  Add chargers
                </a>
              )}
            </p>
            <div className="absolute top-5 right-5 ">
              <a
                className="ml-6"
                onClick={() => {
                  setSelectedBlock(block);
                  handleGetAllChargersAtLocation(
                    selectedLocation?._id,
                    block?.Block
                  );
                }}
              >
                <i class="fa-regular fa-pen-to-square text-blue-500"></i>
              </a>
            </div>
          </div>
        ))}

        {addNewBlockModelOpen ? (
          <div className="bg-blue-100 rounded-xl px-3 py-2 mt-5">
            <p className="mt-5 font-semibold">Add New Block</p>
            <div className="flex flex-col justify-center mt-2">
              <div className="flex mb-5 ">
                <p className="mr-2 mt-1">Block Name: </p>
                <Input
                  className="text-center"
                  type="text"
                  maxLength={10}
                  placeholder={"Enter Block Name"}
                  onChange={(e) => {
                    setNewBlockName(e?.target?.value);
                  }}
                  style={{
                    width: 161,
                  }}
                />
              </div>
              <div className="flex mb-5 ">
                <p className="mr-2 mt-1">Total Input (kW): </p>
                <Input
                  className="text-center"
                  type="number"
                  maxLength={6}
                  minLength={2}
                  placeholder={"Enter Power Input"}
                  onChange={(e) => {
                    setTotalInputForNewBlock(e?.target?.value);
                    console.log(e.target?.value);
                  }}
                  style={{
                    width: 133,
                  }}
                />
              </div>
              <div className="flex mb-5">
                <p className="mr-2 mt-1">Load Balancing Enabled : </p>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={selectedBlock?.enableLoadBalancing}
                  defaultValue={false}
                  onChange={(value) =>
                    setEnabledLoadBalancingForNewBlock(value)
                  }
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Select>
              </div>
            </div>
            <div className="flex justify-around mb-2">
              <p
                onClick={() => {
                  handleSaveNewBlockDetails(
                    selectedLocation?._id,
                    newBlockName,
                    totalInputForNewBlock,
                    enableLoadBalancingForNewBlock
                  );
                }}
                className="cursor-pointer border-gray-700 px-10 py-1 rounded-lg bg-green-300 text-gray-700"
              >
                Save
              </p>
              <p
                onClick={() => setAddNewBlockModelOpen(false)}
                className="cursor-pointer border-gray-700 px-10 py-1 rounded-lg bg-red-300 text-gray-700"
              >
                Cancel
              </p>
            </div>
          </div>
        ) : (
          <p
            onClick={() => setAddNewBlockModelOpen(true)}
            className="text-center bg-green-700  text-white rounded-lg mt-8 cursor-pointer py-1  font-semibold"
          >
            Add New Block
          </p>
        )}
      </Modal>

      {/* Edit Block Details Modal */}
      <Modal
        centered
        open={editBlockDetailsModalOpen}
        onCancel={() => {
          setBlocksDetailsModalOpen(true);
          setEditBlockDetailsModalOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setBlocksDetailsModalOpen(true);
              setEditBlockDetailsModalOpen(false);
            }}
          >
            Close
          </Button>,
        ]}
      >
        <h1 className="text-lg mb-5 font-semibold text-gray-700">
          Editing : {selectedBlock?.Block}
        </h1>

        <div className="flex justify-around">
          <div className="flex mb-5">
            <p className="mr-2 mt-1">Total Input (kW): </p>
            <Input
              className="text-center"
              type="number"
              maxLength={5}
              defaultValue={selectedBlock?.TotalInput}
              // showCount
              placeholder={selectedBlock?.TotalInput}
              onChange={(e) => {
                setTotalInput(e?.target?.value);
              }}
              style={{
                width: 100,
              }}
            />
          </div>
          <div className="flex mb-5">
            <p className="mr-2 mt-1">Load Balancing Enabled : </p>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={selectedBlock?.enableLoadBalancing}
              defaultValue={selectedBlock?.enableLoadBalancing || false}
              onChange={(value) => setEnabledLoadBalancing(value)}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </div>
        </div>
        <h6 className="font-semibold">Chargers : </h6>
        <div className="flex mt-4 flex-wrap">
          {allChargersAtLocation?.map((charger, index) => (
            <a
              onClick={() =>
                toggleBlockCharger(selectedBlockChargers, charger?.chargerName)
              }
            >
              <div
                key={index}
                style={{
                  backgroundColor: selectedBlockChargers?.includes(
                    charger.chargerName
                  )
                    ? "green"
                    : "white",
                }}
                className=" block-card border mb-3 p-2 rounded-lg mx-2"
              >
                <h3
                  style={{
                    color: selectedBlockChargers?.includes(charger.chargerName)
                      ? "white"
                      : "black",
                    userSelect: "none",
                  }}
                  className="text-basic font-semibold text-gray-900"
                >
                  {charger.chargerName}
                </h3>
              </div>
            </a>
          ))}
        </div>
        <p
          onClick={() => {
            handleUpdateBlockDetails(
              selectedLocation?._id,
              selectedBlock?.Block,
              selectedBlockChargers,
              totalInput || selectedBlock?.TotalInput
            );
          }}
          style={{ userSelect: "none" }}
          className="text-center bg-blue-800  text-white rounded-lg mt-8 cursor-pointer py-1  font-semibold"
        >
          Update Changes
        </p>
        <p
          onClick={() => {
            setCinfirmDeleteBlockDataModalOpen(true);
          }}
          style={{ userSelect: "none" }}
          className="text-center bg-red-600  text-white rounded-lg mt-4 cursor-pointer py-1  font-semibold"
        >
          Delete Block
        </p>
      </Modal>

      {/* Confirm delete Location Modal */}
      <Modal
        // title="Confirm"
        centered
        open={connfirmDeleteModalOpen}
        onCancel={() => setConfirmDeleteModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setConfirmDeleteModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            danger
            onClick={() => {
              setConfirmDeleteModalOpen(false);
              handleDeleteLocation();
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Are you sure to delete {selectedLocation && selectedLocation?.address}
        </p>
      </Modal>
      {/* Confirm delete Block Modal */}
      <Modal
        // title="Confirm"
        centered
        open={confirmDeleteBlockDataModalOpen}
        onCancel={() => setCinfirmDeleteBlockDataModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setCinfirmDeleteBlockDataModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            danger
            onClick={() => {
              setCinfirmDeleteBlockDataModalOpen(false);
              handleDeleteBlockData(
                selectedLocation?._id,
                selectedBlock?.Block,
              );
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Are you sure to delete {selectedLocation && selectedBlock?.Block}
        </p>
      </Modal>

      {/* Edit LocationModal */}
      <Modal
        title="Edit Location Details"
        centered
        open={editLocationModalOpen}
        onCancel={() => {
          setEditLocationModalOpen(false);
          setSelectedLocation();
        }}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={form}
          initialValues={{ layout: formLayout }}
          onValuesChange={onFormLayoutChange}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Address">
            <Input
              onChange={(e) => setUpdatedLocationAddress(e.target.value)}
              placeholder={selectedLocation?.address}
            />
          </Form.Item>
          <Form.Item label="State">
            <Input
              onChange={(e) => setUpdatedLocationState(e.target.value)}
              placeholder={selectedLocation?.state}
            />
          </Form.Item>
          <Form.Item label="City">
            <Input
              onChange={(e) => setUpdatedLocationCity(e.target.value)}
              placeholder={selectedLocation?.city}
            />
          </Form.Item>
          <Form.Item label="Pin Code">
            <InputNumber
              maxLength={6}
              showCount
              placeholder={selectedLocation?.pincode}
              onChange={(e) => {
                setUpdatedLocationPincode(e);
              }}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label="Latitude">
            <Input
              onChange={(e) => setUpdatedLocationLatitude(e.target.value)}
              placeholder={selectedLocation?.latitude}
            />
          </Form.Item>
          <Form.Item label="Longitude">
            <Input
              onChange={(e) => setUpdatedLocationLongitude(e.target.value)}
              placeholder={selectedLocation?.longitude}
            />
          </Form.Item>
          <FormItem label="Restricted">
            <Select
              onChange={(value) => setUpdatedLocationRestricted(value)}
              placeholder={selectedLocation?.restricted}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </FormItem>
          <Form.Item label="CGL Fee">
            <InputNumber
              maxLength={3}
              showCount
              placeholder={selectedLocation?.gunLockCharges}
              onChange={(e) => {
                setUpdatedCGLPrice(e);
              }}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Form>
        <div className="mt-16">
          <Button
            onClick={() => handleUpdateLocationDetails()}
            style={{ position: "absolute", right: 20, bottom: 15 }}
            type="default"
          >
            Update
          </Button>
          <Button
            onClick={() => {
              setEditLocationModalOpen(false);
              setSelectedLocation();
            }}
            style={{ position: "absolute", right: 110, bottom: 15 }}
            type="dashed"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setEditLocationModalOpen(false);
              setConfirmDeleteModalOpen(true);
            }}
            style={{ position: "absolute", right: 200, bottom: 15 }}
            danger
          >
            Delete Location
          </Button>
        </div>{" "}
      </Modal>

      {/* Add LocationModal */}
      <Modal
        title="Add New Location"
        centered
        open={addNewLocationModalOpen}
        onCancel={() => setAddNewLocationModalOpen(false)}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={AddLocationForm}
          initialValues={{ layout: formLayout }}
          onValuesChange={onFormLayoutChange}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Address">
            <Input
              required
              onChange={(e) => setNewLocationAddress(e.target.value)}
              placeholder="Enter Address"
            />
          </Form.Item>
          <Form.Item label="State">
            <Input
              onChange={(e) => setNewLocationState(e.target.value)}
              placeholder="Enter State"
            />
          </Form.Item>
          <Form.Item label="City">
            <Input
              onChange={(e) => setNewLocationCity(e.target.value)}
              placeholder="Enter City"
            />
          </Form.Item>
          <Form.Item label="Pin Code">
            <InputNumber
              maxLength={6}
              onChange={(e) => {
                setNewLocationPincode(e);
              }}
              style={{
                width: "100%",
              }}
              placeholder="Enter Pincode"
            />
          </Form.Item>
          <Form.Item label="Latitude">
            <InputNumber
              onChange={(e) => {
                setNewLocationLatitude(e);
              }}
              style={{
                width: "100%",
              }}
              placeholder="Enter Latitude"
            />
          </Form.Item>
          <Form.Item label="Longitude">
            <InputNumber
              onChange={(e) => {
                setNewLocationLongitude(e);
              }}
              style={{
                width: "100%",
              }}
              placeholder="Enter Longitude"
            />
          </Form.Item>
          <FormItem label="Restricted">
            <Select
              showSearch
              onChange={(value) => setNewLocationRestricted(value)}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </FormItem>
          <Form.Item label="CGL Fee">
            <InputNumber
              onChange={(e) => {
                setNewCGLPrice(e);
              }}
              style={{
                width: "100%",
              }}
              placeholder="Enter CGL Fee"
            />
          </Form.Item>
        </Form>
        <div className="mt-10">
          <Button
            onClick={() => handleAddNewLocation()}
            style={{ position: "absolute", right: 20, bottom: 15 }}
            type="default"
          >
            Add
          </Button>
          <Button
            onClick={() => setAddNewLocationModalOpen(false)}
            style={{ position: "absolute", right: 90, bottom: 15 }}
            type="dashed"
          >
            Cancel
          </Button>
        </div>{" "}
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Location Details
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddNewLocationModalOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add New Location
          </a>
        </span>
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        {/* <div className="flex">
          <p style={{ marginTop: 2 }}>Date Range</p>
          <RangePicker
            className="ml-4"
            style={{ width: 230 }}
            onChange={onChange}
          />
        </div> */}
        {/* For getting from address filter */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>Address</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 260 }}
            placeholder="Select Address"
            optionFilterProp="children"
            onChange={(value) => setSelectedAddress(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={undefined}>All Locations</Option>
            {locations &&
              locations?.map((location) => {
                return (
                  <Option value={location?.address}>{location?.address}</Option>
                );
              })}
          </Select>
        </div>
        {/* State */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>State</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select state"
            defaultValue={selectedState}
            optionFilterProp="children"
            onChange={(value) => setSelectedState(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={undefined}>All States</Option>
            {allStates &&
              allStates?.map((state) => {
                return <Option value={state}>{state}</Option>;
              })}
          </Select>
        </div>
        {/* PinCode */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>Pin code</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select pincode"
            optionFilterProp="children"
            onChange={(value) => setSelectedPinCode(value)}
            filterOption={(input, option) =>
              option?.props?.children.indexOf(input) >= 0
            }
          >
            <Option value={undefined}>All Pincodes</Option>
            {allPinCodes &&
              allPinCodes?.map((pincode) => {
                return <Option value={pincode}>{pincode.toString()}</Option>;
              })}
          </Select>
        </div>
        {/* <div className="flex justify-center">
          <a
            onClick={() => filterData()}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div> */}
      </div>

      <div className="flex justify-between mt-3">
        <div className="flex">
          <a
            style={{ display: filterSet ? "block" : "none" }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md "
          >
            Filters :
          </a>
          <div className="ml-4 mt-1 flex">
            {filterSet && selectedAddress ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                {selectedAddress}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedAddress(undefined);
                      filterData();
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}

            {filterSet && selectedState ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                {selectedState}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedState(undefined);
                      filterData();
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}

            {filterSet && selectedPinCode ? (
              <p
                className="mr-2"
                style={{
                  fontSize: 13,
                  backgroundColor: "#ADD8E6",
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderRadius: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  color: "black",
                }}
              >
                {selectedPinCode}
                <a className="cursor-pointer pl-2">
                  <i
                    onClick={() => {
                      setSelectedPinCode(undefined);
                    }}
                    style={{ color: "black", marginTop: 1 }}
                    className="fa-solid fa-xmark"
                  ></i>
                </a>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        {filterSet ? (
          <a
            onClick={() => {
              setfilterSet(false);
              setSelectedAddress(undefined);
              setSelectedPinCode(undefined);
              setSelectedState(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Showing Locations */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedlocationsData}
          onChange={onChange}
          scroll={{
            // x: 500,
            y: 600,
          }}
        />
      </div>
    </div>
  );
};

export default LocationDetailsScreen;
