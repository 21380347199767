import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Button, message } from "antd";
import axios from "axios";
import { BaseUrl } from "../static/BaseUrl.js";
import LoadingComponent from "../Components/LoadingComponent.js";

const LoginScreen = () => {
  const navigation = useNavigate();
  const [username, setusername] = useState("UserName");
  const [password, setPassword] = useState();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigation("/dashboard");
    }
  }, [localStorage.getItem("token")]);

  function refreshPage() {
    window.location.reload(true);
  }

  const handleSignIn = async () => {
    setShowLoading(true);
    if (!username || !password) {
      return message.error("Username and password required");
    }
    try {
      setShowLoading(true);
      const res = await axios.post(`${BaseUrl}/login/admin`, {
        email: username,
        password: password,
      });
      if (res.data.success) {
        message.success("Login Successfully");
        localStorage.setItem("token", res?.data?.token);
        refreshPage();
        setShowLoading(false);
      } else {
        message.error(res?.data?.message);
        setShowLoading(false);
      }
      // console.log(res.data);
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  return showLoading ? (
    <LoadingComponent />
  ) : (
    <div className="  h-screen" style={{ backgroundColor: "#354259" }}>
      <div className="flex flex-col sm:flex-row text-white text-center">
        <div
          style={{ position: "absolute", bottom: 10, left: 5, fontSize: 12 }}
        >
          <p>© ACDC Electric Infrastructure Private Limited</p>
          <p className="text-left">All rights reserved</p>
        </div>
        <div className="w-full flex justify-center items-center h-screen ">
          <div className="items-center w-full">
            <p className="text-5xl font-bold mb-4">ElectricWeb CMS</p>
            <p className="text-xl">
              All you need to manage chargers is to login.
            </p>
            <div className="items-center flex mt-10 justify-center w-full">
              <img
                alt="ElectricWebLogo"
                style={{ height: 220, width: 240 }}
                src="../static/ElectricWeb-Dark-Logo.png"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center h-screen ">
          <div
            className="drop-shadow-lg rounded-lg p-10"
            style={{ backgroundColor: "#FF435B", width: 420 }}
          >
            <p className="text-black text-3xl font-bold text-center mb-6">
              Sign In
            </p>
            <Input
              id="input"
              className="p-2 shadow-md text-black text-xl"
              onChange={(e) => setusername(e.target.value)}
              placeholder="Enter email"
              required
            />
            <Input.Password
              id="input"
              className="p-2 mt-4 shadow-md text-black text-xl"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <p
              className=" text-white text-center mt-3"
              style={{ fontSize: 12 }}
            >
              {/* <a>Forgot your password?</a> */}
            </p>
            <div className="flex justify-center mt-12">
              <Button
                form="input"
                type="submit"
                style={{
                  backgroundColor: "#354259",
                  color: "white",
                  fontWeight: 600,
                  borderWidth: 0,
                }}
                onClick={() => handleSignIn()}
                block
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
