import React, { useState, useEffect } from "react";
import { Button, Select, message, Modal } from "antd";
import { websocketConnectionUrl } from "../../../static/BaseUrl";

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();

  const [selectedCharger, setSelectedCharger] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState();

  const [selectedKey, setSelectedKey] = useState();
  const [selectedValue, setSelectedValue] = useState();

  const handleSendRequest = async (socket) => {
    try {
      setTriggerMessageModalOpen(true);
      let sendData = {
        From: "BD8746G645VWU83",
        To: `${selectedCharger}`,
        message: [
          2,
          "ChangeConfiguration",
          "ChangeConfiguration",
          {
            key: selectedKey,
            value: selectedValue.toString(),
          },
        ],
      };
      await socket.send(JSON.stringify(sendData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[1] === "ChangeConfiguration"
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[5];
        setResponseMessage(message);
      } else if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[1] === "GetConfiguration"
      ) {
        setResponseMessage(rawResponseData.split("*")[2]);
      }
    }
  }, [rawResponseData]);

  useEffect(() => {
    var ws = new WebSocket(websocketConnectionUrl);
    ws.onopen = () => {
      let sendData = {
        From: "BD8746G645VWU83",
      };
      ws.send(sendData);

      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            // const mapFromJSON = array.reduce((map, [key, value]) => {
            //   map.set(key, value);
            //   return map;
            // }, new Map());
            setCharegrStatusArray(array);
            if (refresh === true) {
              refresh = false;
              // setChargerStatus(chargersMap);
            }
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <Modal
        // title="Confirm"
        centered
        open={triggerMessageModalOpen}
        onCancel={() => {
          setTriggerMessageModalOpen(false);
          setResponseMessage("Waiting for response...");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setTriggerMessageModalOpen(false);
              setResponseMessage("Waiting for response...");
            }}
          >
            Close
          </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">Change Configuration</p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Change Configuration
      </p>
      <p className="text-slate-600">
        Central System can request a Charge Point to change configuration
        parameters. To achieve this, Central System SHALL send a
        ChangeConfiguration.req. This request contains a key-value pair, where
        "key" is the name of the configuration setting to change and "value"
        contains the new setting for the configuration setting. Upon receipt of
        a ChangeConfiguration.req Charge Point SHALL reply with a
        ChangeConfiguration.conf indicating whether it was able to apply the
        change to its configuration. Content of "key" and "value" is not
        prescribed. The Charge Point SHALL set the status field in the
        ChangeConfiguration.conf according to the following rules: <br />• If
        the change was applied successfully, and the change if effective
        immediately, the Charge Point SHALL respond with a status 'Accepted'.{" "}
        <br />
        • If the change was applied successfully, but a reboot is needed to make
        it effective, the Charge Point SHALL respond with status
        'RebootRequired'. <br />
        • If "key" does not correspond to a configuration setting supported by
        Charge Point, it SHALL respond with a status 'NotSupported'. <br />• If
        the Charge Point did not set the configuration, and none of the previous
        statuses applies, the Charge Point SHALL respond with status 'Rejected'.
      </p>

      <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              if (charger[1] !== "Unavailable") {
                return (
                  <option value={charger[0]}>
                    {charger[0]}
                  </option>
                );
              }
            })}
        </Select>

        {selectedCharger ? (
          <Select
            style={{ width: 200, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select Trigger Message"}
            onChange={(value) => setSelectedKey(value)}
          >
            <option value="AuthorizationCacheEnabled">
              AuthorizationCacheEnabled
            </option>
          </Select>
        ) : (
          ""
        )}

        {selectedKey ? (
          <Select
            style={{ width: 200, marginLeft: 20, marginRight: 20 }}
            showSearch
            className="shadow-xl"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select Value"}
            onChange={(value) => setSelectedValue(value)}
          >
            <option value={true}>True</option>
            <option value={false}>False</option>
          </Select>
        ) : (
          ""
        )}
      </div>

      {selectedCharger ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Screen;
