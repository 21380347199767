import React, { useState, useEffect } from "react";

const Screen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Unlock Connector
      </p>
      <p className="text-slate-600">
        Central System can request a Charge Point to unlock a connector. To do
        so, the Central System SHALL send an UnlockConnector.req PDU. The
        purpose of this message: Help EV drivers that have problems unplugging
        their cable from the Charge Point in case of malfunction of the
        Connector cable retention. When a EV driver calls the CPO help-desk, an
        operator could manually trigger the sending of an UnlockConnector.req to
        the Charge Point, forcing a new attempt to unlock the connector.
        Hopefully this time the connector unlocks and the EV driver can unplug
        the cable and drive away. The UnlockConnector.req SHOULD NOT be used to
        remotely stop a running transaction, use the Remote Stop Transaction
        instead. Upon receipt of an UnlockConnector.req PDU, the Charge Point
        SHALL respond with a UnlockConnector.conf PDU. The response PDU SHALL
        indicate whether the Charge Point was able to unlock its connector. If
        there was a transaction in progress on the specific connector, then
        Charge Point SHALL finish the transaction first as described in Stop
        Transaction. UnlockConnector.req is intented only for unlocking the
        cable retention lock on the Connector, not for unlocking a connector
        access door.
      </p>
    </div>
  );
};

export default Screen;
