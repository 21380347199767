import React, { useState } from "react";

import Layout from "../WebsiteComponents/Layout";
import LayoutWithoutHeader from "../WebsiteComponents/LayoutWithoutHeader";
import "../WebsiteComponents/HeaderCss.css";

const ServicesScreen = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Layout>
      <div
        className="font-serif"
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
        }}
      >
        {/*  */}
        <div
          className="py-1 sm:py-40"
          style={{
            backgroundImage: "url(../static/21.png)",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "absolute", // Corrected typo
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundOrigin: "content-box",
          }}
        >
          <p
            style={{ "-webkit-text-stroke": "1px black" }}
            className="text-3xl font-bold sm:text-6xl text-white text-center pt-12  font-serif"
          >
            Unleash the Potential of
          </p>
          <p
            style={{ "-webkit-text-stroke": "1px black" }}
            className="text-3xl font-bold sm:text-6xl text-white text-center  font-serif  sm:mt-6"
          >
            Electric Mobility with Our
          </p>
          <p
            style={{ "-webkit-text-stroke": "1px black" }}
            className="text-3xl font-bold sm:text-6xl text-white text-center  font-serif  sm:mt-6"
          >
            EV Charging Software
          </p>
          <p
            style={{ "-webkit-text-stroke": "1px black" }}
            className="text-3xl font-bold sm:text-6xl text-white text-center pb-12 font-serif  sm:mt-6"
          >
            Solutions
          </p>
        </div>

        {/* Mobile Application Section */}
        <div className="bg-white flex flex-col border-2 sm:py-20 sm:flex-row">
          <div className=" mt-7 block sm:ml-20">
            <p
              style={{ letterSpacing: 2 }}
              className="text-3xl text-center sm:hidden mb-5 sm:mb-0   font-semibold mt-2 "
            >
              ElectricWeb Mobile Application
            </p>
            <div>
              <p
                style={{ letterSpacing: 1 }}
                className="sm:text-5xl   mb-0 text-left hidden sm:block   font-bold my-2 "
              >
                ElectricWeb Mobile Application
              </p>
            </div>

            <div style={{ color: "#545454" }} >
              <div className="hidden sm:block mt-9">
                <p className="text-4xl text-left ">
                  An intuitively designed application
                </p>
                <p className="text-4xl text-left ">
                  for EV users with features like:
                </p>
                <p className="font-bold text-left mt-4">
                  {" "}
                  • Charging Station Locator
                </p>
                <p className="font-bold text-left"> • Real-time Availability</p>
                <p className="font-bold text-left"> • Navigation Integration</p>
                <p className="font-bold text-left"> • Charging Station Details</p>
                <p className="font-bold text-left"> • User Reviews and Ratings</p>
                <p className="font-bold text-left"> • All online payment modes</p>
                <p className="font-bold text-left"> • Charging History</p>
                <p className="font-bold text-left"> • Real-time Notifications</p>
                <p className="font-bold text-left">
                  {" "}
                  • Offline Access using RFID tags
                </p>
                <p className="font-bold text-left mb-10">
                  {" "}
                  • User Account Management
                </p>
              </div>
            </div>
          </div>
          <div className=" flex h-32 sm:h-auto justify-center  items-center sm:w-2/4  overflow-hidden">
            <img
              style={{ position: "absolute" }}
              alt="Img"
              className="h-32 sm:h-80 origin-bottom -rotate-12 overflow-hidden mr-10 sm:mr-28"
              src="../../static/Image3.png"
            />

            <img
              style={{ position: "absolute" }}
              alt="Img"
              className="h-32 sm:h-80 origin-bottom rotate-12 overflow-hidden ml-10 sm:ml-28"
              src="../../static/Image1.png"
            />
            <img
              style={{ position: "absolute" }}
              alt="Img"
              className="h-32 sm:h-80 overflow-hidden"
              src="../../static/Image2.png"
            />
          </div>
          <div className="sm:hidden block mt-9 px-5">
            <p className="text-2xl text-left ">
              An intuitively designed application for EV users with features
              like:
            </p>
            <p className="text-left mt-4"> • Charging Station Locator</p>
            <p className="text-left"> • Real-time Availability</p>
            <p className="text-left"> • Navigation Integration</p>
            <p className="text-left"> • Charging Station Details</p>
            <p className="text-left"> • User Reviews and Ratings</p>
            <p className="text-left"> • All online payment modes</p>
            <p className="text-left"> • Charging History</p>
            <p className="text-left"> • Real-time Notifications</p>
            <p className="text-left"> • Offline Access using RFID tags</p>
            <p className="text-left mb-10"> • User Account Management</p>
          </div>
        </div>

        {/* CMS Section */}
        <div
          style={{
            background:
              "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
          }}
          className="flex flex-col sm:flex-row py-5 sm:py-20"
        >
          <p
            style={{ letterSpacing: 2 }}
            className="text-3xl text-center sm:hidden text-white    font-semibold mt-2 "
          >
            Charging Management System (CMS)
          </p>
          <div className="flex mt-10 sm:mt-auto justify-center  items-center sm:w-2/4  overflow-hidden">
            <img src="../static/DashboardLaptopImage.png" />
          </div>
          <div>
            <p
              style={{ letterSpacing: 2 }}
              className="sm:text-5xl text-white text-3xl text-right hidden sm:block   font-bold my-2 "
            >
              Charging Management
            </p>
            <p
              style={{ letterSpacing: 2 }}
              className="sm:text-5xl text-white text-3xl mb-0 text-right hidden sm:block   font-bold my-2 "
            >
              System (CMS)
            </p>
            {/* <p
              style={{ letterSpacing: 2 }}
              className="text-5xl hidden sm:block mb-20 text-center text-white   font-semibold my-2 "
            >
              System (CMS)
            </p> */}
            <div className="sm:hidden block mt-9 mx-5">
              <p className="text-2xl text-left sm:text-4xl sm:text-right text-white">
                An intelligent management system with zero transaction capture
                failure rate and other functionalities such as:
              </p>

              <p className="sm:text-right  mt-4 text-white">
                {" "}
                • Charging Session Management
              </p>
              <p className="sm:text-right  text-white">
                {" "}
                • Remote Monitoring and Diagnostics
              </p>
              <p className="sm:text-right  text-white">
                {" "}
                • Energy Management and Billing
              </p>
              <p className="sm:text-right  text-white">
                {" "}
                • Reporting and Analytics
              </p>
              <p className="sm:text-right  text-white">
                {" "}
                • Integration with Energy Management Systems (EMS)
              </p>
            </div>
            <div className="hidden sm:block mt-9 mx-5">
              <p className="text-3xl text-left sm:text-4xl sm:text-right text-white">
                An intelligent management system with
              </p>
              <p className="text-4xl sm:text-right text-white">
                zero transaction capture failure rate
              </p>
              <p className="text-4xl sm:text-right text-white">
                and other functionalities such as:
              </p>
              <p className="sm:text-right font-bold mt-4 text-white">
                {" "}
                • Charging Session Management
              </p>
              <p className="sm:text-right font-bold text-white">
                {" "}
                • Remote Monitoring and Diagnostics
              </p>
              <p className="sm:text-right font-bold text-white">
                {" "}
                • Energy Management and Billing
              </p>
              <p className="sm:text-right font-bold text-white">
                {" "}
                • Reporting and Analytics
              </p>
              <p className="sm:text-right font-bold text-white">
                {" "}
                • Integration with Energy Management Systems (EMS)
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Header */}
      <div className="w-full" style={{ position: "absolute", top: 0 }}>
        <div
          style={{
            background:
              "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
          }}
          className="px-5 sm:px-14 flex shadow-xl justify-between fixed w-full"
        >
          <div className="block sm:hidden mt-2">
            <i
              onClick={() => setNavbarOpen(!navbarOpen)}
              class="fa-solid fa-bars-staggered"
              style={{
                color: "white",
                fontSize: 30,
                fontWeight: 600,
                marginTop: 18,
              }}
            ></i>
          </div>
          <div>
            <a
              href="/"
              // className="text-right sm:text-left flex"
              style={{
                color: "white",
                // fontSize: 30,
                // fontWeight: 600,
                cursor: "pointer",
              }}
            >
              <img
                className="sm:block hidden mr-5 "
                alt="Images"
                style={{ height: 115, width: 125 }}
                src="../../static/ElectricWeb-Dark-Logo.png"
              />
              <img
                className="block sm:hidden mr-3 my-2"
                alt="Images"
                style={{ height: 70, width: 75 }}
                src="../../static/ElectricWeb-Dark-Logo.png"
              />
              {/* ElectricWeb */}
            </a>
          </div>

          {/* Navigation For Pc Scrren */}
          <div className="hidden sm:flex  justify-center">
            <div className="hidden sm:flex w-full h-full pt-10 flex-row border-0">
              <div id="wrapper">
                <div
                  id="button"
                  className="hover:text-blue-700 text-xl text-white cursor-pointer font-serif pb-5 px-6"
                >
                  Company
                  <i
                    style={{ marginLeft: 10 }}
                    class="fa-solid fa-caret-down"
                  ></i>
                  <div
                    id="drop"
                    style={{ borderRadius: 5, overflow: "hidden" }}
                    className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                  >
                    <a
                      href="aboutus"
                      className="cursor-pointer rounded font-serif text-blue-700"
                      // id="drop"
                    >
                      About us
                      <p
                        className="text-sm"
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        EW stands for reliable, sustainable & cost-effective
                        charging services.
                      </p>
                      {/* <p className="h-4"></p> */}
                    </a>
                    {/* <a
                    href="aboutus"
                    className="cursor-pointer  rounded text-blue-700"
                    // id="drop"
                  >
                    Products
                    <p
                      className="text-sm"
                      style={{
                        textWrap: "wrap",
                        color: "#545454",
                        width: "100%",
                      }}
                    >
                      hdqgduyiq hiqudhquiodj hiohquihi uguihuiqhuiq
                      dhqiudhioqhioq
                    </p>
                  </a> */}
                  </div>
                </div>
              </div>
              <div id="wrapper">
                <div
                  id="button"
                  className="cursor-pointer text-xl font-serif hover:text-blue-700 text-white pb-5 px-6"
                >
                  Business
                  <i
                    style={{ marginLeft: 10 }}
                    class="fa-solid fa-caret-down"
                  ></i>
                  <div
                    id="drop"
                    style={{ borderRadius: 5, overflow: "hidden" }}
                    className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                  >
                    <a
                      href="services"
                      className="font-serif cursor-pointer rounded text-blue-700"
                      // id="drop"
                    >
                      Services
                      <p
                        className="text-sm"
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        Software backbone for seamless charging and monitoring
                      </p>
                      <p className="h-4"></p>
                    </a>
                    <a
                      href="products"
                      className="cursor-pointer font-serif rounded text-blue-700"
                      // id="drop"
                    >
                      Products
                      <p
                        className="text-sm "
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        Custom built hardware to democratize EV adoption.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div id="wrapper">
                <div id="button" className="text-xl cursor-pointer px-6">
                  <a
                    href="partner"
                    className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Partner
                  </a>
                </div>
              </div>
              <div id="wrapper">
                <div
                  id="button"
                  className="text-xl font-serif cursor-pointer px-6"
                >
                  <a
                    href="resources"
                    className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Resources
                  </a>
                </div>
              </div>

              <div id="wrapper">
                <div
                  id="button"
                  className="text-xl font-serif cursor-pointer px-6"
                >
                  <a
                   href="dashboard"
                    className="text-white w-max font-serif h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:block hidden" style={{ height: 115 }}></div>
        <div className="block sm:hidden" style={{ height: 80 }}></div>

        {/* Navigation For Mobile Scrren */}
        <div
          className="flex-wrap flex-row justify-center shadow-2xl fixed top-20 w-full"
          style={{
            display: navbarOpen ? "block" : "none",
            backgroundColor: "white",
            paddingTop: 25,
          }}
        >
          {/* <a onClick={() => setNavbarOpen(false)} className="absolute right-6">
          <i class="fa-regular text-2xl fa-rectangle-xmark"></i>
        </a> */}

          <div id="wrapper">
            <div
              id="button"
              className="hover:text-blue-700 text-left cursor-pointer font-serif pb-5 px-6"
            >
              Company
              <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
              <div
                id="dropMobile"
                style={{ borderRadius: 5, overflow: "hidden" }}
                className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
              >
                <a
                  href="aboutus"
                  className="cursor-pointer rounded font-serif text-blue-700"
                  // id="drop"
                >
                  About us
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    EW stands for reliable, sustainable & cost-effective
                    charging services.
                  </p>
                  {/* <p className="h-4"></p> */}
                </a>
              </div>
            </div>
          </div>

          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              Business
              <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
              <div
                id="dropMobile"
                style={{ borderRadius: 5, overflow: "hidden" }}
                className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
              >
                <a
                  href="services"
                  className="cursor-pointer rounded  font-serif text-blue-700"
                  // id="drop"
                >
                  Services
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    Software backbone for seamless charging and monitoring.
                  </p>
                  <p className="h-4"></p>
                </a>
                <a
                  href="products"
                  className="cursor-pointer rounded font-serif text-blue-700"
                  // id="drop"
                >
                  Products
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    Custom built hardware to democratize EV adoption.
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="partner">Partner</a>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="resources">Resources</a>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="dashboard">Login</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServicesScreen;
