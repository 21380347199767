import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  Button,
  Form,
  Input,
  Modal,
  message,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";
import FormItem from "antd/es/form/FormItem";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const LocationDetailsScreen = () => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState();
  const [formatedlocationsData, setLocationFormatedData] = useState();
  const [captiveCodeModalOpen, setCaptiveCodeModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [editLocationModalOpen, setEditLocationModalOpen] = useState();
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState();
  const [connfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState();
  const [allStates, setAllStates] = useState();
  const [allPinCodes, setAllPinCodes] = useState();

  const [selectedPinCode, setSelectedPinCode] = useState();
  const [selectedState, setSelectedState] = useState();
  const [dateRange, setDateRange] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();

  const [newLocationAddress, setNewLocationAddress] = useState();
  const [newLocationState, setNewLocationState] = useState();
  const [newLocationPincode, setNewLocationPincode] = useState();
  const [newLocationCity, setNewLocationCity] = useState();
  const [newLocationLongitude, setNewLocationLongitude] = useState();
  const [newLocationLatitude, setNewLocationLatitude] = useState();
  const [newLocationRestricted, setNewLocationRestricted] = useState();

  const [updatedLocationAddress, setUpdatedLocationAddress] = useState(
    selectedLocation?.address
  );
  const [updatedLocationState, setUpdatedLocationState] = useState(
    selectedLocation?.state
  );
  const [updatedLocationPincode, setUpdatedLocationPincode] = useState(
    selectedLocation?.pincode
  );
  const [updatedLocationCity, setUpdatedLocationCity] = useState(
    selectedLocation?.city
  );
  const [updatedLocationLongitude, setUpdatedLocationLongitude] = useState(
    selectedLocation?.longitude
  );
  const [updatedLocationLatitude, setUpdatedLocationLatitude] = useState(
    selectedLocation?.latitude
  );
  const [updatedLocationRestricted, setUpdatedLocationRestricted] = useState(
    selectedLocation?.restricted
  );

  useEffect(() => {
    setUpdatedLocationAddress(selectedLocation?.address);
    setUpdatedLocationState(selectedLocation?.state);
    setUpdatedLocationPincode(selectedLocation?.pincode);
    setUpdatedLocationCity(selectedLocation?.city);
    setUpdatedLocationLongitude(selectedLocation?.longitude);
    setUpdatedLocationLatitude(selectedLocation?.latitude);
    setUpdatedLocationRestricted(selectedLocation?.restricted);
  }, [selectedLocation]);

  function onChange(date, dateString) {
    setDateRange(dateString);
  }

  const getLocations = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/chargers/with/captiveCode`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setLocations(res?.data?.locations);
        setAllStates(res?.data?.states);
        setAllPinCodes(res?.data?.pincodes);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);
  useEffect(() => {
    formatBookingData(locations);
  }, [locations]);

  const formatBookingData = (locations) => {
    setLoading(true);
    try {
      let array = locations;
      array?.map((location) => {
        location.restricted = location.restricted.toString();
        location.createdAt = location.createdAt.split("T")[0];
        location.sortingDate = location.createdAt
          .split("T")[0]
          .split("-")
          .join("");
      });
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setLocationFormatedData(array);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Filter Daata
  const filterData = async () => {
    try {
      setLoading(true);

      // If All Filters were Added
      if (dateRange && selectedPinCode && selectedState) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          let createdAt = location?.createdAt
            ?.split("T")[0]
            .split("-")
            .join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            location?.pincode === Number(selectedPinCode) &&
            location?.state === selectedState
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (dateRange && selectedPinCode) {
        // If Date Range and Pin code is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          let createdAt = location?.createdAt
            ?.split("T")[0]
            .split("-")
            .join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            location?.pincode === Number(selectedPinCode)
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (dateRange && selectedState) {
        // If DateRange and state is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          let createdAt = location?.createdAt
            ?.split("T")[0]
            .split("-")
            .join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            location?.state === selectedState
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (selectedState && selectedPinCode) {
        // If State and Pincode is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          if (
            location?.pincode === Number(selectedPinCode) &&
            location?.state === selectedState
          ) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (dateRange) {
        // If only Date range is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          let createdAt = location?.createdAt
            ?.split("T")[0]
            .split("-")
            .join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (createdAt >= from && createdAt <= to) {
            resultArray.push(location);
          }
        });
        setLocationFormatedData(resultArray);
        setLoading(false);
      } else if (selectedPinCode) {
        // If only Pincode is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array.map((location) => {
          if (location?.pincode === Number(selectedPinCode)) {
            resultedArray.push(location);
          }
        });
        setLocationFormatedData(resultedArray);
        setLoading(false);
      } else if (selectedState) {
        // If only State is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array.map((location) => {
          if (location?.state === selectedState) {
            resultedArray.push(location);
          }
        });
        setLocationFormatedData(resultedArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Date of Installation",
      dataIndex: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.sortingDate - b.sortingDate,
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Pin code",
      dataIndex: `pincode`,
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "latitude",
      dataIndex: "latitude",
    },
    {
      title: "longitude",
      dataIndex: "longitude",
    },
    // {
    //   title: "Restricted",
    //   dataIndex: `restricted`,
    // },
    // {
    //   title: "Location ID",
    //   dataIndex: `_id`,
    // },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 100,
    //   render: (text, record) => (
    //     <>
    //       <a
    //         onClick={() => {
    //           setSelectedLocation(record);
    //           setCaptiveCodeModalOpen(true);
    //         }}
    //       >
    //         <i
    //           style={{ color: "green" }}
    //           class="fa-brands fa-codiepie ml-3"
    //         ></i>
    //       </a>
    //       <a
    //         className="ml-6"
    //         onClick={() => {
    //           setSelectedLocation(record);
    //           setEditLocationModalOpen(true);
    //         }}
    //       >
    //         <i
    //           style={{ color: "blue" }}
    //           class="fa-regular fa-pen-to-square"
    //         ></i>
    //       </a>
    //     </>
    //   ),
    // },
  ];

  const [form] = Form.useForm();
  const [AddLocationForm] = Form.useForm();

  //   const handleAddNewLocation = async () => {
  //     try {
  //       if (
  //         !newLocationAddress ||
  //         !newLocationPincode ||
  //         !newLocationLatitude ||
  //         !newLocationLongitude ||
  //         !newLocationCity ||
  //         !newLocationState ||
  //         !newLocationRestricted
  //       ) {
  //         message.error("All fields required");
  //       } else {
  //         setLoading(true);
  //         const res = await axios.post(`${BaseUrl}/add/new/charger/location`, {
  //           token: localStorage.getItem("token"),
  //           address: newLocationAddress,
  //           pincode: newLocationPincode,
  //           latitude: newLocationLatitude,
  //           longitude: newLocationLongitude,
  //           city: newLocationCity,
  //           state: newLocationState,
  //           restricted: newLocationRestricted,
  //         });
  //         if (res?.data?.success) {
  //           message.success("New Location Added");
  //           setAddNewLocationModalOpen(false);
  //           getLocations();
  //           setLoading(false);
  //         } else {
  //           message.error(res?.data?.message);
  //           setLoading(false);
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   };

  //   const handleUpdateLocationDetails = async () => {
  //     try {
  //       setLoading(true);
  //       const res = await axios.post(`${BaseUrl}/update/location/details`, {
  //         token: localStorage.getItem("token"),
  //         _id: selectedLocation?._id,
  //         address: updatedLocationAddress,
  //         pincode: updatedLocationPincode,
  //         latitude: updatedLocationLatitude,
  //         longitude: updatedLocationLongitude,
  //         city: updatedLocationCity,
  //         state: updatedLocationState,
  //         restricted: updatedLocationRestricted,
  //       });
  //       if (res?.data?.success) {
  //         message.success("Location details updated");
  //         setEditLocationModalOpen(false);
  //         getLocations();
  //         setLoading(false);
  //       } else {
  //         message.error("Error in updating details");
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   };

  //   const handleDeleteLocation = async () => {
  //     try {
  //       setLoading(true);

  //       const res = await axios.post(`${BaseUrl}/delete/location`, {
  //         token: localStorage.getItem("token"),
  //         _id: selectedLocation._id,
  //       });
  //       if (res?.data?.success) {
  //         message.success("Location deleted");
  //         getLocations();
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //         message.error("Error in deleting location");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const [formLayout, setFormLayout] = useState("horizontal");
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 50,
            offset: 40,
            position: "absolute",
          },
        }
      : null;

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* CaptiveCodeModal */}
      <Modal
        title="Captive Code"
        centered
        open={captiveCodeModalOpen}
        onCancel={() => setCaptiveCodeModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setCaptiveCodeModalOpen(false)}>Close</Button>,
        ]}
      >
        <p className="pt-2">
          Captive Code for {selectedLocation && selectedLocation?.address}
        </p>
        <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p>
      </Modal>

      {/* Confirm delete Location Modal */}
      {/* <Modal
        // title="Confirm"
        centered
        open={connfirmDeleteModalOpen}
        onCancel={() => setConfirmDeleteModalOpen(false)}
        footer={[
          null,
          <Button onClick={() => setConfirmDeleteModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            danger
            onClick={() => {
              setConfirmDeleteModalOpen(false);
              handleDeleteLocation();
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Are you sure to delete {selectedLocation && selectedLocation?.address}
        </p>
      </Modal> */}

      {/* Edit LocationModal */}
      {/* <Modal
        title="Edit Location Details"
        centered
        open={editLocationModalOpen}
        onCancel={() => {
          setEditLocationModalOpen(false);
          setSelectedLocation();
        }}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={form}
          initialValues={{ layout: formLayout }}
          onValuesChange={onFormLayoutChange}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Address">
            <Input
              onChange={(e) => setUpdatedLocationAddress(e.target.value)}
              placeholder={selectedLocation?.address}
            />
          </Form.Item>
          <Form.Item label="State">
            <Input
              onChange={(e) => setUpdatedLocationState(e.target.value)}
              placeholder={selectedLocation?.state}
            />
          </Form.Item>
          <Form.Item label="City">
            <Input
              onChange={(e) => setUpdatedLocationCity(e.target.value)}
              placeholder={selectedLocation?.city}
            />
          </Form.Item>
          <Form.Item label="Pin Code">
            <Input
              maxLength={6}
              showCount
              onChange={(e) => setUpdatedLocationPincode(e.target.value)}
              placeholder={selectedLocation?.pincode}
            />
          </Form.Item>
          <Form.Item label="Latitude">
            <Input
              onChange={(e) => setUpdatedLocationLatitude(e.target.value)}
              placeholder={selectedLocation?.latitude}
            />
          </Form.Item>
          <Form.Item label="Longitude">
            <Input
              onChange={(e) => setUpdatedLocationLongitude(e.target.value)}
              placeholder={selectedLocation?.longitude}
            />
          </Form.Item>
          <FormItem label="Restricted">
            <Select
              onChange={(value) => setUpdatedLocationRestricted(value)}
              placeholder={selectedLocation?.restricted}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </FormItem>
        </Form>
        <div className="mt-16">
          <Button
            onClick={() => handleUpdateLocationDetails()}
            style={{ position: "absolute", right: 20, bottom: 15 }}
            type="default"
          >
            Update
          </Button>
          <Button
            onClick={() => {
              setEditLocationModalOpen(false);
              setSelectedLocation();
            }}
            style={{ position: "absolute", right: 110, bottom: 15 }}
            type="dashed"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setEditLocationModalOpen(false);
              setConfirmDeleteModalOpen(true);
            }}
            style={{ position: "absolute", right: 200, bottom: 15 }}
            danger
          >
            Delete Location
          </Button>
        </div>{" "}
      </Modal> */}

      {/* Add LocationModal */}
      {/* <Modal
        title="Add New Location"
        centered
        open={addNewLocationModalOpen}
        onCancel={() => setAddNewLocationModalOpen(false)}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={AddLocationForm}
          initialValues={{ layout: formLayout }}
          onValuesChange={onFormLayoutChange}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Address">
            <Input
              required
              onChange={(e) => setNewLocationAddress(e.target.value)}
              placeholder="Enter Address"
            />
          </Form.Item>
          <Form.Item label="State">
            <Input
              onChange={(e) => setNewLocationState(e.target.value)}
              placeholder="Enter State"
            />
          </Form.Item>
          <Form.Item label="City">
            <Input
              onChange={(e) => setNewLocationCity(e.target.value)}
              placeholder="Enter City"
            />
          </Form.Item>
          <Form.Item label="Pin Code">
            <Input
              maxLength={6}
              showCount
              onChange={(e) => setNewLocationPincode(e.target.value)}
              placeholder="Enter Pincode"
            />
          </Form.Item>
          <Form.Item label="Latitude">
            <Input
              onChange={(e) => setNewLocationLatitude(e.target.value)}
              placeholder="Enter Latitude"
            />
          </Form.Item>
          <Form.Item label="Longitude">
            <Input
              onChange={(e) => setNewLocationLongitude(e.target.value)}
              placeholder="Enter Longitude"
            />
          </Form.Item>
          <FormItem label="Restricted">
            <Select
              showSearch
              onChange={(value) => setNewLocationRestricted(value)}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Select>
          </FormItem>
        </Form>
        <div className="mt-10">
          <Button
            onClick={() => handleAddNewLocation()}
            style={{ position: "absolute", right: 20, bottom: 15 }}
            type="default"
          >
            Add
          </Button>
          <Button
            onClick={() => setAddNewLocationModalOpen(false)}
            style={{ position: "absolute", right: 90, bottom: 15 }}
            type="dashed"
          >
            Cancel
          </Button>
        </div>{" "}
      </Modal> */}

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Location Details
        </p>
        {/* <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddNewLocationModalOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add New Location
          </a>
        </span> */}
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Date Range</p>
          <RangePicker
            className="ml-4"
            style={{ width: 230 }}
            onChange={onChange}
          />
        </div>
        {/* State */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>State</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select state"
            optionFilterProp="children"
            onChange={(value) => setSelectedState(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {allStates &&
              allStates?.map((state) => {
                return <Option value={state}>{state}</Option>;
              })}
          </Select>
        </div>
        {/* PinCode */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>Pin code</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select pincode"
            optionFilterProp="children"
            onChange={(value) => setSelectedPinCode(value)}
            filterOption={(input, option) =>
              option?.props?.children.indexOf(input) >= 0
            }
          >
            {allPinCodes &&
              allPinCodes?.map((pincode) => {
                return <Option value={pincode}>{pincode.toString()}</Option>;
              })}
          </Select>
        </div>
        <div className="flex justify-center">
          <a
            onClick={() => filterData()}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getLocations();
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getLocations();
              setfilterSet(false);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Showing Locations */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedlocationsData}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default LocationDetailsScreen;
