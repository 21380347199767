import React from "react";
import Layout from "../WebsiteComponents/Layout";

const AboutUsScreen = () => {
  return (
    <Layout>
      <div
        className="font-serif"
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
        }}
      >
        <div>
          <p className="text-center font-bold text-white text-3xl tracking-widest sm:text-6xl  font-serif pt-10">
            Revolutionizing India’s Charging Infrastructure since 2019
          </p>
          {/* <p className="text-center text-white text-3xl sm:text-7xl font-bold tracking-widest sm:mt-5 font-serif">
          Infrastructure since 2019
          </p> */}
          <p className=" sm:block hidden text-center text-white text-xl tracking-widest sm:text-4xl mt-10  sm:mt-20 font-serif">
            ElectricWeb is tech-startup focusing of charging services that are
          </p>
          <div className=" sm:flex hidden justify-between mt-0 sm:mx-20">
            <div style={{ width: 300 }}>
              <p className="text-center text-white  tracking-widest sm:text-4xl   sm:mt-10 font-serif">
                Reliable
              </p>
            </div>
            <div style={{ width: 300 }}>
              <p className="text-center ml-10 text-white  tracking-widest sm:text-4xl   sm:mt-10 font-serif">
                Sustainable
              </p>
            </div>
            <div style={{ width: 320 }}>
              <p className="text-center ml-12 text-white  tracking-widest sm:text-4xl   sm:mt-10 font-serif">
                Cost-effective
              </p>
            </div>
          </div>
          <div className="mx-10 mt-4">
            <img
              className="sm:block hidden w-full mb-10"
              src="../static/aboutusimg.png"
            />
          </div>

          <img
            className="sm:hidden block"
            style={{ width: "100%", marginBottom: 20 }}
            src="../static/aboutUS.png"
          />
        </div>
        {/*  */}
        <div style={{ color: "#545454" }} className="bg-white py-7 sm:px-20">
          <p className="text-5xl sm:px-52 font-bold text-center sm:text-right">
            Why us?
          </p>
          <div className="flex justify-center flex-wrap py-5 sm:py-10">
            {/*  */}
            <div
              style={{ width: 280, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/10].png"
              />
              <div className="py-10">
                <p className="text-center font-bold">Industry first 2-</p>
                <p className="text-center font-bold">step charging</p>
                <p className="text-center font-bold"> process</p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 280, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/211.png"
              />
              <div className="py-10">
                <p className="text-center font-bold">State-of-the-art</p>
                <p className="text-center font-bold">Charging</p>
                <p className="text-center font-bold"> Equipment</p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 280, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/212.png"
              />
              <div className="py-10">
                <p className="text-center font-bold">EW Community & </p>
                <p className="text-center font-bold">rapidly expanding </p>
                <p className="text-center font-bold"> network</p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 280, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/12.png"
              />
              <div className="py-10">
                <p className="text-center font-bold">User</p>
                <p className="text-center font-bold">Experience and</p>
                <p className="text-center font-bold"> Convenience</p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 280, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/215.png"
              />
              <div className="py-10">
                <p className="text-center font-bold">Charging stations</p>
                <p className="text-center font-bold">with minimum</p>
                <p className="text-center font-bold"> downtime</p>
              </div>
            </div>
            {/*  */}
          </div>
        </div>

        {/* Vision Mission Section */}
        <div className="bg-white shadow-2xl">
          <div className="flex flex-col sm:flex-row justify-center">
            <div
              className="w-full sm:w-2/4 py-10 sm:py-0"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p className="text-blue font-bold text-5xl text-center">Vision</p>
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center mt-6"
              >
                To make using EV charging
              </p>
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center"
              >
                infra even more convenient
              </p>{" "}
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center"
              >
                {" "}
                than fossil fuel stations.
              </p>
            </div>
            <div className="sm:hidden w-full sm:w-2/4">
              <img
                style={{ height: 220, width: "100%" }}
                src="../static/216.png"
              />
            </div>
            <div className="hidden sm:block w-full sm:w-2/4">
              <img
                style={{ height: 350, width: "100%" }}
                src="../static/216.png"
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-center">
            {/* For Mobile Screen */}
            <div
              className=" sm:hidden flex w-full sm:w-2/4 py-10 sm:py-0"
              style={{
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p className="text-blue font-bold text-5xl text-center">
                Mission
              </p>
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center mt-6"
              >
                To encourage faster EV
              </p>
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center"
              >
                adoption by providing smart,
              </p>{" "}
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center"
              >
                {" "}
                safe & reliable charging infra.
              </p>
            </div>
            <div className="sm:hidden w-full sm:w-2/4">
              <img
                style={{ height: 220, width: "100%" }}
                src="../static/172.png"
              />
            </div>
            <div className="hidden sm:block w-full sm:w-2/4">
              <img
                style={{ height: 350, width: "100%" }}
                src="../static/172.png"
              />
            </div>
            {/* For Pc Screen */}
            <div
              className=" hidden sm:flex w-full sm:w-2/4 py-10 sm:py-0"
              style={{
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p className="text-blue font-bold text-5xl text-center">
                Mission
              </p>
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center mt-6"
              >
                To encourage faster EV
              </p>
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center"
              >
                adoption by providing smart,
              </p>{" "}
              <p
                style={{ color: "#545454" }}
                className="text-blue font-bold text-center"
              >
                {" "}
                safe & reliable charging infra.
              </p>
            </div>
          </div>
        </div>

        {/* Meet Our Team Section */}
        <div>
          <p className="text-white px-14 text-4xl pt-14 font-bold">
            Meet our team!
          </p>

          <div className="flex flex-wrap sm:mx-60  justify-around pt-14  pb-14">
            <div className="flex  sm:mt-5 flex-col justify-center">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="shadow-2xl"
                  style={{ height: 190, width: 190, borderRadius: 250 }}
                  src="../static/17.png"
                />
              </div>
              <div>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-md  mt-4"
                >
                  Abhishek Jha
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-md "
                >
                  Founder | Director
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-sm font-extralight"
                >
                  9+ years of experience in Power Sector
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-sm font-extralight"
                >
                  (Generation/Transmission/Distribution)
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-sm font-extralight"
                >
                  Project Financing & Advisory
                </p>
              </div>
            </div>
            {/*  */}
            <div className="flex  mt-20 sm:mt-5 flex-col justify-center">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="shadow-2xl"
                  style={{ height: 190, width: 190, borderRadius: 250 }}
                  src="../static/19.png"
                />
              </div>
              <div>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-md  mt-4"
                >
                  Manika Jha
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-md "
                >
                  Co-Founder | CTO
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-sm font-extralight"
                >
                  7+ years of experience in
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-sm font-extralight"
                >
                  automation, robotics, machine
                </p>
                <p
                  style={{ letterSpacing: 1 }}
                  className="text-white text-center text-sm font-extralight"
                >
                  learning and IOT based projects
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="flex pb-10 flex-col justify-center">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              className="shadow-2xl"
              style={{ height: 190, width: 190, borderRadius: 250 }}
              src="../static/20.jpeg"
            />
          </div>
          <div>
            <h1
              style={{ letterSpacing: 1 }}
              className="text-white text-center text-md  mt-4"
            >
              Ravi Lour
            </h1>
            <p
              style={{ letterSpacing: 1 }}
              className="text-white text-center text-md "
            >
              Technical Head
            </p>
            <p
              style={{ letterSpacing: 1 }}
              className="text-white text-center text-sm font-extralight"
            >
              2+ years of experience in
            </p>
            <p
              style={{ letterSpacing: 1 }}
              className="text-white text-center text-sm font-extralight"
            >
              Full-stack software development
            </p>
            <p
              style={{ letterSpacing: 1 }}
              className="text-white text-center text-sm font-extralight"
            >
              and Business Development.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUsScreen;
