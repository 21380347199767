import React, { useState, useEffect } from "react";

const Screen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Remote Stop Transaction
      </p>
      <p className="text-slate-600">
        Central System can request a Charge Point to stop a transaction by
        sending a RemoteStopTransaction.req to Charge Point with the identifier
        of the transaction. Charge Point SHALL reply with
        RemoteStopTransaction.conf and a status indicating whether it has
        accepted the request and a transaction with the given transactionId is
        ongoing and will be stopped. This remote request to stop a transaction
        is equal to a local action to stop a transaction. Therefore, the
        transaction SHALL be stopped, The Charge Point SHALL send a
        StopTransaction.req and, if applicable, unlock the connector.
      </p>
    </div>
  );
};

export default Screen;
