import axios from "axios";
import React, { useState, useEffect } from "react";
import { BaseUrl } from "../../static/BaseUrl.js";
import { Modal, message } from "antd";
import LoadingComponent from "../../Components/LoadingComponent.js";

import { Select } from "antd";
const Option = Select.Option;

const EvManufacturersScreen = () => {
  const [photo, setPhoto] = useState();
  const [manufacturerName, setManufacturerName] = useState();

  const [loadingScreen, setLoadingScreen] = useState(true);

  const [manufacturers, setManufacturers] = useState();

  const [carName, setCarName] = useState();

  const [cars, setCars] = useState();

  const [addCarModalOpen, setAddCarModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState();

  const [deleteCarModalOpen, setDeleteCarModalOpen] = useState(false);

  const getCars = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/cars`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setCars(res?.data?.cars);
        console.log(res?.data?.cars);
        setLoadingScreen(false);
      } else {
        setLoadingScreen(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingScreen(false);
    }
  };

  const getManufacturers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/manufacturers`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setManufacturers(res?.data?.manufacturers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCars();
    getManufacturers();
  }, []);

  const ImageComponent = (manufacturer) => {
    try {
      const base64String = btoa(
        String.fromCharCode.apply(null, manufacturer.photo.data.data)
      );

      return (
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              borderRadius: 20,
              borderWidth: 2,
              paddingRight: 40,
              paddingLeft: 40,
              paddingTop: 20,
              paddingBottom: 20,
            }}
            className="shadow-xl"
            onClick={() => {
              setSelectedCar(manufacturer);
              setDeleteCarModalOpen(true);
              console.log(manufacturer);
            }}
          >
            <img
              style={{ height: 100, width: 200 }}
              source={{ uri: manufacturer?.uri }}
              alt="Rendered Image"
            />
            <h2 style={{ textAlign: "center", color: "grey", marginTop: 7 }}>
              {manufacturer?.manufacturer} {manufacturer?.name}
            </h2>
          </div>
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      if (!manufacturerName || !photo) {
        return message.warning("All field required");
      }

      e.preventDefault();

      const ManufacturerData = new FormData();
      ManufacturerData.append("name", carName);
      ManufacturerData.append("photo", photo);
      ManufacturerData.append("token", localStorage.getItem("token"));
      ManufacturerData.append("manufacturer", manufacturerName);
      console.log(carName, manufacturerName);

      const res = await axios.post(`${BaseUrl}/add/new/car`, ManufacturerData);
      if (res?.data?.success) {
        setAddCarModalOpen(false);
        getCars();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteManufacturer = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/delete/car`, {
        token: localStorage.getItem("token"),
        carId: selectedCar?._id,
      });
      if (res?.data?.success) {
        setDeleteCarModalOpen(false);
        getCars();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return loadingScreen ? (
    <LoadingComponent />
  ) : (
    <>
      <Modal
        title="Add Ev Car"
        centered
        open={addCarModalOpen}
        onCancel={() => setAddCarModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </button>,
        ]}
      >
        <div className="mb-4">
          <label className="btn btn-outline-secondary col-md-12">
            Select manufacturer
            <Select
              className="ml-4"
              showSearch
              style={{ width: 300 }}
              placeholder="Select manufacturer"
              optionFilterProp="children"
              onChange={(value) => setManufacturerName(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {manufacturers &&
                manufacturers?.map((manufacturer) => {
                  return (
                    <Option style={{ fontSize: 12 }} value={manufacturer?.name}>
                      {manufacturer?.name}
                    </Option>
                  );
                })}
            </Select>
          </label>
        </div>
        <label className="btn btn-outline-secondary col-md-12 mt-12">
          Car name
          <input
            type="text"
            required
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderRadius: 4,
              marginLeft: 84,
              padding: 4,
            }}
            onChange={(e) => setCarName(e.target.value)}
            // hidden
          />
        </label>

        <div className="mb-3">
          <label
            className="btn btn-outline-secondary col-md-12"
            style={{ textAlign: "center" }}
          >
            {/* {photo ? photo.name : "Upload Photo"} */}
            Upload Photo
            <input
              type="file"
              name="photo"
              accept="image/*"
              onChange={(e) => setPhoto(e.target.files[0])}
              style={{ marginLeft: 60, marginTop: 30 }}
              // hidden
            />
          </label>
        </div>
        <div className="mb-3">
          {photo && (
            <div className="text-center justify-center items-center ml-28">
              <img
                src={URL.createObjectURL(photo)}
                alt="product_photo"
                height={"200px"}
                className="img img-responsive"
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title="Delete Ev Car"
        centered
        open={deleteCarModalOpen}
        onCancel={() => setDeleteCarModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              // backgroundColor: "#001529",
              // color: "white",
              padding: 10,
              borderRadius: 3,
              marginRight: 10,
              borderWidth: 1,
            }}
            className="btn btn-primary"
            onClick={() => setDeleteCarModalOpen(false)}
          >
            Cancel
          </button>,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={() => handleDeleteManufacturer()}
          >
            Delete
          </button>,
        ]}
      >
        <p>Are you sure to delete {selectedCar?.name} car ?</p>
      </Modal>

      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Ev Cars
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddCarModalOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add car
          </a>
        </span>
      </div>

      {/* Show All Manufacturers */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: 40,
        }}
      >
        {cars &&
          cars?.map((manufacturer) => {
            return ImageComponent(manufacturer);
          })}
      </div>
    </>
  );
};

export default EvManufacturersScreen;
