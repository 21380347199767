import React, { useState, useEffect } from "react";

const Screen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Cancel Reservation
      </p>
      <p className="text-slate-600">
        To cancel a reservation the Central System SHALL send an
        CancelReservation.req PDU to the Charge Point. If the Charge Point has a
        reservation matching the reservationId in the request PDU, it SHALL
        return status ‘Accepted’. Otherwise it SHALL return ‘Rejected’
      </p>
    </div>
  );
};

export default Screen;
