import React, { useState, useEffect } from "react";
import { DatePicker, Table, Modal, Button, Input, message } from "antd";
import { Select } from "antd";
import axios from "axios";
import LoadingComponent from "../../Components/LoadingComponent";
import { BaseUrl } from "../../static/BaseUrl";
import exportFromJSON from "export-from-json";

const Option = Select.Option;
const RWAPaymentsDetailsScreen = () => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();

  const [makePaymentModalOpen, setMakePaymentModalOpen] = useState(false);
  const [seletedLocation, setSelectedLocation] = useState();

  const [statusFilter, setStatusFilter] = useState();
  const [locationFilter, setLocationFilter] = useState();
  const [loading, setLoading] = useState(true);

  const [formatedData, setFormatedData] = useState([]);

  const [paymentUTR, setPaymentUTR] = useState();

  const [filterSet, setfilterSet] = useState(false);

  const downloadData = async (data) => {
    const fileName = `MonthlyElectricityBilling-${selectedMonth}-${selectedYear}`;

    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  const onMonthChange = async (date) => {
    try {
      const dateString = date?.toString();
      let month = dateString?.split(" ")[2].trim();
      let year = dateString?.split(" ")[3].trim();

      setSelectedMonth(month);
      setSelectedYear(year);
    } catch (error) {
      console.log(error);
    }
  };

  const getPreviousMonth = (year, month) => {
    // Define the year and month you have

    var monthIndex;
    switch (month) {
      case "Jan":
        monthIndex = 0;
        break;
      case "Feb":
        monthIndex = 1;
        break;
      case "Mar":
        monthIndex = 2;
        break;
      case "Apr":
        monthIndex = 3;
        break;
      case "May":
        monthIndex = 4;
        break;
      case "Jun":
        monthIndex = 5;
        break;
      case "Jul":
        monthIndex = 6;
        break;
      case "Aug":
        monthIndex = 7;
        break;
      case "Sep":
        monthIndex = 8;
        break;
      case "Oct":
        monthIndex = 9;
        break;
      case "Nov":
        monthIndex = 10;
        break;
      case "Dec":
        monthIndex = 11;
        break;
    }

    // Create a Date object for the 1st day of the given month
    const givenDate = new Date(year, monthIndex, 1);

    // Subtract one month
    givenDate.setMonth(givenDate.getMonth() - 1);

    // Get the previous month and year
    const previousMonth = givenDate.getMonth(); // 0-based index
    const previousYear = givenDate.getFullYear();

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the name of the previous month
    const previousMonthName = monthNames[previousMonth];

    // console.log(
    //   `The previous month of ${month} ${year} is ${previousMonthName} ${previousYear}.`
    // );
    return previousMonthName;
  };

  const getPaymentData = async () => {
    try {
      setLoading(true);
      if (!selectedMonth || !selectedYear) {
        // Create a new Date object
        var currentDate = new Date().toString();
        let month = currentDate?.split(" ")[1].trim();
        let year = currentDate?.split(" ")[3].trim();
        // console.log(month, year);
        const res = await axios.post(
          `${BaseUrl}/get/monthly/rwa/payments/data`,
          {
            token: localStorage.getItem("token"),
            month: getPreviousMonth(year, month),
            year,
          }
        );
        if (res?.data?.success) {
          // console.log(res?.data);
          formatBookingData(res?.data?.details);
          setLoading(false);
        }
      } else {
        const res = await axios.post(
          `${BaseUrl}/get/monthly/rwa/payments/data`,
          {
            token: localStorage.getItem("token"),
            month: selectedMonth,
            year: selectedYear,
          }
        );
        if (res?.data?.success) {
          formatBookingData(res?.data?.details);
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentData();
  }, []);

  function convertTo12HourFormat(timeString) {
    const formattedTime = `${timeString?.split(" ")[0]} ${
      timeString?.split(" ")[1]
    } ${timeString?.split(" ")[2]} ${timeString?.split(" ")[3]}`;
    return formattedTime;
  }

  const [unchangedData, setUnchangedData] = useState();

  function maskAccountNumber(accountNumber) {
    // Convert accountNumber to string (if it's not already)
    let strAccountNumber = String(accountNumber);

    // Check if the account number has at least 4 digits
    if (strAccountNumber.length >= 4) {
      // Extract the last 4 characters
      let lastFourDigits = strAccountNumber.slice(-4);
      // Mask the rest of the digits with 'X'
      let maskedString =
        "x".repeat(strAccountNumber.length - 4) + lastFourDigits;
      return maskedString;
    } else {
      // Handle case where account number is less than 4 digits (if necessary)
      return strAccountNumber; // Or handle as per your requirements
    }
  }

  const formatBookingData = (data) => {
    setLoading(true);
    try {
      let resultedData = [];
      data?.map((data) => {
        data.billAmount = parseFloat(data?.billAmount).toFixed(2);
        data.unitsSold = parseFloat(data?.unitsSold).toFixed(2);
        if (data?.status === "Paid") {
          let Time = convertTo12HourFormat(data?.time);
          data.time = Time;
          let accountNumber = maskAccountNumber(
            data?.accountDetails?.accountNumber
          );
          data.accountNumber = accountNumber;
          resultedData.push(data);
        } else {
          data.paymentId = "-";
          data.accountNumber = "-";
          data.time = "-";
          resultedData.push(data);
        }
      });
      setUnchangedData(resultedData);
      setFormatedData(resultedData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterData = async (locationId, paymentStatus) => {
    // console.log(locationId, paymentStatus);
    setLoading(true);
    try {
      if (paymentStatus === "All" && !locationId) {
        setLoading(false);
        return setFormatedData(unchangedData);
      }
      if (locationId && paymentStatus !== "All") {
        const resultedData = [];
        unchangedData?.map((data) => {
          if (data?.status === paymentStatus && data?._id === locationId) {
            resultedData?.push(data);
          }
        });
        setFormatedData(resultedData);
        setLoading(false);
      } else if (locationId) {
        const resultedData = [];
        unchangedData?.map((data) => {
          if (data?._id === locationId) {
            resultedData?.push(data);
          }
        });
        setFormatedData(resultedData);
        setLoading(false);
      } else if (paymentStatus) {
        const resultedData = [];
        unchangedData?.map((data) => {
          if (data?.status === paymentStatus) {
            resultedData?.push(data);
          }
        });
        setFormatedData(resultedData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Location",
      dataIndex: "locationName",
      width: 200,
    },
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      width: 120,
      // fixed: "left",
    },
    {
      title: "Total Units Consumed (kWh)",
      dataIndex: "unitsSold",
      width: 120,
    },
    // {
    //   title: "Electricity Charges (Rs)",
    //   dataIndex: "electricityCharge",
    //   width: 120,
    // },
    {
      title: "Total Electricity Amount (Rs)",
      dataIndex: "billAmount",
      width: 120,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.billAmount - b.billAmount,
    },
    // {
    //   title: "Payment Status",
    //   dataIndex: `status`,
    //   width: 120,
    // },
    {
      title: "Account No.",
      dataIndex: "accountNumber",
      width: 120,
    },
    {
      title: "UTR No.",
      dataIndex: "paymentId",
      width: 120,
    },
    {
      title: "Date of Payment",
      dataIndex: "time",
      width: 180,
    },
    {
      title: "Status",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (text, record) =>
        record.status === "Paid" ? (
          <div className="mx-7">
            <p className="text-center py-2 bg-green-700 rounded-lg text-white">
              Paid
            </p>
          </div>
        ) : (
          <a
            onClick={() => {
              setMakePaymentModalOpen(true);
              setSelectedLocation(record);
            }}
            className="ml-4"
            href="javascript:;"
          >
            <div className="mx-7">
              <p className="text-center cursor-pointer py-2 bg-red-700 rounded-lg text-white">
                Pending
              </p>
            </div>
          </a>
        ),
    },
  ];

  const handleUpdatePaymentDetails = async () => {
    try {
      if (!paymentUTR) {
        return message.warning("UTR number not entered!");
      } else {
        const res = await axios.post(`${BaseUrl}/update/RWA/payment/utr`, {
          token: localStorage?.getItem("token"),
          _id: seletedLocation?._id,
          paymentId: paymentUTR,
        });
        // console.log(res?.data);
        if (res?.data?.success) {
          getPaymentData();
          message.success("Updated successfully.");
        } else {
          message.warning(res?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Confirm delete Location Modal */}
      <Modal
        title="Update Payment Details"
        centered
        open={makePaymentModalOpen}
        onCancel={() => setMakePaymentModalOpen(false)}
        footer={[
          null,
          <Button
            style={{ marginTop: 25 }}
            onClick={() => setMakePaymentModalOpen(false)}
          >
            Cancel
          </Button>,
          <Button
            primary
            onClick={() => {
              setMakePaymentModalOpen(false);
              handleUpdatePaymentDetails();
            }}
          >
            Update
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          {seletedLocation?.locationName}
        </p>

        {/* <div className=" flex justify-center mt-3">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Account no.
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 250 }} className=" text-center pt-0 ">
            <Input
              className="text-center"
              // maxLength={4}
              // showCount
              placeholder={"Account number"}
              onChange={(e) => {
                setPaymentUTR(e?.target?.value);
              }}
              style={{
                width: 200,
              }}
            />
          </p>
        </div> */}

        <div className=" flex justify-center mt-3">
          <p style={{ width: 220 }} className=" text-center pt-1 ">
            Payment UTR
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 250 }} className=" text-center pt-0 ">
            <Input
              className="text-center"
              // maxLength={4}
              // showCount
              placeholder={"UTR"}
              onChange={(e) => {
                setPaymentUTR(e?.target?.value);
              }}
              style={{
                width: 200,
              }}
            />
          </p>
        </div>
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          RWA Payments Details
        </p>
      </div>

      {/* Filter Data */}

      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 5 }}>Select Month</p>
          <DatePicker
            className="ml-4"
            style={{ width: 250 }}
            placeholder="Select month"
            onChange={onMonthChange}
            picker="month"
          />
          <a
            onClick={() => {
              getPaymentData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 ml-2 border rounded-md bg-slate-500 text-white"
          >
            Get Data
          </a>
        </div>
      </div>

      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Location</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 500 }}
            placeholder="Select Location"
            optionFilterProp="children"
            onChange={(value) => {
              setLocationFilter(value);
              filterData(value, statusFilter);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {/* <Option value={undefined}>All</Option> */}
            {unchangedData?.map((value) => {
              return <Option value={value?._id}>{value?.locationName}</Option>;
            })}
          </Select>
        </div>
        <div className="flex">
          <p style={{ marginTop: 2 }}>Status</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select transaction status"
            optionFilterProp="children"
            onChange={(value) => {
              setStatusFilter(value);
              filterData(locationFilter, value);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={"All"}>All</Option>
            <Option value={"Pending"}>Pending</Option>
            <Option value={"Paid"}>Paid</Option>
          </Select>
        </div>
        {/* <div className="flex justify-center">
          <a
            onClick={() => {
              filterData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div> */}
      </div>

      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedData}
          // scroll={{
          //   y: 600,
          // }}
        />
      </div>
    </div>
  );
  //   <DatePicker placeholder="Current month" onChange={onMonthChange} picker="month" />;
};

export default RWAPaymentsDetailsScreen;
