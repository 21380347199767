import React, { useState, useRef, useEffect } from "react";
// import "./HeaderCss.css";
import Layout from "../WebsiteComponents/Layout";
import axios from "axios";
import { BaseUrl } from "../static/BaseUrl";

const Index = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [totalGreenKilometers, setTotalGreenKilometers] = useState();
  const [totalChargingSessions, setTotalChargingSessions] = useState();
  const [ewGlitchVideoPlay, setEwGlitchVideoPlay] = useState(true);
  const [co2saved, setco2saved] = useState(0);

  const vidRef = useRef();

  const getWebsitedata = async () => {
    try {
      const res = await axios.get(`${BaseUrl}/get/website/data`);
      setTotalGreenKilometers(
        (
          (res?.data?.totalUnitsConsumed[0]?.totalUnitsConsumed * 7.5) /
          100000
        ).toFixed(2)
      );
      setTotalChargingSessions((res?.data?.chargingSessions / 1000).toFixed(2));

      const formatedTotalUnitsConsumed = new Intl.NumberFormat("en-IN").format(
        (res?.data?.totalUnitsConsumed[0]?.totalUnitsConsumed * 0.054).toFixed(
          2
        )
      );
      setco2saved(formatedTotalUnitsConsumed);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWebsitedata();
    vidRef?.current?.play();
    setTimeout(() => {
      setEwGlitchVideoPlay(false);
    }, 4000);
  }, []);

  return ewGlitchVideoPlay ? (
    <div style={{ backgroundColor: "#030508" }} className="w-screen h-screen">
      <video
        className="w-screen h-screen"
        src="../static/ElectricGlitchLogoReveal_720p.mp4"
        ref={vidRef}
        muted
        autoPlay
        loop
      />
    </div>
  ) : (
    <Layout>
      <div
        className="w-screen overflow-hidden"
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
        }}
      >
        {/*  */}
        <div className="w-screen">
          <img className="w-full" src="../static/webiste.png" />{" "}
        </div>

        {/*  */}
        <div className="flex flex-col sm:flex-row justify-around sm:mb-0 bg-white">
          <div className="my-10 sm:my-0 sm:mt-32 sm:mb-32 w-full sm:w-2/4 sm:ml-12">
            <h1
              style={{ color: "#545454" }}
              className="text-xl sm:text-4xl  text-blue font-serif text-center sm:text-left"
            >
              Charging Ahead:
            </h1>
            <h1
              style={{ color: "#545454" }}
              className="text-xl sm:text-4xl text-blue font-serif text-center sm:text-left"
            >
              Transforming Mobility
            </h1>
            <h1
              style={{ color: "#545454" }}
              className="text-xl sm:text-4xl text-blue font-serif text-center sm:text-left"
            >
              with Our EV Charging Network.
            </h1>
            <h1
              // style={{ color: "#545454" }}
              className="text-xl mt-5 sm:text-4xl text-green-700 font-serif text-center italic sm:text-left"
            >
              #We Plant Chargers
            </h1>
          </div>
          <div
            // style={{ backgroundColor: "#649235"}}
            className="  sm:w-2/4  overflow-hidden"
          >
            <video
              src="../static/weplantchargers.mp4"
              ref={vidRef}
              muted
              autoPlay
              loop
            />
          </div>
        </div>

        {/*  */}
        <div
          style={{ backgroundColor: "white" }}
          className="font-serif py-10 flex flex-col sm:flex-row"
        >
          <div className="my-10 sm:my-0 sm:w-1/2">
            <img
              style={{ width: "100%" }}
              src="../static/MapWithPinIcons.png"
            />
          </div>
          <div className="flex flex-col justify-center  sm:w-1/2 sm:mr-10">
            <p
              className="text-xl sm:text-4xl text-center sm:text-right sm:mr-5"
              style={{ color: "#545454" }}
            >
              ElectricWeb is contributing to the EV charging ecosystem by
              expanding its charging network and making the future of mobility
              more accessible.
            </p>
          </div>
        </div>

        {/*  */}
        <div className="w-screen font-serif">
          <div className="sm:hidden block absolute w-screen">
            <p
              style={{
                color: "white",
                fontSize: 25,
                fontWeight: 600,
                textAlign: "center",
                marginTop: 30,
                // "-webkit-text-stroke": "1px grey",
              }}
            >
              Driving Toward a Greener Future: Our Charging Network Leads the
              Way.
            </p>
            <p
              style={{
                color: "white",
                fontSize: 25,
                fontWeight: 400,
                textAlign: "center",
                marginTop: 30,
              }}
            >
              Total Green Kilometers
            </p>
            <p
              className=" font-serif"
              style={{
                color: "#C1FF72",
                fontSize: 25,
                fontWeight: 600,
                textAlign: "center",
                width: "100%",
              }}
            >
              {totalGreenKilometers} Lakhs+
            </p>
            <p
              style={{
                color: "white",
                fontSize: 25,
                fontWeight: 400,
                textAlign: "center",
                marginTop: 30,
              }}
            >
              Total Charging Sessions
            </p>
            <p
              className=" font-serif"
              style={{
                color: "#C1FF72",
                fontSize: 25,
                fontWeight: 600,
                textAlign: "center",
                width: "100%",
              }}
            >
              {totalChargingSessions} K+
            </p>
          </div>
          <p
            className="mt-5 font-serif hidden sm:block"
            style={{
              position: "absolute",
              color: "white",
              fontSize: 40,
              fontWeight: 700,
              textAlign: "center",
              width: "100%",
            }}
          >
            Driving Toward a Greener Future: Our Charging Network Leads the Way.
          </p>
          <div
            className="hidden sm:block"
            style={{
              position: "absolute",
              color: "white",
              fontSize: 40,
              fontWeight: 700,
              textAlign: "center",
              width: "66%",
              marginTop: 180,
            }}
          >
            <p className="" style={{}}>
              Total Green Kilometers
            </p>
            <p style={{ color: "#C1FF72" }} className="">
              {totalGreenKilometers} Lakhs+
            </p>
          </div>

          <div
            className="hidden sm:block"
            style={{
              position: "absolute",
              color: "white",
              fontSize: 40,
              fontWeight: 700,
              textAlign: "center",
              marginTop: 350,
              right: "18%",
            }}
          >
            <p className="" style={{}}>
              Total Charging Sessions
            </p>
            <p style={{ color: "#C1FF72" }} className="">
              {totalChargingSessions} K+
            </p>
          </div>

          <img
            style={{ height: 400 }}
            className="w-full sm:hidden block"
            src="../static/hmimg3.jpeg"
          />
          <img
            style={{ height: 600 }}
            className="w-full hidden sm:block"
            src="../static/hmimg3.jpeg"
          />
        </div>
      </div>

      {/* Header */}
      <div className="w-screen" style={{ position: "absolute", top: 0 }}>
        <div
          style={{
            background:
              "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
          }}
          className="px-5 sm:px-14 flex shadow-xl justify-between fixed w-full"
        >
          <div className="block sm:hidden mt-2">
            <i
              onClick={() => setNavbarOpen(!navbarOpen)}
              class="fa-solid fa-bars-staggered"
              style={{
                color: "white",
                fontSize: 30,
                fontWeight: 600,
                marginTop: 18,
              }}
            ></i>
          </div>
          <div>
            <a
              href="/"
              // className="text-right sm:text-left flex"
              style={{
                color: "white",
                // fontSize: 30,
                // fontWeight: 600,
                cursor: "pointer",
              }}
            >
              <img
                className="sm:block hidden mr-5 "
                alt="Images"
                style={{ height: 115, width: 125 }}
                src="../../static/ElectricWeb-Dark-Logo.png"
              />
              <img
                className="block sm:hidden mr-3 my-2"
                alt="Images"
                style={{ height: 70, width: 75 }}
                src="../../static/ElectricWeb-Dark-Logo.png"
              />
              {/* ElectricWeb */}
            </a>
          </div>

          {/* Navigation For Pc Scrren */}
          <div className="hidden sm:flex  justify-center">
            <div className="hidden sm:flex w-full h-full pt-10 flex-row border-0">
              <div id="wrapper">
                <div
                  id="button"
                  className="hover:text-blue-700 text-xl text-white cursor-pointer font-serif pb-5 px-6"
                >
                  Company
                  <i
                    style={{ marginLeft: 10 }}
                    class="fa-solid fa-caret-down"
                  ></i>
                  <div
                    id="drop"
                    style={{ borderRadius: 5, overflow: "hidden" }}
                    className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                  >
                    <a
                      href="aboutus"
                      className="cursor-pointer  rounded font-serif text-blue-700"
                      // id="drop"
                    >
                      About us
                      <p
                        className="text-sm"
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        EW stands for reliable, sustainable & cost-effective
                        charging services.
                      </p>
                      {/* <p className="h-4"></p> */}
                    </a>
                    {/* <a
                    href="aboutus"
                    className="cursor-pointer  rounded text-blue-700"
                    // id="drop"
                  >
                    Products
                    <p
                      className="text-sm"
                      style={{
                        textWrap: "wrap",
                        color: "#545454",
                        width: "100%",
                      }}
                    >
                      hdqgduyiq hiqudhquiodj hiohquihi uguihuiqhuiq
                      dhqiudhioqhioq
                    </p>
                  </a> */}
                  </div>
                </div>
              </div>
              <div id="wrapper">
                <div
                  id="button"
                  className="cursor-pointer text-xl font-serif hover:text-blue-700 text-white pb-5 px-6"
                >
                  Business
                  <i
                    style={{ marginLeft: 10 }}
                    class="fa-solid fa-caret-down"
                  ></i>
                  <div
                    id="drop"
                    style={{ borderRadius: 5, overflow: "hidden" }}
                    className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                  >
                    <a
                      href="services"
                      className="font-serif cursor-pointer rounded text-blue-700"
                      // id="drop"
                    >
                      Services
                      <p
                        className="text-sm"
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        Software backbone for seamless charging and monitoring
                      </p>
                      <p className="h-4"></p>
                    </a>
                    <a
                      href="products"
                      className="cursor-pointer font-serif rounded text-blue-700"
                      // id="drop"
                    >
                      Products
                      <p
                        className="text-sm "
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        Custom built hardware to democratize EV adoption.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div id="wrapper">
                <div id="button" className="text-xl cursor-pointer px-6">
                  <a
                    href="partner"
                    className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Partner
                  </a>
                </div>
              </div>
              <div id="wrapper">
                <div
                  id="button"
                  className="font-serif text-xl cursor-pointer px-6"
                >
                  <a
                    href="resources"
                    className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Resources
                  </a>
                </div>
              </div>

              <div id="wrapper">
                <div
                  id="button"
                  className="text-xl font-serif cursor-pointer px-6"
                >
                  <a
                    href="dashboard"
                    className="text-white w-max font-serif h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:block hidden" style={{ height: 115 }}></div>
        <div className="block sm:hidden" style={{ height: 80 }}></div>

        {/* Navigation For Mobile Scrren */}
        <div
          className="flex-wrap flex-row justify-center shadow-2xl fixed top-20 w-full"
          style={{
            display: navbarOpen ? "block" : "none",
            backgroundColor: "white",
            paddingTop: 25,
          }}
        >
          {/* <a onClick={() => setNavbarOpen(false)} className="absolute right-6">
          <i class="fa-regular text-2xl fa-rectangle-xmark"></i>
        </a> */}

          <div id="wrapper">
            <div
              id="button"
              className="hover:text-blue-700 text-left cursor-pointer font-serif pb-5 px-6"
            >
              Company
              <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
              <div
                id="dropMobile"
                style={{ borderRadius: 5, overflow: "hidden" }}
                className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
              >
                <a
                  href="aboutus"
                  className="cursor-pointer rounded font-serif text-blue-700"
                  // id="drop"
                >
                  About us
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    EW stands for reliable, sustainable & cost-effective
                    charging services.
                  </p>
                  {/* <p className="h-4"></p> */}
                </a>
              </div>
            </div>
          </div>

          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              Business
              <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
              <div
                id="dropMobile"
                style={{ borderRadius: 5, overflow: "hidden" }}
                className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
              >
                <a
                  href="services"
                  className="cursor-pointer rounded  font-serif text-blue-700"
                  // id="drop"
                >
                  Services
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    Software backbone for seamless charging and monitoring.
                  </p>
                  <p className="h-4"></p>
                </a>
                <a
                  href="products"
                  className="cursor-pointer rounded font-serif text-blue-700"
                  // id="drop"
                >
                  Products
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    Custom built hardware to democratize EV adoption.
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="partner">Partner</a>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="resources">Resources</a>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="dashboard">Login</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
