import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const RFIDCardsManagementScreen = () => {
  const [loading, setLoading] = useState(true);
  const [rfidUsers, setRFIDUSers] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [unchangedRequests, setUncahngedRFIDUsers] = useState();
  const [formattedRequestsData, setFormattedRequestsData] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [editRequestModalOpen, setEditRequestModalOpen] = useState();
  const [selectedMobile, setSelectedMobile] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 8,
          },
          wrapperCol: {
            span: 92,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 50,
            offset: 40,
            position: "absolute",
          },
        }
      : null;

  useEffect(() => {
    getRFIDusers();
  }, []);

  const [users, setUsers] = useState();
  const [locations, setLocations] = useState([]);
  const [updatedChargingHours, setUpdatedChargingHours] = useState();

  const getUsers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/chargers/with/captiveCode`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setLocations(res?.data?.locations);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
    getUsers();
  }, []);

  useEffect(() => {
    formatUsersData(rfidUsers, users, locations);
  }, [users, locations, users]);

  const getRFIDusers = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/rfid/card/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setRFIDUSers(res?.data?.users);
        setUncahngedRFIDUsers(res?.data?.users);
        formatUsersData(res?.data?.users, users, locations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatUsersData = (rfidUsers, users, locations) => {
    setLoading(true);
    try {
      const array = [];
      rfidUsers?.map((USER) => {
        const user = users?.find((user) => user?.phone === USER?.phone);
        if (user) {
          let LocationString = "";
          USER?.location?.map((location) => {
            const result = locations?.find(
              (locations) => locations?._id === location
            );
            if (LocationString?.length < 1) {
              LocationString = result?.address?.split(",")[0];
            } else {
              LocationString =
                LocationString + " ☆ " + result?.address?.split(",")[0];
            }
          });
          let data = {
            name: user?.name,
            location: LocationString,
            walletAmount: Number(user?.walletAmount).toFixed(2),
            time: USER?.createdAt?.split("T")[0],
            phone: USER?.phone,
            idTag: USER?.idTag,
            status: USER?.status,
            chargingTimePeriod: USER?.chargingTimePeriod,
            captiveCodes: USER?.location,
            _id: USER?._id,
            rfidBlockedAmount: USER?.rfidBlockedAmount,
          };
          array.push(data);
        }
      });

      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setFormattedRequestsData(array);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterData = () => {
    try {
      if (selectedLocation && selectedMobile) {
        // If Alll filters are applied
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (
            user?.phone === selectedMobile &&
            user?.captiveCodes?.includes(selectedLocation)
          ) {
            resultArray.push(user);
          }
        });
        setFormattedRequestsData(resultArray);
        setLoading(false);
      } else if (selectedMobile) {
        // If only selectedmobile is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.phone === selectedMobile) {
            resultArray.push(user);
          }
        });
        setFormattedRequestsData(resultArray);
        setLoading(false);
      } else if (selectedLocation) {
        // If only IdTag is Selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.captiveCodes?.includes(selectedLocation)) {
            resultArray.push(user);
          }
        });
        setFormattedRequestsData(resultArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    // {
    //   title: "Date",
    //   dataIndex: "time",
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.sortingDate - b.sortingDate,
    // },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "RFID Blocked Amount",
      dataIndex: "rfidBlockedAmount",
      sorter: (a, b) => a.rfidBlockedAmount - b.rfidBlockedAmount,
    },
    {
      title: "Wallet Amount",
      dataIndex: "walletAmount",
      sorter: (a, b) => a.walletAmount - b.walletAmount,
    },
    {
      title: "Idtag",
      dataIndex: "idTag",
    },
    {
      title: "Session Time(Hr)",
      dataIndex: "chargingTimePeriod",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    // },
    {
      title: "Address",
      dataIndex: "location",
      width: 200,
    },

    {
      title: "  Action",
      fixed: "right",
      // width: 100,
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            className="m-5"
            onClick={() => {
              setSelectedUser(record);
              setEditRequestModalOpen(true);
            }}
          >
            <i
              style={{ color: "blue" }}
              class="fa-regular fa-pen-to-square"
            ></i>
          </a>
        </div>
      ),
    },
  ];

  const handleUpdateChargingHours = async () => {
    setLoading(true);
    try {
      if (!updatedChargingHours) {
        setLoading(false);
        return message.warning("No Change Found.");
      }
      if (updatedChargingHours === selectedUser?.chargingTimePeriod) {
        setLoading(false);
        return message.warning("No Change Found.");
      }

      const res = await axios.post(
        `${BaseUrl}/edit/rfid/card/chargingHours/admin`,
        {
          token: localStorage.getItem("token"),
          _id: selectedUser?._id,
          chargingTimePeriod: updatedChargingHours,
        }
      );
      if (res?.data?.success) {
        setEditRequestModalOpen(false);
        message.success(res?.data?.message);
        getRFIDusers();
        setLoading(false);
      } else {
        setLoading(false);
        message.success(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Edit Charging Hours */}
      <Modal
        title="Edit User Details"
        centered
        open={editRequestModalOpen}
        onCancel={() => setEditRequestModalOpen(false)}
        footer={[null]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={form}
        >
          <Form.Item label="Name">
            <Input disabled placeholder={selectedUser?.name} />
          </Form.Item>
          <Form.Item label="Phone">
            <Input disabled placeholder={selectedUser?.phone} />
          </Form.Item>
          <Form.Item label="IdTag">
            <Input disabled placeholder={selectedUser?.idTag} />
          </Form.Item>
          {/* <Form.Item label="Status">
            <Input disabled placeholder={selectedUser?.status} />
          </Form.Item> */}
          <Form.Item label="Charging Time">
            <InputNumber
              maxLength={2}
              max={12}
              min={2}
              showCount
              placeholder={selectedUser?.chargingTimePeriod}
              onChange={(e) => {
                setUpdatedChargingHours(e);
              }}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label="Address">
            <Input disabled placeholder={selectedUser?.location} />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                handleUpdateChargingHours();
              }}
              style={{ position: "absolute", right: 10 }}
              type="default"
            >
              Update
            </Button>
            <Button
              onClick={() => setEditRequestModalOpen(false)}
              style={{ position: "absolute", right: 100 }}
              type="dashed"
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          RFID Cards
        </p>
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        {/* State */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>User Details</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 300 }}
            placeholder="Select User"
            optionFilterProp="children"
            onChange={(value) => setSelectedMobile(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {/* <Option style={{ fontSize: 12 }} value={undefined}>
              {"🧑🏻‍💼  All Users"}
            </Option> */}
            {rfidUsers &&
              rfidUsers?.map((user) => {
                return (
                  <Option style={{ fontSize: 12 }} value={user?.phone}>
                    {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                  </Option>
                );
              })}
          </Select>
        </div>

        {/* For location Filter */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>Select location</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 300 }}
            placeholder="Select location"
            optionFilterProp="children"
            onChange={(value) => setSelectedLocation(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations &&
              locations?.map((location) => {
                return (
                  <Option style={{ fontSize: 12 }} value={location?._id}>
                    {location?.address}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="flex justify-center">
          <a
            onClick={() => filterData()}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getRFIDusers();
            setSelectedMobile(undefined);
            setSelectedLocation(undefined);
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getRFIDusers();
              setSelectedMobile(undefined);
              setSelectedLocation(undefined);
              setfilterSet(false);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>
      {/* Showing Locations */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formattedRequestsData && formattedRequestsData}
          scroll={{
            y: 600,
          }}
        />
      </div>
    </div>
  );
};

export default RFIDCardsManagementScreen;
