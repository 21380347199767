import React, { useState, useEffect } from "react";

const Screen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Clear Charging Profile
      </p>
      <p className="text-slate-600">
        If the Central System wishes to clear some or all of the charging
        profiles that were previously sent the Charge Point, it SHALL use the
        ClearChargingProfile.req PDU. The Charge Point SHALL respond with a
        ClearChargingProfile.conf PDU specifying whether it was able to process
        the request.
      </p>
    </div>
  );
};

export default Screen;
