import React from "react";
import { Spin } from "antd";

const LoadingComponent = () => {
  return (
    <div className="h-screen w-screen " style={{ backgroundColor: "white" }}>
      <Spin
        style={{ paddingTop: "23%" }}
        className="flex justify-center h-screen"
        spinning={true}
      ></Spin>
    </div>
  );
};

export default LoadingComponent;
