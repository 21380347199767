import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { DatePicker, Table } from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const CompletedBookingsScreen = () => {
  const [bookings, setBookings] = useState([]);
  const [users, setUsers] = useState();
  const [formatedBookings, setformatedBookings] = useState([]);
  const [chargersNames, setChargersNames] = useState();
  const [loading, setLoading] = useState(true);

  const [dateRange, setDateRange] = useState();
  const [selectedChargerName, setSelectedChargername] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [selectedMobile, setSelectedmobile] = useState();
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();

  const columns = [
    {
      title: "BookingId",
      dataIndex: "transactionId",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "Name",
      dataIndex: "user",
    },
    {
      title: "Mobile No.",
      dataIndex: "phone",
    },
    {
      title: "Start Time",
      dataIndex: `startTime`,
    },
    {
      title: "End Time",
      dataIndex: "stopTime",
    },
    {
      title: "Charger Name",
      dataIndex: "chargerName",
    },
    {
      title: "Units Consumed",
      dataIndex: "UnitsConsumed",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.UnitsConsumed - b.UnitsConsumed,
    },
    {
      title: "Electricity Price",
      dataIndex: "electricityChargePerkWh",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.electricityChargePerkWh - b.electricityChargePerkWh,
    },
    {
      title: "Service Charge",
      dataIndex: "serviceChargePerkWh",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.serviceChargePerkWh - b.serviceChargePerkWh,
    },
    {
      title: "Blocked Amount",
      dataIndex: "blockedAmount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.blockedAmount - b.blockedAmount,
    },
    {
      title: "Refund Amount",
      dataIndex: "refund",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.refund - b.refund,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidCredits",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.paidCredits - b.paidCredits,
    },
    // {
    //   title: "Updated Wallet Amount",
    //   dataIndex: "finalWalletAmountAfterRefund"
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   filters: [
    //     {
    //       text: "London",
    //       value: "London",
    //     },
    //     {
    //       text: "New York",
    //       value: "New York",
    //     },
    //   ],
    //   filterMultiple: false,
    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    //   sorter: (a, b) => a.address.length - b.address.length,
    //   sortDirections: ["descend", "ascend"],
    // },
  ];

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getCompletedBookings = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/completed/bookings`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setBookings(res?.data?.bookings);
        setChargersNames(res?.data?.chargersName);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function onChange(date, dateString) {
    setDateRange(dateString);
  }

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const formatBookingData = (bookings) => {
    setLoading(true);
    try {
      let array = bookings;
      array?.map((booking) => {
        if (booking?.startTime) {
          booking.startTime =
            booking.startTime.split(" ")[1] +
            "/" +
            booking.startTime.split(" ")[2] +
            "/" +
            booking.startTime.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking.startTime.split(" ")[4]);

          booking.stopTime =
            booking.stopTime.split(" ")[1] +
            "/" +
            booking.stopTime.split(" ")[2] +
            "/" +
            booking.stopTime.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking.stopTime.split(" ")[4]);

          const details = users?.find((user) => user?.idTag === booking?.idTag);
          booking.phone = details?.phone;
          booking.user = details?.name;
        }
        return null;
      });
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setformatedBookings(array);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const filterData = async () => {
    try {
      setLoading(true);
      if (selectedChargerName && selectedMobile && dateRange) {
        // If all filters were seletced
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((booking) => {
          let createdAt = booking?.createdAt?.split("T")[0].split("-").join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            booking?.chargerName === selectedChargerName &&
            booking?.phone === selectedMobile
          ) {
            resultArray.push(booking);
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (dateRange && selectedMobile) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((booking) => {
          let createdAt = booking?.createdAt?.split("T")[0].split("-").join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            booking?.phone === selectedMobile
          ) {
            resultArray.push(booking);
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (dateRange && selectedChargerName) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((booking) => {
          let createdAt = booking?.createdAt?.split("T")[0].split("-").join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            booking?.chargerName === selectedChargerName
          ) {
            resultArray.push(booking);
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (selectedChargerName && selectedMobile) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((booking) => {
          if (
            booking?.chargerName === selectedChargerName &&
            booking?.phone === selectedMobile
          ) {
            resultArray.push(booking);
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (dateRange) {
        // If only Date Range is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((location) => {
          let createdAt = location?.createdAt
            ?.split("T")[0]
            .split("-")
            .join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (createdAt >= from && createdAt <= to) {
            resultArray.push(location);
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (selectedChargerName) {
        // If only charger is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.chargerName === selectedChargerName) {
            resultedArray.push(booking);
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else if (selectedMobile) {
        // If Only Mobile Number is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.phone === selectedMobile) {
            resultedArray.push(booking);
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompletedBookings();
    getUsers();
  }, []);

  useEffect(() => {
    formatBookingData(bookings);
  }, [bookings, users]);

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Past/Completed Bookings
        </p>
      </div>

      {/* Filter Data */}
      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Date Range</p>
          <RangePicker
            className="ml-4"
            style={{ width: 250 }}
            onChange={onChange}
          />
        </div>
        {/* State */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>Charger name</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select charger"
            optionFilterProp="children"
            onChange={(value) => setSelectedChargername(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {chargersNames &&
              chargersNames?.map((chargerName) => {
                return (
                  <Option value={chargerName}>{chargerName.toString()}</Option>
                );
              })}
          </Select>
        </div>
        {/* PinCode */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>Mobile No.</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select Mobile no."
            optionFilterProp="children"
            onChange={(value) => setSelectedmobile(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {users?.map((user) => {
              return <option value={user?.phone}>{user?.phone}</option>;
            })}
          </Select>
        </div>
        <div className="flex justify-center">
          <a
            onClick={() => {
              filterData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getCompletedBookings();
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getCompletedBookings();
              setfilterSet(false);
              setSelectedChargername(undefined);
              setfilterSet(false);
              setSelectedmobile(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>
      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto">
        <Table columns={columns} dataSource={formatedBookings} />
      </div>
    </div>
  );
};

export default CompletedBookingsScreen;
