import React, { useState, useEffect } from "react";
import { DatePicker, message, Table } from "antd";
import { Select } from "antd";
import axios from "axios";
import LoadingComponent from "../../Components/LoadingComponent";
import { BaseUrl } from "../../static/BaseUrl";
import exportFromJSON from "export-from-json";

const Option = Select.Option;
const MonthlyElectricityBillingScreen = () => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [locations, setLocations] = useState();

  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [chargersNames, setChargersNames] = useState();
  const [selectedChargerName, setSelectedChargername] = useState();
  const [selectedMobile, setSelectedmobile] = useState();
  const [totalUnitsConsumed, setTotalUnitsConsumed] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [CGLFees, setCGLFees] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState();

  const [formatedBookings, setformatedBookings] = useState([]);
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [filterSet, setfilterSet] = useState(false);

  const downloadData = async (data) => {
    const fileName = `MonthlyData${
      selectedChargerName ? "_" + selectedChargerName : ""
    }${selectedMonth ? "_" + selectedMonth : ""}${
      selectedYear ? "_" + selectedYear : ""
    }`;

    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  const onMonthChange = async (date) => {
    try {
      const dateString = date?.toString();
      let month = dateString?.split(" ")[2].trim();
      let year = dateString?.split(" ")[3].trim();

      setSelectedMonth(month);
      setSelectedYear(year);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/chargers/with/captiveCode`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setLocations(res?.data?.locations);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getCompletedBookings = async () => {
    try {
      setLoading(true);
      if (!selectedLocation) {
        message.warning("Select month and location...");
      }
      if (!selectedMonth || !selectedYear) {
        // Create a new Date object
        var currentDate = new Date().toString();
        let month = currentDate?.split(" ")[1].trim();
        let year = currentDate?.split(" ")[3].trim();
        const res = await axios.post(
          `${BaseUrl}/get/monthly/bookings/by/location`,
          {
            token: localStorage.getItem("token"),
            month,
            year,
            locationId: selectedLocation,
          }
        );
        // console.log(res.data);
        if (res?.data?.success) {
          setBookings(res?.data?.bookings);
          setChargersNames(res?.data?.chargersName);
          formatBookingData(res?.data?.bookings);
        }
      } else {
        const res = await axios.post(
          `${BaseUrl}/get/monthly/bookings/by/location`,
          {
            token: localStorage.getItem("token"),
            month: selectedMonth,
            year: selectedYear,
            locationId: selectedLocation,
          }
        );
        if (res?.data?.success) {
          setBookings(res?.data?.bookings);
          setChargersNames(res?.data?.chargersName);
          // setPhoneNumbers(res?.data?.phoneNumbers);
          formatBookingData(res?.data?.bookings);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const [actualUnitsConsumed, setActualUnitsConsumed] = useState();

  const formatBookingData = (bookings) => {
    setLoading(true);

    try {
      let array = [];
      var TUC = 0;
      var AUC = 0;
      var TR = 0;
      var CGLFee = 0;
      bookings?.map((booking) => {
        if (booking?.startTime && booking?.UnitsConsumed != 0) {
          booking.startTime =
            booking.startTime?.split(" ")[1] +
            "/" +
            booking.startTime?.split(" ")[2] +
            "/" +
            booking.startTime?.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking?.startTime?.split(" ")[4]);

          booking.stopTime =
            booking.stopTime?.split(" ")[1] +
            "/" +
            booking.stopTime?.split(" ")[2] +
            "/" +
            booking.stopTime?.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking?.stopTime?.split(" ")[4]);

          let data = {
            BookingId: booking?.transactionId,
            StartTime: booking?.startTime,
            StopTime: booking?.stopTime,
            Name: booking?.userName,
            Mobile: booking?.phone,
            ChargerName: booking?.chargerName,
            MeterStart: booking?.meterStart,
            MeterStop: booking?.meterStop,
            ActualUnitsConsumed: (
              (booking?.meterStop - booking?.meterStart) /
              1000
            ).toFixed(2),
            UnitsConsumed: booking?.UnitsConsumed,
            ElectricityTarrif: booking?.electricityChargePerkWh,
            ElectricityAmountPaid: (
              booking?.electricityChargePerkWh * booking?.UnitsConsumed
            ).toFixed(2),
          };

          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
            AUC = AUC + (booking?.meterStop - booking?.meterStart) / 1000;
            // console.log(AUC);
          }
          if (booking?.revenue) {
            TR = TR + booking?.revenue;
          }
          if (booking?.gunLockCharge) {
            TR = TR + Number(booking?.gunLockCharge);
            CGLFee = CGLFee + Number(booking?.gunLockCharge);
          }

          array.push(data);
        }
        return null;
      });
      setTotalUnitsConsumed(TUC.toFixed(2));
      setActualUnitsConsumed(AUC.toFixed(2));
      setRevenue(TR.toFixed(2));
      setCGLFees(CGLFee?.toFixed(2));
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      // console.log(totalUnitsConsumed);
      return setformatedBookings(array);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const filterData = async () => {
    try {
      setLoading(true);
      var TUC = 0;
      if (selectedChargerName && selectedMobile) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((booking) => {
          if (
            booking?.ChargerName === selectedChargerName &&
            booking?.Mobile === selectedMobile
          ) {
            resultArray.push(booking);
          }
          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (selectedChargerName) {
        // If only charger is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.ChargerName === selectedChargerName) {
            resultedArray.push(booking);
          }
          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else if (selectedMobile) {
        // If Only Mobile Number is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.Mobile === selectedMobile) {
            resultedArray.push(booking);
          }
          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
          }
        });
        setformatedBookings(resultedArray);
        setTotalUnitsConsumed(TUC.toFixed(2));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const columns = [
    {
      title: "BookingId",
      dataIndex: "BookingId",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.BookingId - b.BookingId,
      width: 100,
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "Name",
      width: 120,
      fixed: "left",
    },
    {
      title: "Mobile No.",
      dataIndex: "Mobile",
      width: 120,
    },
    {
      title: "Start Time",
      dataIndex: `StartTime`,
      width: 120,
    },
    {
      title: "End Time",
      dataIndex: "StopTime",
      width: 120,
    },
    {
      title: "Charger Name",
      dataIndex: "ChargerName",
      width: 180,
    },
    {
      title: "Meter Start",
      dataIndex: `MeterStart`,
      width: 100,
    },
    {
      title: "Meter Stop",
      dataIndex: "MeterStop",
      width: 100,
    },
    {
      title: "Actual Units Consumed",
      dataIndex: "ActualUnitsConsumed",
      width: 100,
    },
    {
      title: "Units Consumed",
      dataIndex: "UnitsConsumed",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.UnitsConsumed - b.UnitsConsumed,
      width: 100,
    },
    {
      title: "Electricity Tarrif",
      dataIndex: "ElectricityTarrif",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.ElectricityTarrif - b.ElectricityTarrif,
      width: 100,
    },
    {
      title: "Electricity Amount Paid",
      dataIndex: "ElectricityAmountPaid",
      width: 110,
    },
  ];

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Monthly Data
        </p>
      </div>

      {/* Filter Data */}

      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between overflow-scroll">
        <div className="flex">
          <p style={{ marginTop: 5 }}>Select Month</p>
          <DatePicker
            className="ml-4"
            style={{ width: 250 }}
            placeholder="Select month"
            onChange={onMonthChange}
            picker="month"
          />
        </div>
        <div className="flex">
          <p style={{ marginTop: 2 }}>Address</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 500 }}
            placeholder="Select Address"
            optionFilterProp="children"
            onChange={(value) => setSelectedLocation(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations &&
              locations?.map((location) => {
                return (
                  <Option value={location?._id}>{location?.address}</Option>
                );
              })}
          </Select>
          <a
            onClick={() => {
              getCompletedBookings();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 ml-10 border rounded-md bg-slate-500 text-white"
          >
            Get Data
          </a>
        </div>
      </div>

      {formatedBookings?.length ? (
        <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
          <div className="flex">
            <p style={{ marginTop: 2 }}>Charger name</p>
            <Select
              className="ml-4"
              showSearch
              style={{ width: 200 }}
              placeholder="Select charger"
              optionFilterProp="children"
              onChange={(value) => setSelectedChargername(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {chargersNames &&
                chargersNames?.map((charger) => {
                  return (
                    <Option value={charger?.chargerName}>
                      {charger?.chargerName.toString()}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="flex justify-center">
            <a
              onClick={() => {
                filterData();
              }}
              className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
            >
              Filter Data
            </a>
            <a
              onClick={() => {
                downloadData(formatedBookings);
              }}
              className="text-center pr-2 pl-2 pt-1 ml-10 pb-1 border rounded-md bg-slate-500 text-white"
            >
              Download Data
            </a>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="flex justify-between mt-3">
        {filterSet ? (
          <a
            onClick={() => {
              getCompletedBookings();
              setfilterSet(false);
              setSelectedChargername(undefined);
              setfilterSet(false);
              setSelectedmobile(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Completed bookings View */}
      {formatedBookings?.length ? (
        <div className="mt-4 overflow-auto">
          <Table
            columns={columns}
            dataSource={formatedBookings}
            scroll={{
              y: 600,
            }}
          />
        </div>
      ) : (
        ""
      )}

      {formatedBookings?.length ? (
        <div className="flex justify-between my-5">
          <div>
            <p style={{ color: "gray" }}>
              Actual Units Consumed : {actualUnitsConsumed} kWh
            </p>
          </div>
          <div>
            <p style={{ color: "gray" }}>
              Total Units Consumed : {totalUnitsConsumed} kWh
            </p>
          </div>
          <div>
            <p style={{ color: "gray" }}>CGL Fee : Rs {CGLFees}</p>
          </div>
          <div>
            <p style={{ color: "gray" }}>
              Total Revenue : Rs {revenue} (CGL Fee inc.)
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
  //   <DatePicker placeholder="Current month" onChange={onMonthChange} picker="month" />;
};

export default MonthlyElectricityBillingScreen;
