import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const RWAUsersDetails = () => {
  function onChange(date, dateString) {
    setDateRange(dateString);
  }
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const [formatedUserdata, setFormatedUserData] = useState();

  const [selectedMobile, setSelectedMobile] = useState();
  const [selectedIdTag, setSelectedIdTag] = useState();
  const [dateRange, setDateRange] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/RWA/get/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setSelectedIdTag(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    formatUserData(users);
  }, [users]);
  const formatUserData = (users) => {
    setLoading(true);
    try {
      let array = users;

      users?.map((user) => {
        // let vehicles = "";
        // let vehicleNumbers = "";
        let captiveCodes = "";
        user.phone = user?.phone?.toString();
        user.createdAt = user?.createdAt?.split("T")[0];
        user.sortingDate =
          user?.createdAt?.split("T")[0].split("-").join("") || 0;

        user.V = user?.vehicles[0]?.fullName;
        user.VN = user?.vehicles[0]?.vehicleNumber || "NA";
      });
      setLoading(false);
      setUnchangedFormatedTransactions(users);
      return setFormatedUserData(users);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterData = () => {
    try {
      if (dateRange && selectedMobile && selectedIdTag) {
        // If Alll filters are applied
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          let createdAt = user?.createdAt?.split("T")[0].split("-").join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            user?.phone === selectedMobile &&
            user?.idTag === selectedIdTag
          ) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (dateRange && selectedIdTag) {
        // If Date Range Nad IdTag is SleectedsetfilterSet(true)
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          let createdAt = user?.createdAt?.split("T")[0].split("-").join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            user?.idTag === selectedIdTag
          ) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (dateRange && selectedMobile) {
        // If date Range and mobile is slected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          let createdAt = user?.createdAt?.split("T")[0].split("-").join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (
            createdAt >= from &&
            createdAt <= to &&
            user?.phone === selectedMobile
          ) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedIdTag && selectedMobile) {
        // If Selected mobile and idtag filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.phone === selectedMobile && user?.idTag === selectedIdTag) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (dateRange) {
        // If only Date range is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          let createdAt = user?.createdAt?.split("T")[0].split("-").join("");
          let from = dateRange[0].split("-").join("");
          let to = dateRange[1].split("-").join("");
          if (createdAt >= from && createdAt <= to) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedMobile) {
        // If only selectedmobile is filtered
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.phone === selectedMobile) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else if (selectedIdTag) {
        // If only IdTag is Selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((user) => {
          if (user?.idTag === selectedIdTag) {
            resultArray.push(user);
          }
        });
        setFormatedUserData(resultArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("vertical");
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 50,
            offset: 40,
            position: "absolute",
          },
        }
      : null;

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      title: "Date of Registration",
      dataIndex: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.sortingDate - b.sortingDate,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: `email`,
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    // {
    //   title: "Wallet Amount",
    //   dataIndex: "walletAmount",
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.walletAmount - b.walletAmount,
    // },
    // {
    //   title: "IdTag",
    //   dataIndex: "idTag",
    // },

    {
      title: "Vehicles",
      dataIndex: "V",
    },
    {
      title: "Vehicle Number",
      dataIndex: `VN`,
    },
  ];

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          User Details
        </p>
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Date Range</p>
          <RangePicker
            className="ml-4"
            style={{ width: 230 }}
            onChange={onChange}
          />
        </div>
        {/* State */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>Phone No.</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select Mobile Number"
            optionFilterProp="children"
            onChange={(value) => setSelectedMobile(value)}
            filterOption={(input, option) =>
              option.props.children.indexOf(input) >= 0
            }
          >
            {unchangedFormatedTransactions &&
              unchangedFormatedTransactions?.map((user) => {
                return (
                  <Option value={user?.phone}>
                    {" "}
                    {user?.phone?.toLowerCase()}
                  </Option>
                );
              })}
          </Select>
        </div>

        <div className="flex justify-center">
          <a
            onClick={() => filterData()}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getUsers();
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getUsers();
              setfilterSet(false);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>
      {/* Showing Locations */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedUserdata && formatedUserdata}
          onChange={onChange}
        />
      </div>
      <div>{JSON.stringify(selectedIdTag)}</div>
    </div>
  );
};

export default RWAUsersDetails;
