import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";
import { websocketConnectionUrl } from "../../static/BaseUrl.js";

const Option = Select.Option;

const ChargersLiveLogsScreen = () => {
  const [wsGlobal, setWsGlobal] = React.useState("");
  const [chargersStatus, setChargerStatus] = useState();

  const [reverseLogs, setReverseLogs] = useState([]);
  const [selectedCharger, setSelectedCharger] = useState();

  const [messagesStack, setMessageStack] = useState([]); // This will act as our stack

  const MAX_STACK_SIZE = 50; // Maximum stack size

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      setInterval(() => {
        let sendData = {
          From: "BD8746G645VWU83",
        };
        ws.send(JSON.stringify(sendData));
      }, 1000 * 5);

      ws.onmessage = (e) => {
        try {
          if (
            e.data.split("*")[0] === "StatusNotification " &&
            !chargersStatus
          ) {
            if (!chargersStatus) {
              const arrayString = e.data.split("*")[1];
              const array = JSON.parse(arrayString);
              // const mapFromJSON = array.reduce((map, [key, value]) => {
              //   map.set(key, value);
              //   return map;
              // }, new Map());
              setChargerStatus(array);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
  }, []);

  useEffect(() => {
    if (selectedCharger) {
      connectWs(selectedCharger);
    }
  }, [selectedCharger]);

  const connectWs = async (selectedCharger) => {
    try {
      const logs = messagesStack;
      var ws = new WebSocket(websocketConnectionUrl);
      ws.onopen = () => {
        let sendData = {
          From: "BD8746G645VWU83",
        };
        ws.send(JSON.stringify(sendData));
        ws.onmessage = (e) => {
          try {
            if (
              e.data.split("*")[0] === "from " &&
              e.data.split("*")[1] === ` ${selectedCharger} ` &&
              selectedCharger
            ) {
              if (messagesStack.length >= MAX_STACK_SIZE) {
                messagesStack.shift(); // Remove the oldest message when the stack is full
              }

              logs.push(e.data.split("*")[2]?.toString());
              setMessageStack(logs?.reverse()); // Add the new message to the stack
              // Notify React to update the UI

              // setReverseLogs(logs?.reverse());
            }
          } catch (error) {
            console.log(error);
          }
        };

        ws.onclose = (e) => {
          ws.close();
        };
      };
      setWsGlobal(ws);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="w-full flex flex-col sm:flex-row justify-center mb-10 sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Charger Logs : {selectedCharger}
        </p>

        {/* Select Charger */}
        <div
          style={{ display: selectedCharger ? "none" : "flex" }}
          className="mt-2 ml-10 flex flex-col flex-wrap sm:flex-row justify-between "
        >
          <div className="flex">
            <p style={{ marginTop: 2 }}>Select Charger</p>
            <Select
              className="ml-4"
              showSearch
              style={{ width: 200 }}
              placeholder="Select charger"
              optionFilterProp="children"
              onChange={(value) => setSelectedCharger(value)}
              onSelect={(value) => setSelectedCharger(value)}
              filterOption={(input, Option) =>
                Option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {chargersStatus &&
                chargersStatus?.map((chargerName) => {
                  return (
                    <Option value={chargerName[0]}>
                      {chargerName[0].toString()}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div>
      </div>

      {/* Showing Charegr Logs */}
      {messagesStack?.map((log) => {
        return (
          <p
            style={{ fontSize: 15 }}
            className="my-3 p-4 rounded-lg text-gray-700 shadow-xl font-semi-bold"
          >
            {log}
          </p>
        );
      })}
    </>
  );
};

export default ChargersLiveLogsScreen;
