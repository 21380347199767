import React, { useState, useEffect } from "react";

const Screen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Change Availability
      </p>
      <p className="text-slate-600">
        Central System can request a Charge Point to change its availability. A
        Charge Point is considered available (“operative”) when it is charging
        or ready for charging. A Charge Point is considered unavailable when it
        does not allow any charging. The Central System SHALL send a
        ChangeAvailability.req PDU for requesting a Charge Point to change its
        availability. The Central System can change the availability to
        available or unavailable. Upon receipt of a ChangeAvailability.req PDU,
        the Charge Point SHALL respond with a ChangeAvailability.conf PDU. The
        response PDU SHALL indicate whether the Charge Point is able to change
        to the requested availability or not. When a transaction is in progress
        Charge Point SHALL respond with availability status 'Scheduled' to
        indicate that it is scheduled to occur after the transaction has
        finished.
      </p>
    </div>
  );
};

export default Screen;
