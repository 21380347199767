import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../static/BaseUrl.js";
import { Button, Modal } from "antd";
import CircleImage from "../../static/circle.png";
import PieChartGraph from "../../Components/PieChartGraph.js";

const RWADashboardScreen = () => {
  const [modal2Open, setModal2Open] = useState(false);

  const [locationDetails, setLocationDetails] = useState();
  const [captiveCode, setCaptiveCode] = useState();
  const [mostActiveUsers, setMostActiveUsers] = useState([]);
  const [pieChartData, setPieChartData] = useState();
  const [bookingSessions, setBookingSessions] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [chargersCount, setChargersCount] = useState(0);
  const [chargersTypes, setChargersTypes] = useState([]);
  const [connectorTypes, setConnectorTypes] = useState();
  const [chargers, setChargers] = useState();
  const [electricityPrice, setElectricityPrice] = useState();
  const [serviceCharge, setServiceCharge] = useState();

  const getLocationDetails = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/rwa/get/location/details`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        localStorage.setItem("location", JSON.stringify(res?.data?.location));
        setLocationDetails(res?.data?.location);
        setCaptiveCode(res?.data?.location?.captiveCode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDashBoardDetails = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/RWA/get/dahsboard/details`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setBookingSessions(res?.data?.bookingsCount);
        setUsersCount(res?.data?.usersCount);
        setChargersCount(res?.data?.chargers?.length);
        getMaximumLatestUsedchargers(res?.data?.bookings);
        setChargersTypes(res?.data?.connectorTypes);
        setConnectorTypesfunction(res?.data?.chargers);
        setChargers(res?.data?.chargers);
      } else {
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
    }
  };

  useEffect(() => {
    setConnectorTypesfunction();
  }, [chargers]);
  useEffect(() => {
    if (!localStorage.getItem("location")) {
      getLocationDetails();
    } else {
      setLocationDetails(JSON.parse(localStorage.getItem("location")));
    }
    getDashBoardDetails();
  }, []);

  const setConnectorTypesfunction = async () => {
    try {
      const typesSet = new Set();

      let availableConnectors = "";

      chargers?.map((charger) => {
        typesSet.add(charger?.connectorType);
        setElectricityPrice(charger?.electricityChargePerkWh);
        setServiceCharge(charger?.serviceChargePerkWh);
      });

      chargersTypes?.map((chargerType) => {
        if (typesSet?.has(chargerType?._id)) {
          if (availableConnectors?.length) {
            availableConnectors =
              availableConnectors + " , " + chargerType?.name;
          } else {
            availableConnectors = availableConnectors + chargerType?.name;
          }
        }
      });

      setConnectorTypes(availableConnectors);

      console.log(availableConnectors);
    } catch (error) {
      console.log(error);
    }
  };

  const getMaximumLatestUsedchargers = async (data) => {
    try {
      // Group chargers by charger name and calculate total UnitsConsumed
      const usersMap = new Map();

      // console.log(data);

      data?.forEach((item) => {
        const userName = item.userName;

        const unitsConsumed = parseFloat(item.UnitsConsumed);

        if (!isNaN(unitsConsumed)) {
          if (usersMap.has(userName)) {
            usersMap.set(userName, usersMap.get(userName) + unitsConsumed);
          } else {
            usersMap.set(userName, unitsConsumed);
          }
        }
      });
      // console.log(usersMap);

      // Convert the Map to an array of charger object

      const usersArray = Array.from(
        usersMap,
        ([userName, totalUnitsConsumed]) => ({
          userName,
          totalUnitsConsumed: totalUnitsConsumed.toFixed(2), // Format to two decimal places
        })
      );

      // Sort the chargers by total UnitsConsumed in descending order
      usersArray.sort((a, b) => b.totalUnitsConsumed - a.totalUnitsConsumed);

      // Take the top 5 chargers
      const top5users = usersArray.slice(0, 5);

      // Log the top 5 chargers with their total UnitsConsumed
      setMostActiveUsers(top5users);
    } catch (error) {
      console.log("Get MAXIMUM LATEST CHARGER ERROR => " + error);
    }
  };

  const SetPieChartDatafromMostActiveUsers = () => {
    try {
      const labels = [];
      const DatasetData = [];
      mostActiveUsers?.map((user) => {
        if (!user?.userName) {
          labels.push("User");
        } else {
          labels.push(user?.userName);
        }
        DatasetData.push(user?.totalUnitsConsumed);
      });
      const data = {
        labels: labels,
        datasets: [
          {
            label: ` Units Consumed `,
            data: DatasetData,
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: ["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"],
            borderWidth: 4,
          },
        ],
      };
      setPieChartData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    SetPieChartDatafromMostActiveUsers();
  }, [mostActiveUsers]);

  return (
    <div className="items-center justify-center h-full w-full ">
      {/* Modal for View Captive Code */}

      <div>
        <div style={{ width: "100%" }}>
          <p className="text-xl text-center">{locationDetails?.address}</p>
          <div className="text-center mt-4">
            <a className="text-center mt-4" onClick={() => setModal2Open(true)}>
              Show Captive Code
            </a>
          </div>
        </div>
        <Modal
          title={`Captive code for ${locationDetails?.address}`}
          centered
          open={modal2Open}
          onCancel={() => setModal2Open(false)}
          footer={[null]}
        >
          <div className="text-center mt-6 mb-6 text-sm">
            {captiveCode ? captiveCode : "Generate captive code"}
          </div>
          <div style={{ minHeight: 10 }}>
            {captiveCode ? (
              <Button
                onClick={() => {
                  setModal2Open(false);
                }}
                style={{ position: "absolute", right: 30 }}
                type="dashed"
              >
                Close
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    getLocationDetails();
                  }}
                  style={{ position: "absolute", right: 10 }}
                  type="default"
                >
                  Generate
                </Button>
                <Button
                  onClick={() => {
                    setModal2Open(false);
                  }}
                  style={{ position: "absolute", right: 120 }}
                  type="dashed"
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </Modal>

        {/* Analysis Modals For dashboard */}
      </div>
      <div className="items-center justify-center h-full w-full">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: 12,
          }}
        >
          <div
            className="shadow-2xl"
            style={{
              height: 120,
              width: 230,
              background: `linear-gradient(260deg, #fa7c30 0%, rgba(0, 0, 0, 0)190%)`,
              margin: 20,
            }}
          >
            <img
              alt="Circle"
              style={{
                position: "absolute",
                marginLeft: 120,
                height: 120,
                width: 120,
              }}
              src={CircleImage}
            />
            <div className="mt-6">
              <p className=" text-center" style={{ color: "white" }}>
                Total Users
              </p>
              <p
                className="m-5 text-center text-4xl font-bold"
                style={{ color: "white" }}
              >
                {usersCount < 10 ? "0" + usersCount : usersCount}
              </p>
            </div>
          </div>
          <div
            className="shadow-2xl"
            style={{
              height: 120,
              width: 230,
              background: `linear-gradient(260deg, #F13D00 0%, rgba(0, 0, 0, 0)190%)`,
              margin: 20,
            }}
          >
            <img
              alt="Circle"
              style={{
                position: "absolute",
                marginLeft: 120,
                height: 120,
                width: 120,
              }}
              src={CircleImage}
            />
            <div className="mt-6">
              <p className=" text-center" style={{ color: "white" }}>
                Total Chargers
              </p>
              <p
                className="m-5 text-center text-4xl font-bold"
                style={{ color: "white" }}
              >
                {chargersCount < 10 ? "0" + chargersCount : chargersCount}
              </p>
            </div>
          </div>

          <div
            className="shadow-2xl"
            style={{
              height: 120,
              width: 230,
              background: `linear-gradient(260deg, #A400DA 0%, rgba(0, 0, 0, 0)190%)`,
              margin: 20,
            }}
          >
            <img
              alt="Circle"
              style={{
                position: "absolute",
                marginLeft: 120,
                height: 120,
                width: 120,
              }}
              src={CircleImage}
            />
            <div className="mt-6">
              <p className=" text-center" style={{ color: "white" }}>
                Total Charging sessions
              </p>
              <p
                className="m-5 text-center text-4xl font-bold"
                style={{ color: "white" }}
              >
                {bookingSessions}
              </p>
            </div>
          </div>
          {/* Connector Types */}
          <div
            className="shadow-2xl "
            style={{
              height: 120,
              width: 230,
              background: `linear-gradient(260deg, #FF002B 0%, rgba(0, 0, 0, 0)190%)`,
              margin: 20,
            }}
          >
            <img
              alt="Circle"
              style={{
                position: "absolute",
                marginLeft: 120,
                height: 120,
                width: 120,
              }}
              src={CircleImage}
            />
            <div className="mt-6">
              <p className=" text-center" style={{ color: "white" }}>
                Connector Type
              </p>
              <p
                className="m-5 text-center text-4xl font-bold"
                style={{ color: "white" }}
              >
                {connectorTypes}
              </p>
            </div>
          </div>
          {/* Eelctricity Tarrif */}
          <div
            className="shadow-2xl "
            style={{
              height: 120,
              width: 230,
              background: `linear-gradient(260deg, #AA123B 0%, rgba(0, 0, 0, 0)190%)`,
              margin: 20,
            }}
          >
            <img
              alt="Circle"
              style={{
                position: "absolute",
                marginLeft: 120,
                height: 120,
                width: 120,
              }}
              src={CircleImage}
            />
            <div className="mt-6">
              <p className=" text-center" style={{ color: "white" }}>
                Electricity Tarrif
              </p>
              <p
                className="m-5 text-center text-4xl font-bold"
                style={{ color: "white" }}
              >
                Rs {electricityPrice && parseFloat(electricityPrice).toFixed(2)}
              </p>
            </div>
          </div>
          {/* Service Charge */}
          <div
            className="shadow-2xl "
            style={{
              height: 120,
              width: 230,
              background: `linear-gradient(260deg, #AA000B 0%, rgba(0, 0, 0, 0)190%)`,
              margin: 20,
            }}
          >
            <img
              alt="Circle"
              style={{
                position: "absolute",
                marginLeft: 120,
                height: 120,
                width: 120,
              }}
              src={CircleImage}
            />
            <div className="mt-6">
              <p className=" text-center" style={{ color: "white" }}>
                Service Charge
              </p>
              <p
                className="m-5 text-center text-4xl font-bold"
                style={{ color: "white" }}
              >
                Rs {serviceCharge && parseFloat(serviceCharge).toFixed(2)}{" "}
                <span style={{ fontSize: 10 }}>inc. taxes </span>
              </p>
            </div>
          </div>
        </div>
        {/* Pie Chart */}
        {/* <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <div style={{ width: 400 , marginLeft: window.innerWidth/2 - 400 }}>
           
            <p className="text-center bg-slate-800 text-white mt-5 sm:mt-0">
              Top Users <span className="font-light">( last 30 days )</span>
            </p>
            {pieChartData ? <PieChartGraph data={pieChartData} /> : ""}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RWADashboardScreen;
