import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <div
      id="FAQs"
      className="px-5 font-serif sm:px-14 py-5 sm:py-10   flex  flex-col sm:flex-row justify-between"
      style={{ backgroundColor: "black" }}
    >
      {/* Section 1 */}
      <div>
        <h2
          className="text-center sm:text-left"
          style={{ color: "white", fontSize: 45, fontWeight: 500 }}
        >
          Get in touch
        </h2>
        <h2
          className="text-center sm:text-left"
          style={{
            color: "white",
            fontSize: 45,
            fontWeight: 500,
            marginBottom: 20,
          }}
        >
          today
        </h2>
        <a
          href="tel:9205427914"
          className="text-white rounded-lg pr-5 py-2  sm:mt-10 cursor-pointer flex justify-center sm:justify-start"
          style={{
            background:
              "linear-gradient(90deg, rgba(21,4,36,1) 0%, rgba(159,16,54,1) 48%, rgba(168,61,61,1) 100%)",
          }}
        >
          <i
            class="fa-solid fa-phone "
            style={{ color: "white", marginRight: 15, marginTop: 5 }}
          ></i>
          Call us
        </a>
        <a
          href="mailto: reachout@electricweb.in"
          className="text-white rounded-lg pr-5 py-2 mt-4 sm:mt-3 cursor-pointer flex justify-center sm:justify-start"
          style={{
            background:
              "linear-gradient(90deg, rgba(21,4,36,1) 0%, rgba(159,16,54,1) 48%, rgba(168,61,61,1) 100%)",
          }}
        >
          <i
            style={{ color: "white", marginRight: 15, marginTop: 5 }}
            class="fa-solid fa-envelope"
          ></i>
          Mail us
        </a>
        {/* Social Links */}
        {/* <div className="mt-7 flex justify-center sm:justify-start  ">
          <a href="https://in.linkedin.com/company/electricweb-india">
            <i
              class="fa-brands fa-linkedin cursor-pointer "
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>
          <a href="https://x.com/ElectricwebI?s=20">
            <i
              class="fa-brands fa-square-twitter ml-3"
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>
          <a href="https://www.facebook.com/people/ElectricWeb/100064175674493/">
            <i
              class="fa-brands fa-square-facebook ml-3"
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>{" "}
          <a href="https://www.instagram.com/electricwebindia/">
            <i
              class="fa-brands fa-square-instagram ml-3"
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>
        </div> */}
      </div>
      {/* Section 2 */}
      <div className="flex flex-col mt-12 sm:mt-3 items-center">
        <p className="text-white text-center sm:text-left">Quick Links</p>
        <a
          href="aboutus"
          className="text-center sm:text-left"
          style={{ color: "grey" }}
        >
          About Us
        </a>
        {/* <a className="text-center sm:text-left" style={{ color: "grey" }}>
          Charge Cloud
        </a> */}
        <a
          href="products"
          className="text-center sm:text-left"
          style={{ color: "grey" }}
        >
          Products
        </a>
        <a
          href="resources"
          className="text-center sm:text-left"
          style={{ color: "grey" }}
        >
          FAQs
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.acdcelectric.urja"
          className="cursor-pointer mt-14"
        >
          <img
            className="h-12"
            src="../static/googlePlayStoreAppDownload.png"
          />
        </a>
        <a
          href="https://apps.apple.com/in/app/electricweb/id1569958711"
          className="cursor-pointer"
        >
          <img
            style={{ height: 36 }}
            className="mt-4"
            src="../static/IosAppDownload.png"
          />
        </a>
      </div>

      {/* Section 3 */}
      <div className="flex flex-col mt-3 ">
        <div className="flex flex-col items-center sm:items-end  mt-5 sm:mt-0">
          <div className="flex flex-col items-center">
            {/* <img
              style={{ height: 70, width: 75 }}
              src="../static/ElectricWeb-Dark-Logo.png"
            /> */}
            <p
              className="text-white font-extrabold text-center"
              style={{ letterSpacing: 2 }}
            >
              ElectricWeb
            </p>
            <p className="text-white font-thin text-sm text-center ">
              EV Charging Simplified
            </p>
          </div>
        </div>
        <p className="text-white text-center sm:text-right mt-5">
          ACDC Electric Infrastructure Pvt Ltd.
        </p>
        <p style={{ color: "grey" }} className="text-center sm:text-right">
          Registered Office UB-36A, Antriksh Bhawan, KG Marg.
        </p>
        <p style={{ color: "grey" }} className="text-center sm:text-right">
          New Delhi - 110001, INDIA.
        </p>

        <div className="mt-7 flex justify-center sm:justify-end  ">
          <a href="https://in.linkedin.com/company/electricweb-india">
            <i
              class="fa-brands fa-linkedin cursor-pointer "
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>
          <a href="https://x.com/ElectricwebI?s=20">
            <i
              class="fa-brands fa-square-x-twitter ml-3"
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>
          <a href="https://www.facebook.com/people/ElectricWeb/100064175674493/">
            <i
              class="fa-brands fa-square-facebook ml-3"
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>{" "}
          <a href="https://www.instagram.com/electricwebindia/">
            <i
              class="fa-brands fa-square-instagram ml-3"
              style={{ color: "white", fontSize: 25 }}
            ></i>
          </a>
        </div>

        {/* <p className="text-white text-center sm:text-right mt-5">
          24x7 Customer Support
        </p>
        <p className="text-white text-center sm:text-right flex flex-col sm:flex-row">
          <a
            href="mailto: reachout@electricweb.in"
            className="text-white  cursor-pointer"
          >
            <i
              class="fa-solid fa-envelope"
              style={{ color: "white", marginRight: 7, marginTop: 3 }}
            ></i>
            reachout@electricweb.in
          </a>
          <a href="tel:9205427914" className="text-white cursor-pointer ml-5">
            <i
              class="fa-solid fa-phone"
              style={{ color: "white", marginRight: 7, marginTop: 3 }}
            ></i>
            +91 9205427914
          </a>
        </p> */}
      </div>
    </div>
  );
};

export default Footer;
