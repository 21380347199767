import React, { useState, useEffect } from "react";
import { Button, Select, message, Modal } from "antd";
import axios from "axios";
import { BaseUrl, websocketConnectionUrl } from "../../../static/BaseUrl.js";

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();

  const [selectedCharger, setSelectedCharger] = useState();
  const [selectedtriggerMessage, setSelectedTriggerMessage] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState();

  const handleSendRequest = async (socket) => {
    try {
      setTriggerMessageModalOpen(true);
      let sendData = {
        From: "BD8746G645VWU83",
        To: `${selectedCharger}`,
        message: [
          2,
          `${selectedtriggerMessage}`,
          "TriggerMessage",
          {
            requestedMessage: selectedtriggerMessage,
          },
        ],
      };
      await socket.send(JSON.stringify(sendData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[1] === selectedtriggerMessage
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[5];
        setResponseMessage(message);
      } else if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === selectedtriggerMessage
      ) {
        setResponseMessage(rawResponseData.split("*")[2]);

        updateChargerDetailsFromBootNotification(rawResponseData.split("*")[2]);
      }
    }
  }, [rawResponseData]);

  const updateChargerDetailsFromBootNotification = async (data) => {
    try {
      if (data && JSON.parse(data)[2] === "BootNotification") {
        if (data && JSON.parse(data)[3]?.chargePointVendor === "Kazam") {
          let sendedData = JSON.parse(data)[3];
          sendedData.chargePointSerialNumber =
            JSON.parse(data)[3]?.chargeBoxSerialNumber;

          let fromattedData = [2, "Kazam", "BootNotification", sendedData];

          const res = await axios.post(
            `${BaseUrl}/update/charger/details/bootnotification`,
            {
              token: localStorage.getItem("token"),
              data: fromattedData,
            }
          );
        } else {
          const res = await axios.post(
            `${BaseUrl}/update/charger/details/bootnotification`,
            {
              token: localStorage.getItem("token"),
              data: JSON.parse(data),
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
      // message.error("Error in server...");
    }
  };

  useEffect(() => {
    var ws = new WebSocket(websocketConnectionUrl);
    ws.onopen = () => {
      let sendData = {
        From: "BD8746G645VWU83",
      };
      ws.send(sendData);

      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const chargersMap = new Map();
            const array = JSON.parse(arrayString);
            setCharegrStatusArray(array);
            array.map((charger) => {
              return chargersMap.set(charger[0], charger[1]);
            });
            if (refresh === true) {
              refresh = false;
              // setChargerStatus(chargersMap);
            }
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <Modal
        // title="Confirm"
        centered
        open={triggerMessageModalOpen}
        onCancel={() => {
          setTriggerMessageModalOpen(false);
          setResponseMessage("Waiting for response...");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setTriggerMessageModalOpen(false);
              setResponseMessage("Waiting for response...");
            }}
          >
            Cancel
          </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">Trigger Message</p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Trigger Message
      </p>
      <p className="text-slate-600">
        The Charge Point SHALL first send the TriggerMessage response, before
        sending the requested message. In the TriggerMessage.conf the Charge
        Point SHALL indicate whether it will send it or not, by returning
        ACCEPTED or REJECTED. It is up to the Charge Point if it accepts or
        rejects the request to send. If the requested message is unknown or not
        implemented the Charge Point SHALL return NOT_IMPLEMENTED. Messages that
        the Charge Point marks as accepted SHOULD be sent. The situation could
        occur that, between accepting the request and actually sending the
        requested message, that same message gets sent because of normal
        operations. In such cases the message just sent MAY be considered as
        complying with the request. The TriggerMessage mechanism is not intended
        to retrieve historic data. The messages it triggers should only give
        current information. A MeterValues.req message triggered in this way for
        instance SHALL return the most recent measurements for all measurands
        configured in configuration key MeterValuesSampledData. StartTransaction
        and StopTransaction have been left out of this mechanism because they
        are not state related, but by their nature describe a transition.
      </p>

      <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              return (
                <option
                  style={{ color: charger[1] === "Unavailable" ? "red" : "" }}
                  value={charger[0]}
                >
                  {charger[0]}
                </option>
              );
              // if (charger[1] !== "Unavailable") {
              //   return <option value={charger[0]}>{charger[0]}</option>;
              // }
            })}
        </Select>

        {selectedCharger ? (
          <Select
            style={{ width: 200, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select Trigger Message"}
            onChange={(value) => setSelectedTriggerMessage(value)}
          >
            <option value="BootNotification">BootNotification</option>
            <option value="DiagnosticsStatusNotification">
              DiagnosticsStatusNotification
            </option>
            <option value="FirmwareStatusNotification">
              FirmwareStatusNotification
            </option>
            <option value="Heartbeat">Heartbeat</option>
            <option value="MeterValues">MeterValues</option>
            <option value="StatusNotification">StatusNotification</option>
          </Select>
        ) : (
          ""
        )}
      </div>

      {selectedtriggerMessage ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Screen;
