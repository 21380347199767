import React, { useState, useRef, useEffect } from "react";
import { BaseUrl } from "../static/BaseUrl.js";

import Layout from "../WebsiteComponents/Layout";
import axios from "axios";

const ResourcesScreen = () => {
  const vidRef = useRef();

  const [faqs, setFAqs] = useState([]);

  const getWebsitedata = async () => {
    try {
      const res = await axios.get(`${BaseUrl}/get/faq/data`);

      setFAqs(res?.data?.faqs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWebsitedata();
    vidRef?.current?.play();
  }, []);
  return (
    <Layout>
      <div
        className="font-serif"
        style={{ backgroundColor: "white", paddingTop: 10 }}
      >
        <p
          // style={{ color: "#545454" }}
          className="text-center font-bold text-3xl sm:text-6xl pt-10"
        >
          How to start a charging session?
        </p>
        {/* Mobile Application */}
        <div className="flex flex-col sm:flex-row  justify-around sm:py-20 sm:mx-28">
          <div className="flex sm:w-3/4  ">
            <div>
              <p
                style={{ color: "#545454" }}
                className="font-bold text-lg ml-4  sm:text-4xl pt-4"
              >
                ElectricWeb mobile application
              </p>
              <p
                style={{ color: "#545454" }}
                className="ml-10 mt-1 sm:mt-10 font-bold"
              >
                1. Sign up/Sign In to enjoy unparalleled EV Charging Experience
              </p>
              <p style={{ color: "#545454" }} className="ml-10 mt-2 font-bold">
                2. Discover your location from the search bar or by clicking
                location marker
              </p>
              <p style={{ color: "#545454" }} className="ml-10 mt-2 font-bold">
                3. Add charger to your favorite list
              </p>
              <p style={{ color: "#545454" }} className="ml-10 mt-2 font-bold">
                4. Explore our menu options to manage many other features
              </p>
              <p style={{ color: "#545454" }} className="ml-10 mt-2 font-bold">
                5. Seek your one-time code to access captive chargers
              </p>
              <p style={{ color: "#545454" }} className="ml-10 mt-2 font-bold ">
                6. Select the available connecter
              </p>
              <p style={{ color: "#545454" }} className="ml-10 mt-2 font-bold">
                7. Swipe to choose the no. of hours and start charging
              </p>

              <div className="hidden  sm:flex justify-center py-10">
                <a
                  href="https://play.google.com/store/apps/details?id=com.acdcelectric.urja"
                  className="cursor-pointer "
                >
                  <img
                    style={{ height: 47 }}
                    src="../static/googlePlayStoreAppDownload.png"
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/electricweb/id1569958711"
                  className="cursor-pointer"
                >
                  <img
                    style={{ height: 36, marginTop: 6 }}
                    className="ml-10"
                    src="../static/IosAppDownload.png"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="sm:w-1/4 flex flex-col justify-center items-center border-0 py-10 sm:py-0">
            <video
              // style={{ height: 300, width: "100%" }}
              className="sm:h-80 h-60"
              src="../static/resource1.mp4"
              ref={vidRef}
              muted
              autoPlay
              loop
            />
            <div className="sm:hidden  flex justify-center py-10">
              <a
                href="https://play.google.com/store/apps/details?id=com.acdcelectric.urja"
                className="cursor-pointer "
              >
                <img
                  style={{ height: 47 }}
                  src="../static/googlePlayStoreAppDownload.png"
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/electricweb/id1569958711"
                className="cursor-pointer"
              >
                <img
                  style={{ height: 36, marginTop: 6 }}
                  className="ml-10"
                  src="../static/IosAppDownload.png"
                />
              </a>
            </div>
          </div>
        </div>

        {/* Rfid Tag */}
        <div className="flex flex-col sm:flex-row  justify-around sm:py-20 sm:px-28">
          <div className="flex sm:w-3/4">
            <div>
              <p
                style={{ color: "#545454" }}
                className="font-bold text-left text-xl ml-4  sm:text-4xl pt-4"
              >
                ElectricWeb RFID Tag
              </p>
              <div className="sm:w-1/4 sm:hidden flex flex-col justify-center items-center border-0 py-5">
                <img
                  className="shadow-xl"
                  style={{ height: 120, borderRadius: 250 }}
                  src="../static/resource2.png"
                />
              </div>
              <p
                style={{ color: "#545454" }}
                className="ml-10 mt-1 text-left sm:px-0 sm:mt-10 font-bold"
              >
                1. Tap your RFID on the scanner and wait for a beep sound.
              </p>
              <p
                style={{ color: "#545454" }}
                className="ml-10 mt-2  text-left sm:px-0 font-bold"
              >
                2. Charging shall automatically start within 5-20 seconds.
              </p>
              <p
                style={{ color: "#545454" }}
                className="ml-10 mt-2  text-left sm:px-0 font-bold"
              >
                3. The charging session will automatically end after a 5 hour
                slot. For emergency stop, tap the RFID again on the scanner.
              </p>
              <p
                style={{ color: "#545454" }}
                className="ml-10 mt-2  text-left sm:px-0 font-bold"
              >
                4. For all transaction and charging session related information,
                please refer to our mobile application.
              </p>
            </div>
          </div>
          <div className="sm:w-1/4 hidden sm:flex flex-col justify-center items-center border-0 py-10 sm:py-0">
            <img
              className="shadow-xl"
              style={{ height: 320, borderRadius: 250 }}
              src="../static/resource2.png"
            />
          </div>
        </div>

        {/* Youtube Video */}
        <div className="hidden sm:block">
          <iframe
            className="video sm:absolute w-full sm:h-dvh h-96"
            title="Youtube"
            sandbox="allow-same-origin allow-forms  allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${"GHGXy_sjbgQ"}?autoplay=0`}
          ></iframe>
          <div className="hidden sm:block sm:h-dvh h-96"></div>
        </div>
        <div className="sm:hidden mt-14 flex justify-center">
          <iframe
            className="video sm:absolute  sm:h-dvh h-max"
            title="Youtube"
            sandbox="allow-same-origin allow-forms  allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${"GHGXy_sjbgQ"}?autoplay=0`}
          ></iframe>
          <div className="hidden sm:block sm:h-dvh h-96"></div>
        </div>

        {/* EV Charging Basics */}
        <div>
          <p className="font-bold text-3xl ml-4 text-center my-8 sm:my-10 sm:text-5xl pt-4">
            EV Charging Basics
          </p>

          {/*  */}
          <p
            style={{ color: "#545454" }}
            className="font-bold text-2xl ml-4 text-center my-8 sm:my-10 sm:text-4xl pt-4"
          >
            Types of electric vehicles
          </p>

          <div className="flex sm:flex-row flex-col justify-around sm:mx-20">
            <div className="sm:hidden flex justify-center sm:w-1/4">
              <img
                style={{ height: 220, width: "auto" }}
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/battery-evs.png"
              />
            </div>
            <div className="sm:w-2/4 ml-5 sm:ml-0 py-4 sm:py-10">
              <p className="text-xl sm:text-2xl" style={{ color: "#88AAC9" }}>
                Battery Electric Vehicles (BEVs)
              </p>
              <p className="" style={{ color: "#545454" }}>
                These vehicles run entirely on electric power stored in
                rechargeable batteries. They do not have an internal combustion
                engine, and their sole source of propulsion is electric motors.
                BEVs need to be plugged into an electric power source to
                recharge their batteries.
              </p>
            </div>
            <div className="hidden sm:block sm:w-1/4  justify-center items-center">
              <img
                className="h-52 sm:w-80"
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/battery-evs.png"
              />
            </div>
          </div>

          <div className="flex sm:flex-row flex-col pt-10 sm:py-20 justify-around sm:mx-20">
            <div className="sm:hidden flex justify-center sm:w-1/4">
              <img
                style={{ height: 220, width: "auto" }}
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/plugin-hybdid-evs.png"
              />
            </div>
            <div className="hidden sm:flex sm:w-1/4  justify-center items-center ">
              <img
                className="h-52 sm:w-80 "
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/plugin-hybdid-evs.png"
              />
            </div>
            <div className="sm:w-2/4 ml-5 sm:ml-0 py-4 sm:py-10">
              <p className="text-xl sm:text-2xl" style={{ color: "#88AAC9" }}>
                Plug-in Hybrid Electric Vehicles (PHEVs)
              </p>
              <p className="" style={{ color: "#545454" }}>
                PHEVs have both an electric motor and an internal combustion
                engine. They can operate using electric power alone for a
                limited range before switching to the internal combustion engine
                or can blend both power sources. PHEVs can be recharged by
                plugging into an electric power source or through regenerative
                braking.
              </p>
            </div>
          </div>

          <div className="flex sm:flex-row flex-col justify-around sm:mx-20">
            <div className="sm:hidden flex justify-center sm:w-1/4">
              <img
                style={{ height: 220, width: "auto" }}
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/hybrid-evs.png"
              />
            </div>
            <div className="sm:w-2/4 ml-5 sm:ml-0 py-4 sm:py-10">
              <p className="text-xl sm:text-2xl" style={{ color: "#88AAC9" }}>
                Hybrid Electric Vehicles (HEVs)
              </p>
              <p className="" style={{ color: "#545454" }}>
                HEVs combine an internal combustion engine with an electric
                motor and a small battery. Unlike PHEVs, they cannot be plugged
                in to recharge; instead, they rely on regenerative braking and
                the internal combustion engine to charge the battery. The
                electric motor assists the engine and can power the vehicle at
                low speeds.
              </p>
            </div>
            <div className="hidden sm:block sm:w-1/4  justify-center items-center">
              <img
                className="h-52 sm:w-80"
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/hybrid-evs.png"
              />
            </div>
          </div>

          <div className="flex sm:flex-row flex-col pt-10 sm:py-20 justify-around sm:mx-20">
            <div className="sm:hidden flex justify-center sm:w-1/4">
              <img
                style={{ height: 220, width: "auto" }}
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/fuelevs.png"
              />
            </div>
            <div className="hidden sm:flex sm:w-1/4  justify-center items-center ">
              <img
                className="h-52 sm:w-80 "
                src="https://e-amrit.niti.gov.in/assets/admin/dist/img/new-fronend-img/fuelevs.png"
              />
            </div>
            <div className="sm:w-2/4 ml-5 sm:ml-0 py-4 sm:py-10">
              <p className="text-xl sm:text-2xl" style={{ color: "#88AAC9" }}>
                Fuel Cell Electric Vehicles (FCEVs)
              </p>
              <p className="" style={{ color: "#545454" }}>
                FCEVs use a fuel cell stack to generate electricity by combining
                hydrogen fuel with oxygen from the air, producing water vapor as
                the only emission. The electricity generated powers the electric
                motor, propelling the vehicle. FCEVs do not need to be plugged
                in and have longer range capabilities compared to battery
                electric vehicles. However, the infrastructure for hydrogen
                refueling is currently limited.
              </p>
            </div>
          </div>

          {/* Types of Electric Cars  Chargers */}
          <p
            style={{ color: "#545454" }}
            className="font-bold text-2xl ml-4  text-center my-8 sm:my-10 sm:text-4xl pt-4"
          >
            Types of electric car chargers
          </p>

          <div className="flex sm:flex-row flex-col justify-around sm:mx-20">
            <div className="sm:hidden flex justify-center sm:w-1/4">
              <img
                style={{ height: 220, width: "auto" }}
                src="../static/28.png"
              />
            </div>
            <div className="sm:w-2/4 ml-5 sm:ml-0 py-4 sm:py-10">
              <p className="text-xl sm:text-2xl" style={{ color: "#88AAC9" }}>
                AC Fast Chargers (Type 2)
              </p>
              <p className="" style={{ color: "#545454" }}>
                AC fast chargers (Level 2 chargers) offer higher charging speeds
                compared to slow chargers, typically ranging from 7.4 kW to 22
                kW and come with tethered charging cable & gun. These chargers
                are commonly found in residential and commercial locations such
                as group housing societies, shopping malls, hotels, and office
                complexes, as well as along highways and major travel routes. AC
                fast chargers require a Type 2 connector and are suitable for
                shorter charging sessions, providing faster replenishment of the
                vehicle's battery without any adverse effect to the EV battery.
              </p>
            </div>
            <div className="hidden sm:flex sm:w-1/4  justify-center items-center">
              <img className="h-60 " src="../static/28.png" />
            </div>
          </div>

          <div className="flex sm:flex-row flex-col pt-10 sm:py-20 justify-around sm:mx-20">
            <div className="sm:hidden flex justify-center sm:w-1/4">
              <img
                style={{ height: 220, width: "auto" }}
                src="../static/9.png"
              />
            </div>
            <div className="hidden sm:flex sm:w-1/4  justify-center items-center ">
              <img className="h-52 sm:w-80 " src="../static/9.png" />
            </div>
            <div className="sm:w-2/4 ml-5 sm:ml-0 py-4 sm:py-10">
              <p className="text-xl sm:text-2xl" style={{ color: "#88AAC9" }}>
                DC Fast Chargers (CCS Combo)
              </p>
              <p className="" style={{ color: "#545454" }}>
                DC fast chargers provide rapid charging speeds by delivering
                direct current (DC) power to the electric vehicle's battery. In
                India, DC fast chargers commonly use either the CCS (Combined
                Charging System) Combo . CCS Combo connectors are compatible
                with all electric vehicles, including models from Tata Motors,
                MG Motor, and Hyundai. DC fast chargers can deliver charging
                speeds ranging from 25 kW to 50 kW or higher, enabling quick
                charging stops during long-distance travel.
              </p>
            </div>
          </div>
        </div>

        {/* Common Places for charging PC Screen */}
        <div className="sm:w-full pb-20 mt-7 hidden sm:block ">
          <p
            style={{ color: "#545454" }}
            className="font-bold text-xl ml-4  text-center  sm:my-10 sm:text-4xl pt-6"
          >
            Common places where you can charge your electric vehicle
          </p>

          <div className="flex justify-center  flex-wrap py-5">
            {/*  */}
            <div
              style={{ width: 350, borderRadius: 10, overflow: "hidden" }}
              className=" shadow-xl  sm:mx-7 my-3"
            >
              <img
                style={{ height: 200, width: "100%", borderRadius: 10 }}
                src="../static/ri6.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-3">Home Charging</p>
                <p style={{ color: "#545454" }} className="text-center">
                  Most electric vehicle owners in India primarily charge their
                  vehicles at home. Installing a charging station at home allows
                  for convenient overnight charging, ensuring that the vehicle
                  is ready for daily use. Home charging stations can be
                  installed in residential garages or parking spaces and are
                  typically connected to the household electricity supply.
                </p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 350, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 200, width: "100%", borderRadius: 10 }}
                src="../static/ri7.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-3">Workplace Charging</p>
                <p style={{ color: "#545454" }} className="text-center">
                  Many employers are installing electric vehicle charging
                  stations at workplaces to encourage employees to switch to
                  electric vehicles. Workplace charging allows employees to
                  charge their vehicles during the workday, providing added
                  convenience and peace of mind. Employers may offer free or
                  subsidized charging as part of their employee benefits
                  program.
                </p>
              </div>
            </div>

            {/*  */}
            <div
              style={{ width: 350, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 200, width: "100%", borderRadius: 10 }}
                src="../static/ri8.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  Public Charging Stations
                </p>
                <p style={{ color: "#545454" }} className="text-center ">
                  Public charging stations are increasingly becoming available
                  in urban areas, including shopping malls, supermarkets,
                  parking lots, and commercial complexes. These charging
                  stations are accessible to the general public and provide
                  convenient charging options for electric vehicle owners while
                  they run errands, shop, or dine out. Public charging stations
                  may offer different charging speeds and may require payment or
                  membership for access.
                </p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 350, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 200, width: "100%", borderRadius: 10 }}
                src="../static/ri10.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  Highway Charging Stations
                </p>
                <p style={{ color: "#545454" }} className="text-center">
                  Charging infrastructure along highways and major travel routes
                  is essential for enabling long-distance travel with electric
                  vehicles. Highway charging stations are strategically located
                  at rest areas, service plazas, and fuel stations, allowing
                  electric vehicle drivers to recharge their vehicles during
                  long journeys. These stations often offer DC fast charging
                  capabilities to minimize charging times during travel.
                </p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 350, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 200, width: "100%", borderRadius: 10 }}
                src="../static/ri9.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  Destination Stations
                </p>
                <p style={{ color: "#545454" }} className="text-center">
                  Destination charging refers to charging stations installed at
                  hotels, resorts, tourist attractions, and other destination
                  points. These charging stations cater to electric vehicle
                  owners visiting these locations, providing a convenient way to
                  recharge while staying or exploring nearby. Destination
                  charging helps support tourism and encourages electric vehicle
                  adoption among travelers.
                </p>
              </div>
            </div>

            <div
              style={{ width: 350, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 200, width: "100%", borderRadius: 10 }}
                src="../static/ri11.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  EV Dealerships and Service Centers
                </p>
                <p style={{ color: "#545454" }} className="text-center ">
                  EV Dealerships and Service Centers: Electric vehicle
                  dealerships and service centers may also offer charging
                  facilities for customers. Customers can charge their vehicles
                  while visiting the dealership for sales inquiries, test
                  drives, or servicing appointments. Some dealerships may even
                  provide complimentary charging as part of the customer
                  experience.
                </p>
              </div>
            </div>
            {/*  */}
          </div>
        </div>

        {/* Common Places for charging Mobile Screen */}
        <div className="sm:w-full mt-7  block sm:hidden">
          <p
            style={{ color: "#545454" }}
            className="font-bold  text-center  sm:my-10 text-3xl pt-6"
          >
            Common places where you can charge your electric vehicle
          </p>

          <div className="flex justify-center flex-wrap py-5">
            {/*  */}
            <div
              style={{ width: 300, borderRadius: 10, overflow: "hidden" }}
              className=" shadow-xl  sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/ri6.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-3">Home Charging</p>
                <p style={{ color: "#545454" }} className="text-center">
                  Most electric vehicle owners in India primarily charge their
                  vehicles at home. Installing a charging station at home allows
                  for convenient overnight charging, ensuring that the vehicle
                  is ready for daily use. Home charging stations can be
                  installed in residential garages or parking spaces and are
                  typically connected to the household electricity supply.
                </p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 300, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/ri7.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-3">Workplace Charging</p>
                <p style={{ color: "#545454" }} className="text-center">
                  Many employers are installing electric vehicle charging
                  stations at workplaces to encourage employees to switch to
                  electric vehicles. Workplace charging allows employees to
                  charge their vehicles during the workday, providing added
                  convenience and peace of mind. Employers may offer free or
                  subsidized charging as part of their employee benefits
                  program.
                </p>
              </div>
            </div>

            {/*  */}
            <div
              style={{ width: 300, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/ri8.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  Public Charging Stations
                </p>
                <p style={{ color: "#545454" }} className="text-center ">
                  Public charging stations are increasingly becoming available
                  in urban areas, including shopping malls, supermarkets,
                  parking lots, and commercial complexes. These charging
                  stations are accessible to the general public and provide
                  convenient charging options for electric vehicle owners while
                  they run errands, shop, or dine out. Public charging stations
                  may offer different charging speeds and may require payment or
                  membership for access.
                </p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 300, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/ri10.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  Highway Charging Stations
                </p>
                <p style={{ color: "#545454" }} className="text-center">
                  Charging infrastructure along highways and major travel routes
                  is essential for enabling long-distance travel with electric
                  vehicles. Highway charging stations are strategically located
                  at rest areas, service plazas, and fuel stations, allowing
                  electric vehicle drivers to recharge their vehicles during
                  long journeys. These stations often offer DC fast charging
                  capabilities to minimize charging times during travel.
                </p>
              </div>
            </div>
            {/*  */}
            <div
              style={{ width: 300, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/ri9.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  Destination Stations
                </p>
                <p style={{ color: "#545454" }} className="text-center">
                  Destination charging refers to charging stations installed at
                  hotels, resorts, tourist attractions, and other destination
                  points. These charging stations cater to electric vehicle
                  owners visiting these locations, providing a convenient way to
                  recharge while staying or exploring nearby. Destination
                  charging helps support tourism and encourages electric vehicle
                  adoption among travelers.
                </p>
              </div>
            </div>

            <div
              style={{ width: 300, borderRadius: 10, overflow: "hidden" }}
              className="border shadow-xl sm:mx-7 my-3"
            >
              <img
                style={{ height: 180, width: "100%", borderRadius: 10 }}
                src="../static/ri11.jpeg"
              />
              <div className="py-10">
                <p className="text-center font-bold mb-2">
                  EV Dealerships and Service Centers
                </p>
                <p style={{ color: "#545454" }} className="text-center ">
                  EV Dealerships and Service Centers: Electric vehicle
                  dealerships and service centers may also offer charging
                  facilities for customers. Customers can charge their vehicles
                  while visiting the dealership for sales inquiries, test
                  drives, or servicing appointments. Some dealerships may even
                  provide complimentary charging as part of the customer
                  experience.
                </p>
              </div>
            </div>
            {/*  */}
          </div>
        </div>

        {/* FAQ's */}
        <div id="FAQs" className="sm:mx-20 py-10 sm:py-20">
          <p className="mx-5 text-3xl">FAQs</p>

          {/*  */}
          <div className="mt-5 sm:mt-10 w-full px-2 sm:px-0">
            {faqs &&
              faqs?.map((faq) => {
                return (
                  <div id="wrapper" className="my-5">
                    <div
                      id="button"
                      className="text-left border-2 border-gray-500 hover:border-2 hover:border-lime-500 rounded-xl shadow-2xl cursor-pointer font-serif pb-5 px-6"
                    >
                      <p className="sm:text-xl p-2 rounded-md">
                        {faq?.question}
                      </p>

                      <div
                        id="dropMobileFAQ"
                        style={{ borderRadius: 5, overflow: "hidden" }}
                        className="bg-white p-2 w-full shadodw-2xl pt-1"
                      >
                        {faq?.answer?.split("\n")?.map((text) => {
                          return (
                            <p
                              style={{ color: "#15AE81" }}
                              className="text-sm sm:text-lg"
                            >
                              {text}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResourcesScreen;
