import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { DatePicker, Table } from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const TransactionDetailsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const [transactions, setTransactions] = useState();
  const [selectedMobile, setSelectedMobile] = useState();
  const [dateRange, setDateRange] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [formatedTransactions, setFormatedTransactions] = useState();
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
        getTransactions();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    formatTransactionsData(transactions);
  }, [transactions, users]);

  const getTransactions = async () => {
    setLoading(true);
    try {
      if (selectedMobile && selectedTransactionType) {
        const res = await axios.post(`${BaseUrl}/get/transactions/admin`, {
          token: localStorage.getItem("token"),
          phone: selectedMobile,
          transactionType: selectedTransactionType,
        });
        if (res?.data?.success) {
          setTransactions(res?.data?.transactions);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else if (selectedMobile) {
        const res = await axios.post(`${BaseUrl}/get/transactions/admin`, {
          token: localStorage.getItem("token"),
          phone: selectedMobile,
        });
        if (res?.data?.success) {
          setTransactions(res?.data?.transactions);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else if (selectedTransactionType) {
        const res = await axios.post(`${BaseUrl}/get/transactions/admin`, {
          token: localStorage.getItem("token"),
          transactionType: selectedTransactionType,
        });
        if (res?.data?.success) {
          setTransactions(res?.data?.transactions);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        const res = await axios.post(`${BaseUrl}/get/transactions/admin`, {
          token: localStorage.getItem("token"),
        });
        if (res?.data?.success) {
          setTransactions(res?.data?.transactions);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const formatTransactionsData = async (Transactions) => {
    setLoading(true);
    try {
      const array = Transactions;
      await array?.map((transaction) => {
        transaction.time =
          transaction?.time?.split(" ")[1] +
          "/" +
          transaction?.time?.split(" ")[2] +
          "/" +
          transaction?.time?.split(" ")[3] +
          " " +
          convertTo12HourFormat(transaction?.time?.split(" ")[4]);

        // Created At for Filter on Date
        transaction.date =
          transaction?.createdAt?.split("T")[0].split("-").join("") +
          transaction?.createdAt?.split("T")[1].split(":")[0] +
          transaction?.createdAt?.split("T")[1].split(":")[1];

        // Booking Id
        if (transaction?.bookingId) {
          if (
            transaction?.bookingId === "0001" ||
            transaction?.bookingId === "0002"
          ) {
            transaction.paymentId = `${transaction?.transactionId} / ${transaction?.bookingId}`;
          } else {
            transaction.paymentId = transaction?.bookingId;
          }
        } else {
          if (transaction?.paymentId === "NA") {
            transaction.paymentId = transaction?.rechargeStatus;
          }
        }

        // Refund Credits
        if (transaction?.refundAmount) {
          transaction.refundAmount =
            transaction?.refundAmount?.split("₹")[0] +
            transaction?.refundAmount?.split("₹")[1];
        }

        // User Name for showing
        const details =
          users && users?.find((user) => user?._id === transaction?.user);
        transaction.userName = details?.name;
        transaction.mobile = details?.phone;
        if (!details) {
          const details =
            users && users?.find((user) => user?.phone === transaction?.phone);
          transaction.userName = details?.name;
          transaction.mobile = details?.phone;
        }

        // Created At
        // transaction.createdAt = transaction?.createdAt?.split("+")[0];
      });
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      return setFormatedTransactions(array);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function onChange(date, dateString) {
    setDateRange(dateString);
  }

  const filterData = async () => {
    setLoading(true);

    if (selectedMobile && dateRange) {
      // If All Filters are selected
      setfilterSet(true);
      const array = unchangedFormatedTransactions;
      const resultArray = [];
      array?.map((transaction) => {
        let createdAt = transaction?.createdAt
          ?.split("T")[0]
          .split("-")
          .join("");
        let from = dateRange[0].split("-").join("");
        let to = dateRange[1].split("-").join("");
        if (
          createdAt >= from &&
          createdAt <= to &&
          transaction?.mobile === selectedMobile
        ) {
          resultArray.push(transaction);
        }
      });
      setFormatedTransactions(resultArray);
      setLoading(false);
    } else if (selectedMobile) {
      setfilterSet(true);
      // If only Mobile number is Filtered
      const array = unchangedFormatedTransactions;
      const resultArray = [];
      array?.map((transaction) => {
        if (transaction?.mobile === selectedMobile) {
          resultArray.push(transaction);
        }
      });
      setFormatedTransactions(resultArray);
      setLoading(false);
    } else if (dateRange) {
      // If only date is Filtered
      setfilterSet(true);
      const array = unchangedFormatedTransactions;
      const resultArray = [];
      array?.map((transaction) => {
        let createdAt = transaction?.createdAt
          ?.split("T")[0]
          .split("-")
          .join("");
        let from = dateRange[0].split("-").join("");
        let to = dateRange[1].split("-").join("");
        if (createdAt >= from && createdAt <= to) {
          resultArray.push(transaction);
        }
      });
      setFormatedTransactions(resultArray);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "time",
      // fixed:'left',
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date - b.date,
      width:120
    },
    {
      title: "User Name",
      fixed:'left',
      dataIndex: `userName`,
      width:120

    },
    {
      title: "Mobile No.",
      dataIndex: `mobile`,
      width:120

    },
    {
      title: "Payment / Booking Id",
      dataIndex: `paymentId`,
      width:100

    },
    {
      title: "Blocked Credits",
      dataIndex: `blockedAmount`,
      width:100

    },
    {
      title: "Refund Credits",
      dataIndex: `refundAmount`,
      width:100

    },
    {
      title: "Paid Credits",
      dataIndex: `paid`,
      width:100

    },
    {
      title: "Wallet Amount",
      dataIndex: `prevWalletAmount`,
      width:100

    },
    {
      title: "Recharge Amount",
      dataIndex: `rechargeAmount`,
      sorter: (a, b) => a.rechargeAmount - b.rechargeAmount,
      width:100

    },
    {
      title: "Updated Wallet Amount",
      dataIndex: `walletAmount`,
      width:100

    },
  ];

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Transactions
        </p>
      </div>

      {/* Filter Data */}
      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        {/* <div className="flex">
          <p style={{ marginTop: 2 }}>Date Range</p>
          <RangePicker
            className="ml-4"
            style={{ width: 250 }}
            onChange={onChange}
          />
        </div> */}
        {/* Mobile Number Filter */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>User Mobile No.</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 300 }}
            placeholder="Select mobile no."
            optionFilterProp="children"
            defaultValue={selectedMobile}
            onChange={(value) => {
              setSelectedMobile(value);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <option style={{ fontSize: 12 }} value={undefined}>
              All Users
            </option>
            {users?.map((user) => {
              return (
                <option style={{ fontSize: 12 }} value={user?.phone}>
                  {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                </option>
              );
            })}
          </Select>
        </div>

        <div className="flex">
          <p style={{ marginTop: 2 }}>Transaction type</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="All"
            optionFilterProp="children"
            defaultValue={selectedTransactionType}
            onChange={(value) => {
              setSelectedTransactionType(value);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <option style={{ fontSize: 12 }} value={undefined}>
              All
            </option>
            <option style={{ fontSize: 12 }} value={"Charging"}>
              Credits spend
            </option>
            <option style={{ fontSize: 12 }} value={"recharge"}>
              Wallet Topup
            </option>
          </Select>
        </div>

        <div className="flex justify-center">
          <a
            onClick={() => getTransactions()}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getTransactions();
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getTransactions();
              setfilterSet(false);
              setSelectedMobile(undefined);
              setDateRange(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Showing Chargers */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedTransactions}
          scroll={{
            y: 600,
          }}
        />
      </div>
    </div>
  );
};

export default TransactionDetailsScreen;
