import React, { useState, useEffect } from "react";
import { DatePicker, Table } from "antd";
import { Select } from "antd";
import axios from "axios";
import LoadingComponent from "../../Components/LoadingComponent";
import { BaseUrl } from "../../static/BaseUrl";
import exportFromJSON from "export-from-json";

const Option = Select.Option;
const MonthlyElectricityBillingScreen = () => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();

  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [chargersNames, setChargersNames] = useState();
  const [selectedChargerName, setSelectedChargername] = useState();
  const [selectedMobile, setSelectedmobile] = useState();
  const [totalUnitsConsumed, setTotalUnitsConsumed] = useState(0);

  const [formatedBookings, setformatedBookings] = useState([]);
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [filterSet, setfilterSet] = useState(false);

  const downloadData = async (data) => {
    const fileName = `MonthlyElectricityBilling${
      selectedChargerName ? "_" + selectedChargerName : ""
    }${selectedMonth ? "_" + selectedMonth : ""}${
      selectedYear ? "_" + selectedYear : ""
    }`;

    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  const onMonthChange = async (date) => {
    try {
      const dateString = date?.toString();
      let month = dateString?.split(" ")[2].trim();
      let year = dateString?.split(" ")[3].trim();

      setSelectedMonth(month);
      setSelectedYear(year);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompletedBookings = async () => {
    try {
      setLoading(true);
      if (!selectedMonth || !selectedYear) {
        // Create a new Date object
        var currentDate = new Date().toString();
        let month = currentDate?.split(" ")[1].trim();
        let year = currentDate?.split(" ")[3].trim();
        // console.log(month, year);
        const res = await axios.post(`${BaseUrl}/get/monthly/bookings/specific/charger`, {
          token: localStorage.getItem("token"),
          month,
          year,
        });
        // console.log(res.data);
        if (res?.data?.success) {
          setBookings(res?.data?.bookings);
          setChargersNames(res?.data?.chargers);
          setPhoneNumbers(res?.data?.phoneNumbers);
        }
      } else {
        const res = await axios.post(`${BaseUrl}/get/monthly/bookings/specific/charger`, {
          token: localStorage.getItem("token"),
          month: selectedMonth,
          year: selectedYear,
        });
        if (res?.data?.success) {
          setBookings(res?.data?.bookings);
          setChargersNames(res?.data?.chargersName);
          setPhoneNumbers(res?.data?.phoneNumbers);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const [totalBilling , setTotalBilling] = useState(0);
  const formatBookingData = (bookings) => {
    setLoading(true);

    try {
      let array = [];
      var TUC = 0;
      var TotalBill = 0 ;
      bookings?.map((booking) => {
        if (booking?.startTime && booking?.UnitsConsumed != 0) {
          booking.startTime =
            booking.startTime?.split(" ")[1] +
            "/" +
            booking.startTime?.split(" ")[2] +
            "/" +
            booking.startTime?.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking?.startTime?.split(" ")[4]);

          booking.stopTime =
            booking.stopTime?.split(" ")[1] +
            "/" +
            booking.stopTime?.split(" ")[2] +
            "/" +
            booking.stopTime?.split(" ")[3] +
            " " +
            convertTo12HourFormat(booking?.stopTime?.split(" ")[4]);

          let data = {
            BookingId: booking?.transactionId,
            StartTime: booking?.startTime,
            StopTime: booking?.stopTime,
            Name: booking?.userName,
            Mobile: booking?.phone,
            ChargerName: booking?.chargerName,
            // MeterStart: booking?.meterStart,
            // MeterStop: booking?.meterStop,
            UnitsConsumed: booking?.UnitsConsumed,
            ElectricityTarrif: booking?.electricityChargePerkWh,
            ElectricityAmountPaid: (
              booking?.electricityChargePerkWh * booking?.UnitsConsumed
            ).toFixed(2),
          };

          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
          }
          if (booking?.electricityChargePerkWh) {
            TotalBill = TotalBill +( Number(booking?.electricityChargePerkWh) *  Number(booking?.UnitsConsumed))
          }

          array.push(data);
        }
        return null;
      });
      setTotalUnitsConsumed(TUC.toFixed(2));
      setTotalBilling(TotalBill.toFixed(2)) ;
      setLoading(false);
      setUnchangedFormatedTransactions(array);
      // console.log(totalUnitsConsumed);
      return setformatedBookings(array);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const filterData = async () => {
    try {
      setLoading(true);
      var TUC = 0;
      if (selectedChargerName && selectedMobile) {
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultArray = [];
        array?.map((booking) => {
          if (
            booking?.ChargerName === selectedChargerName &&
            booking?.Mobile === selectedMobile
          ) {
            resultArray.push(booking);
          }
          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
          }
        });
        setformatedBookings(resultArray);
        setLoading(false);
      } else if (selectedChargerName) {
        // If only charger is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.ChargerName === selectedChargerName) {
            resultedArray.push(booking);
          }
          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
          }
        });
        setformatedBookings(resultedArray);
        setLoading(false);
      } else if (selectedMobile) {
        // If Only Mobile Number is selected
        setfilterSet(true);
        const array = unchangedFormatedTransactions;
        const resultedArray = [];
        array?.map((booking) => {
          if (booking?.Mobile === selectedMobile) {
            resultedArray.push(booking);
          }
          if (booking?.UnitsConsumed) {
            TUC = TUC + booking?.UnitsConsumed;
          }
        });
        setformatedBookings(resultedArray);
        setTotalUnitsConsumed(TUC.toFixed(2));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompletedBookings();
  }, []);

  useEffect(() => {
    formatBookingData(bookings);
  }, [bookings]);

  const columns = [
    {
      title: "BookingId",
      dataIndex: "BookingId",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Mobile No.",
      dataIndex: "Mobile",
    },
    {
      title: "Start Time",
      dataIndex: `StartTime`,
    },
    {
      title: "End Time",
      dataIndex: "StopTime",
    },
    {
      title: "Charger Name",
      dataIndex: "ChargerName",
    },
    // {
    //   title: "Meter Start",
    //   dataIndex: `MeterStart`,
    // },
    // {
    //   title: "Meter Stop",
    //   dataIndex: "MeterStop",
    // },
    {
      title: "Units Consumed",
      dataIndex: "UnitsConsumed",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.UnitsConsumed - b.UnitsConsumed,
    },
    {
      title: "Electricity Tarrif",
      dataIndex: "ElectricityTarrif",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.ElectricityTarrif - b.ElectricityTarrif,
    },
    {
      title: "Electricity Amount Paid",
      dataIndex: "ElectricityAmountPaid",
    },
  ];

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Monthly Electricity Billing
        </p>
      </div>

      {/* Filter Data */}

      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 5 }}>Select Month</p>
          <DatePicker
            className="ml-4"
            style={{ width: 250 }}
            placeholder="Select month"
            onChange={onMonthChange}
            picker="month"
          />
          <a
          onClick={() => {
            getCompletedBookings()
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 ml-2 border rounded-md bg-slate-500 text-white"
        >
          Get Data
        </a>
        </div>
        <a
          onClick={() => {
            downloadData(formatedBookings);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Download Data
        </a>
      </div>

      {/* <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
       
        <div className="flex">
          <p style={{ marginTop: 2 }}>Charger name</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select charger"
            optionFilterProp="children"
            onChange={(value) => setSelectedChargername(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {chargersNames &&
              chargersNames?.map((charger) => {
                return (
                  <Option value={charger?.chargerName}>{charger?.chargerName.toString()}</Option>
                );
              })}
          </Select>
        </div>
      </div> */}

      <div className="flex justify-between mt-3">
        {filterSet ? (
          <a
            onClick={() => {
              getCompletedBookings();
              setfilterSet(false);
              setSelectedChargername(undefined);
              setfilterSet(false);
              setSelectedmobile(undefined);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>
      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto">
        <Table columns={columns} dataSource={formatedBookings} />
      </div>
      <div>
        <p>Total Units Consumed : {totalUnitsConsumed} kWh</p>
        <p>Total Bill : &#8377; {totalBilling}</p>

      </div>
    </div>
  );
  //   <DatePicker placeholder="Current month" onChange={onMonthChange} picker="month" />;
};

export default MonthlyElectricityBillingScreen;
