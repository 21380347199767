import React, { useState, useEffect } from "react";
import { DatePicker, Table } from "antd";
import { Select } from "antd";
import axios from "axios";
import LoadingComponent from "../../Components/LoadingComponent";
import { BaseUrl } from "../../static/BaseUrl";
import exportFromJSON from "export-from-json";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

const Option = Select.Option;
const RWAPaymentsScreenForRwaAdmin = () => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();

  const [makePaymentModalOpen, setMakePaymentModalOpen] = useState(false);
  const [seletedLocation, setSelectedLocation] = useState();

  const [statusFilter, setStatusFilter] = useState();
  const [loading, setLoading] = useState(true);

  const [formatedData, setFormatedData] = useState([]);

  const [filterSet, setfilterSet] = useState(false);

  const onMonthChange = async (date) => {
    try {
      const dateString = date?.toString();
      let month = dateString?.split(" ")[2].trim();
      let year = dateString?.split(" ")[3].trim();

      setSelectedMonth(month);
      setSelectedYear(year);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentData = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BaseUrl}/get/monthly/rwa/admin/payments/data`,
        {
          token: localStorage.getItem("token"),
        }
      );
      if (res?.data?.success) {
        console.log(res?.data);

        formatBookingData(res?.data?.details);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentData();
  }, []);

  function convertTo12HourFormat(timeString) {
    const formattedTime = `${timeString?.split(" ")[0]} ${
      timeString?.split(" ")[1]
    } ${timeString?.split(" ")[2]} ${timeString?.split(" ")[3]}`;
    return formattedTime;
  }

  const [unchangedData, setUnchangedData] = useState();

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const downloadData = async (
    selectedMonth,
    selectedYear,
    type,
    billingCycle
  ) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BaseUrl}/get/monthly/bookings/specific/charger`,
        {
          token: localStorage.getItem("token"),
          month: selectedMonth,
          year: selectedYear,
        }
      );
      if (res?.data?.success) {
        const bookings = res?.data?.bookings;

        const array = [];
        var TUC = 0;
        var TotalBill = 0;
        res?.data?.bookings?.map((booking) => {
          if (booking?.startTime && booking?.UnitsConsumed != 0) {
            booking.startTime =
              booking.startTime?.split(" ")[1] +
              "/" +
              booking.startTime?.split(" ")[2] +
              "/" +
              booking.startTime?.split(" ")[3] +
              " " +
              convertTo12HourFormat(booking?.startTime?.split(" ")[4]);

            booking.stopTime =
              booking.stopTime?.split(" ")[1] +
              "/" +
              booking.stopTime?.split(" ")[2] +
              "/" +
              booking.stopTime?.split(" ")[3] +
              " " +
              convertTo12HourFormat(booking?.stopTime?.split(" ")[4]);

            let data = {
              BookingId: booking?.transactionId,
              StartTime: booking?.startTime,
              StopTime: booking?.stopTime,
              Name: booking?.userName,
              Mobile: booking?.phone,
              ChargerName: booking?.chargerName,
              // MeterStart: booking?.meterStart,
              // MeterStop: booking?.meterStop,
              UnitsConsumed: Number(booking?.UnitsConsumed)?.toFixed(2),
              ElectricityTarrif: Number(
                booking?.electricityChargePerkWh
              ).toFixed(2),
              ElectricityAmountPaid: (
                Number(booking?.electricityChargePerkWh) *
                Number(booking?.UnitsConsumed)
              ).toFixed(2),
            };

            if (booking?.UnitsConsumed) {
              TUC = TUC + booking?.UnitsConsumed;
            }
            if (booking?.electricityChargePerkWh) {
              TotalBill =
                TotalBill +
                Number(booking?.electricityChargePerkWh) *
                  Number(booking?.UnitsConsumed);
            }

            array.push(data);
          }
        });
        console.log(array);

        if (type === "Excel") {
          return downloadDataAsExcelFile(array, selectedMonth, selectedYear);
        } else {
          downloadPDF(
            array,
            selectedMonth,
            selectedYear,
            TUC,
            TotalBill,
            billingCycle
          );
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const downloadDataAsExcelFile = async (data, selectedMonth, selectedYear) => {
    const fileName = `MonthlyElectricityBilling_${
      selectedMonth ? "_" + selectedMonth : ""
    }${selectedYear ? "_" + selectedYear : ""}`;

    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  function downloadPDF(
    data,
    selectedMonth,
    selectedYear,
    TotalUnitsConsumed,
    BillAmount,
    billingCycle
  ) {
    const fileName = `MonthlyElectricityBilling_${selectedMonth}_${selectedYear}.pdf`;

    // Create an HTML element to capture
    const htmlElement = document.createElement("div");
    htmlElement.innerHTML = `
      <div style="text-align: left; background-color: rgb(0,22,40); color: white; padding: 10px;">
        <p style="font-size: 30px;">
          ElectricWeb Monthly Electricity Billing ( ${selectedMonth} ${selectedYear} )
        </p>

        <div style="padding-top : 30px; text-align: left;">
          <p>Location : ${
            JSON.parse(localStorage.getItem("location"))?.address
          }</p>
          <p>Billing Cycle : ${billingCycle}</p>
          <p>Total Units Sold : ${TotalUnitsConsumed?.toFixed(2)} kWh</p>
          <p>Bill Amount : ₹ ${BillAmount?.toFixed(2)}</p>
          <p style=" font-size:6px ; text-align:right ; position:relative; top:1 ; right:20;">
            ${new Date()}
          </p>
        </div>
      </div>
    `;

    // Append the HTML element to the document body
    document.body.appendChild(htmlElement);

    // Use html2canvas to capture the HTML element
    html2canvas(htmlElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // const imgHeight = (canvas.height * 190) / canvas.width; // Calculate image height based on aspect ratio

      // Create a new jsPDF instance
      const doc = new jsPDF();

      // Add the captured HTML as an image to the PDF
      doc.addImage(imgData, "PNG", 10, 10, 190, 25); // Adjust the dimensions as needed

      // Remove the HTML element from the document
      document.body.removeChild(htmlElement);

      const columns = [
        { header: "BookingId", dataKey: "BookingId" },
        { header: "Name", dataKey: "Name" },
        { header: "Mobile", dataKey: "Mobile" },
        { header: "Charger", dataKey: "ChargerName" },
        { header: "Start Time", dataKey: "StartTime" },
        { header: "Stop Time", dataKey: "StopTime" },
        { header: "Units (kWh)", dataKey: "UnitsConsumed" },
        { header: "Tarrif (Rs)", dataKey: "ElectricityTarrif" },
        { header: "Bill Amount", dataKey: "ElectricityAmountPaid" },
      ];

      // Add table
      doc.autoTable({
        columns: columns,
        body: data,
        startY: 35, // Start table below the title
        margin: { horizontal: 10 },
        theme: "striped",
        headStyles: {
          fillColor: [41, 128, 185],
          textColor: [255, 255, 255],
          halign: "center",
        },
        styles: {
          cellPadding: 3,
          fontSize: 8,
          halign: "center",
          valign: "middle",
        },
      });

      doc.save(fileName);
    });
  }

  function maskAccountNumber(accountNumber) {
    // Convert accountNumber to string (if it's not already)
    let strAccountNumber = String(accountNumber);

    // Check if the account number has at least 4 digits
    if (strAccountNumber.length >= 4) {
      // Extract the last 4 characters
      let lastFourDigits = strAccountNumber.slice(-4);
      // Mask the rest of the digits with 'X'
      let maskedString =
        "x".repeat(strAccountNumber.length - 4) + lastFourDigits;
      return maskedString;
    } else {
      // Handle case where account number is less than 4 digits (if necessary)
      return strAccountNumber; // Or handle as per your requirements
    }
  }

  const formatBookingData = (data) => {
    setLoading(true);
    try {
      let resultedData = [];
      data?.map((data) => {
        data.unitsSold = Number(data.unitsSold).toFixed(2);
        data.billAmount = Number(data.billAmount).toFixed(2);
        data.billingCycle = `${data?.billingCycle} ${data?.month?.slice(3)}`;

        if (data?.status === "Paid") {
          data.time =
            data?.time?.split(" ")[0] +
            " " +
            data?.time?.split(" ")[1] +
            " " +
            data?.time?.split(" ")[2] +
            " " +
            data?.time?.split(" ")[3];
          let accountNumber = maskAccountNumber(
            data?.accountDetails?.accountNumber
          );
          data.accountNumber = accountNumber;
          resultedData.push(data);
        } else {
          data.paymentId = "-";
          data.accountNumber = "-";
          data.time = "-";
          resultedData.push(data);
        }
      });
      setUnchangedData(resultedData);
      setFormatedData(resultedData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterData = async () => {
    setLoading(true);
    try {
      if (statusFilter === "All" && !seletedLocation) {
        setLoading(false);
        return setFormatedData(unchangedData);
      }
      const resultedData = [];
      unchangedData?.map((data) => {
        if (data?.status === statusFilter) {
          resultedData?.push(data);
        }
      });
      setFormatedData(resultedData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    // {
    //   title: "Location",
    //   dataIndex: "locationName",
    //   width: 350,
    // },
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      // width: 120,
      // fixed: "left",
    },
    {
      title: "Total Units Sold (kWh)",
      dataIndex: "unitsSold",
      // width: 120,
    },
    {
      title: " Electricity Charge (Rs)",
      dataIndex: "billAmount",
      // width: 120,
    },
    // {
    //   title: "Payment Status",
    //   dataIndex: `status`,
    //   width: 120,
    // },
    // {
    //   title: "Account No.",
    //   dataIndex: "accountNumber",
    //   width: 120,
    // },
    {
      title: "Date of Payment",
      dataIndex: "time",
      // width: 180,
    },
    {
      title: "UTR No.",
      dataIndex: "paymentId",
      width: 180,
    },

    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <a
          className="ml-5"
          onClick={() => {
            downloadData(
              record?.billingCycle?.split(" ")[3],
              record?.billingCycle?.split(" ")[4],
              "Pdf",
              record?.billingCycle
            );
          }}
        >
          <i
            style={{ color: "blue" }}
            title="Download Data"
            class="fa-regular fa-file-pdf"
          ></i>
        </a>
      ),
    },
  ];

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          RWA Payments Details
        </p>
      </div>

      {/* Filter Data */}

      {/* <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 5 }}>Select Month</p>
          <DatePicker
            className="ml-4"
            style={{ width: 250 }}
            placeholder="Select month"
            onChange={onMonthChange}
            picker="month"
          />
          <a
            onClick={() => {
              getPaymentData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 ml-2 border rounded-md bg-slate-500 text-white"
          >
            Get Data
          </a>
        </div>
      </div>

      <div className="mt-5 flex flex-col flex-wrap sm:flex-row justify-between">
        <div className="flex">
          <p style={{ marginTop: 2 }}>Status</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select transaction status"
            optionFilterProp="children"
            onChange={(value) => setStatusFilter(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={"All"}>All</Option>
            <Option value={"Pending"}>Pending</Option>
            <Option value={"Paid"}>Paid</Option>
          </Select>
        </div>
        <div className="flex justify-center">
          <a
            onClick={() => {
              filterData();
            }}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div> */}

      {/* Completed bookings View */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedData}
          scroll={{
            y: 600,
          }}
        />
      </div>
    </div>
  );
  //   <DatePicker placeholder="Current month" onChange={onMonthChange} picker="month" />;
};

export default RWAPaymentsScreenForRwaAdmin;
