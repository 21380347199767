import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartGraph = ({ data }) => {
  //   console.log(data);
  return (
    <>
      <Pie className="shadow-2xl" data={data} />
    </>
  );
};

export default PieChartGraph;
