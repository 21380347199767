import React, { useEffect } from "react";
import LoadingComponent from "../Components/LoadingComponent";

const DownloadApplicationScreen = () => {
  useEffect(() => {
    let string = window?.navigator?.userAgent;
    if (string?.includes("iPhone")) {
      setTimeout(() => {
        window.location.href =
          "https://apps.apple.com/in/app/electricweb/id1569958711";
      }, 2000);
    } else {
      setTimeout(() => {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.acdcelectric.urja";
      }, 2000);
    }
  }, []);
  return (
    <div className="h-screen w-full flex flex-1 justify-center">
      <div>
        <LoadingComponent title={"Checking Device Compatibility"} />
      </div>
    </div>
  );
};

export default DownloadApplicationScreen;
