import React, { useState, useEffect } from "react";
import LayoutComponent from "../../Components/LayoutComponent";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const Option = Select.Option;

const RWAadminDetails = () => {
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState();
  const [formattedAdminsArray, setFormattedAdminsArray] = useState([]);
  const [editAdminModel, setEditAdminModel] = useState(false);
  const [deleteAdminModel, setDeleteAdminModel] = useState(false);
  const [addNewAdminModelOpen, setAddNewAdminModelOpen] = useState(false);
  const [displayedAdmins, setDisplayedAdmins] = useState([]);
  const [allLocations, setAllLocations] = useState();

  const [addAdminEmail, setAddAdminEmail] = useState();
  const [addAdminPassword, setAddAdminPassword] = useState();
  const [addAdminLocationAccess, setAddAdminLocationAccess] = useState();

  const [updateAdminEmail, setUpdateAddAdminEmail] = useState();
  const [updateAdminPassword, setUpdateAdminPassword] = useState();

  const getAdmins = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/rwa/admins`, {
        token: localStorage.getItem("token"),
      });
      console.log(res?.data);
      if (res?.data?.success) {
        setAdmins(res?.data?.admins);
        setAllLocations(res?.data?.locations);
        formatAdminData(res?.data?.admins, res?.data?.locations);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formatAdminData = async (admins, allLocations) => {
    try {
      let resultedArray = [];
      admins?.map((admin) => {
        allLocations?.map((location) => {
          if (admin.locationAccess === location?._id) {
            admin.location = location?.address;
            resultedArray.push(admin);
          }
        });
      });
      setFormattedAdminsArray(resultedArray);
      console.log(resultedArray);
      setDisplayedAdmins(resultedArray);
      // console.log(resultedArray);
    } catch (error) {
      console.log(error);
    }
  };

  const filterData = () => {
    setLoading(true);
    try {
      if (selectedAdmin) {
        let resultedArray = [];
        formattedAdminsArray &&
          formattedAdminsArray?.map((admin) => {
            if (admin?.location === selectedAdmin) {
              resultedArray.push(admin);
            }
          });
        setDisplayedAdmins(resultedArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  const handleDeleteAdmin = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/delete/rwa/admin`, {
        token: localStorage.getItem("token"),
        adminId: selectedAdmin?._id,
      });
      if (res?.data?.success) {
        getAdmins();

        setLoading(false);
        message.success("Admin deleted successfully");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Error in deleting admin");
    }
  };

  const handleAddNewAdmin = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/register/new/rwa/admin`, {
        token: localStorage.getItem("token"),
        email: addAdminEmail,
        password: addAdminPassword,
        locationAccess: addAdminLocationAccess,
      });
      if (res?.data?.success) {
        getAdmins();
        setLoading(false);
        message.success("Admin added successfully");
      } else {
        setLoading(false);
        message.error("Error in adding Admin");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Error in adding admin");
    }
  };

  const handleUpdateAdmin = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/update/rwa/admin/details`, {
        token: localStorage.getItem("token"),
        adminId: selectedAdmin?._id,
        email: updateAdminEmail || selectedAdmin?.email,
        password: updateAdminPassword || selectedAdmin?.password,
      });
      if (res?.data?.success) {
        getAdmins();
        setLoading(false);
        message.success("Admin updated successfully");
      } else {
        setLoading(false);
        message.error("Error in updating Admin details");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Error in updating admin");
    }
  };

  const columns = [
    {
      title: "Location",
      dataIndex: `location`,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Password",
      dataIndex: "password",
    },
    {
      title: "  Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            // className="m-5"
            onClick={() => {
              setSelectedAdmin(record);
              setEditAdminModel(true);
            }}
          >
            <i style={{ color: "green" }} class="fa-solid fa-circle-info"></i>
          </a>
          <a
            // className="m-5 pr-5"
            onClick={() => {
              setSelectedAdmin(record);
              setDeleteAdminModel(true);
            }}
          >
            <i class="fa-solid fa-trash" style={{ color: "#fd4c12" }}></i>
          </a>
        </div>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [AddLocationForm] = Form.useForm();

  const [formLayout, setFormLayout] = useState("horizontal");
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 50,
            offset: 40,
            position: "absolute",
          },
        }
      : null;

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Model for delete Admin */}
      <Modal
        title="Confirm to delete"
        centered
        open={deleteAdminModel}
        onCancel={() => setDeleteAdminModel(false)}
        footer={[
          null,
          <Button
            style={{ borderColor: "red", color: "red" }}
            onClick={() => {
              handleDeleteAdmin();
              setDeleteAdminModel(false);
            }}
          >
            Delete
          </Button>,
          <Button onClick={() => setDeleteAdminModel(false)}>Cancel</Button>,
        ]}
      >
        <p className="pt-2 " style={{ color: "grey" }}>
          Are you sure to delete Admin Access of location{" "}
          {selectedAdmin && selectedAdmin?.location}{" "}
        </p>
      </Modal>

      {/* Model for Adding New Admin */}
      <Modal
        title="Add New Rwa Admin"
        centered
        open={addNewAdminModelOpen}
        onCancel={() => {
          setAddNewAdminModelOpen(false);
          setAddAdminEmail("");
          setAddAdminPassword("");
          setAddAdminLocationAccess("");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              handleAddNewAdmin();
              setAddNewAdminModelOpen(false);
              setTimeout(() => {
                setAddAdminEmail("");
                setAddAdminPassword("");
                setAddAdminLocationAccess("");
              }, 1500);
            }}
          >
            Add
          </Button>,
          <Button
            onClick={() => {
              setAddNewAdminModelOpen(false);
              setAddAdminEmail("");
              setAddAdminPassword("");
              setAddAdminLocationAccess("");
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={AddLocationForm}
          initialValues={{ layout: formLayout }}
          onValuesChange={onFormLayoutChange}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Email">
            <Input
              required
              onChange={(e) => setAddAdminEmail(e.target.value)}
              placeholder="Enter Email"
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input
              onChange={(e) => setAddAdminPassword(e.target.value)}
              placeholder="Enter Password"
            />
          </Form.Item>
          <Form.Item label="Select Address">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={"Select Address"}
              onChange={(value) => setAddAdminLocationAccess(value)}
            >
              {allLocations?.map((location) => {
                return (
                  <option value={location?._id}>{location?.address}</option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* Model for Updating Admin */}
      <Modal
        title="Update Admin Details"
        centered
        open={editAdminModel}
        onCancel={() => {
          setEditAdminModel(false);
          setAddAdminEmail("");
          setAddAdminPassword("");
          setAddAdminLocationAccess("");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              handleUpdateAdmin();
              setEditAdminModel(false);
              setTimeout(() => {
                setAddAdminEmail("");
                setAddAdminPassword("");
                setAddAdminLocationAccess("");
              }, 1500);
            }}
          >
            Update
          </Button>,
          <Button
            onClick={() => {
              setEditAdminModel(false);
              setAddAdminEmail("");
              setAddAdminPassword("");
              setAddAdminLocationAccess("");
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Form
          className="mt-7"
          {...formItemLayout}
          layout={formLayout}
          form={AddLocationForm}
          initialValues={{ layout: formLayout }}
          onValuesChange={onFormLayoutChange}
          style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
        >
          <Form.Item label="Email">
            <Input
              required
              defaultValue={selectedAdmin?.email}
              onChange={(e) => setUpdateAddAdminEmail(e.target.value)}
              placeholder="Enter Email"
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input
              defaultValue={selectedAdmin?.password}
              onChange={(e) => setUpdateAdminPassword(e.target.value)}
              placeholder="Enter Password"
            />
          </Form.Item>
          {/* <Form.Item label="Select Address">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={"Select Address"}
              onChange={(value) => setAddAdminLocationAccess(value)}
            >
              {allLocations?.map((location) => {
                return (
                  <option value={location?._id}>{location?.address}</option>
                );
              })}
            </Select>
          </Form.Item> */}
        </Form>
      </Modal>

      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          RWA Admin Details
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddNewAdminModelOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add New Rwa Admin
          </a>
        </span>
      </div>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        {/* State */}
        <div className="flex">
          <p style={{ marginTop: 2 }}>Select Location</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 400 }}
            placeholder="Select location"
            optionFilterProp="children"
            defaultValue={selectedAdmin}
            onChange={(value) => setSelectedAdmin(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {allLocations &&
              allLocations?.map((location) => {
                return (
                  <Option value={location?.address}>{location?.address}</Option>
                );
              })}
          </Select>
        </div>

        <div className="flex justify-center">
          <a
            onClick={() => filterData()}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>

      {/* Showing Locations */}
      <div className="mt-12 overflow-auto">
        <Table
          columns={columns}
          dataSource={displayedAdmins && displayedAdmins}
          scroll={{
            x: 1000,
            y: 600,
          }}
        />
      </div>
    </div>
  );
};

export default RWAadminDetails;
