import React, { useState, useEffect } from "react";
import { Button, Select, message, Modal } from "antd";
import LoadingComponent from "../../../Components/LoadingComponent";
import axios from "axios";
import { BaseUrl } from "../../../static/BaseUrl.js";
import exportFromJSON from "export-from-json";

const Screen = () => {
  const [selectedTransactionId, setSelectedtransactionId] = useState();

  var chargerStatusNotificationArray = [
    {
      chargerName: "EWUPGHRPPP001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGHVHAH001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND00LB002",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND78MM001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGHRPRP_B01",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGHRPEH_B01",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND00LB_B01",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND77PW001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND78MM002",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGNP2SC001",
      status: "Unavailable",
    },
    {
      chargerName: "EWHRGN12GG001",
      status: "Unavailable",
    },
    {
      chargerName: "EWHRGNETAC001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPIPAKSS001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND78UC001",
      status: "Unavailable",
    },
    {
      chargerName: "EWHRGN13LV001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGHRERR001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGHRPRP001",
      status: "Unavailable",
    },
    {
      chargerName: "EWHRGN13LV002",
      status: "Unavailable",
    },
    {
      chargerName: "EWHRBG37NH001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGHSVAK001",
      status: "Unavailable",
    },
    {
      chargerName: "EWHRGNETDC001",
      status: "Unavailable",
    },
    {
      chargerName: "EWHRGN12GG002",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGNP2SC002",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND68GP001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPGHVHAV001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPND00LB001",
      status: "Unavailable",
    },
    {
      chargerName: "EWUPIPAKSS002",
      status: "Unavailable",
    },
  ];

  const [logs, setLogs] = useState();
  const [selectedCharegr, setSelectedCharger] = useState();

  const handleGetLogs = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/logs`, {
        token: localStorage.getItem("token"),
        transactionId: selectedTransactionId,
      });
      if (res?.data?.success) {
        let LOGS = res?.data?.logs;
        if (LOGS?.length) {
          setLogs(res?.data?.logs);
          downloadData(res?.data?.logs);
          message.success("Logs downloaded");
        } else {
          message.warning("No Logs found");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadData = async (data) => {
    const fileName = `Charging-Logs-(${selectedTransactionId})`;

    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Download Logs
      </p>

      <div className="mt-12 flex justify-around border-2 py-4 rounded-lg shadow-2xl">
        <p className="mt-1">Download Transaction Logs </p>
        <div className="flex justify-center">
          <p className="mt-1">Transaction Id : </p>
          <input
            // className="shadow-xl"
            maxLength={5}
            onChange={(e) => setSelectedtransactionId(e.target.value)}
            style={{
              borderWidth: 1,
              borderRadius: 5,
              marginLeft: 10,
              textAlign: "center",
            }}
          />
        </div>

        <Button
          onClick={() => {
            if (selectedTransactionId) {
              handleGetLogs();
            } else {
              message.error("Enter Transaction Id");
            }
          }}
        >
          Download Logs
        </Button>
      </div>

      {/* <div className="mt-12 flex justify-around border-2 py-4 rounded-lg shadow-2xl">
        <p className="mt-1">Download Charger Logs </p>
        <div className="flex justify-center">
          <p className="mt-1">Charger : </p>
          <input
            maxLength={5}
            onChange={(e) => setSelectedtransactionId(e.target.value)}
            style={{
              borderWidth: 1,
              borderRadius: 5,
              marginLeft: 10,
              textAlign: "center",
            }}
          />
        </div>

        <div className="flex justify-center">
          <p className="mt-1">Logs Count : </p>
          <input
            maxLength={4}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9]*$/; // Regular expression to allow only numeric characters
              if (regex.test(value)) {
                setSelectedtransactionId(value);
              }
            }}
            // onChange={(e) => setSelectedtransactionId(e.target.value)}
            style={{
              borderWidth: 1,
              borderRadius: 5,
              marginLeft: 10,
              textAlign: "center",
            }}
          />
        </div>

        <Button
          onClick={() => {
            if (selectedTransactionId) {
              handleGetLogs();
            } else {
              message.error("Enter Transaction Id");
            }
          }}
        >
          Download Logs
        </Button>
      </div> */}
    </div>
  );
};

export default Screen;
