import React, { useState, useEffect } from "react";
import { Button, Select, message, Modal } from "antd";

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();

  const [selectedCharger, setSelectedCharger] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState();

  const handleSendRequest = async (socket) => {
    try {
      setTriggerMessageModalOpen(true);
      let sendData = {
        From: "BD8746G645VWU83",
        To: `${selectedCharger}`,
        message: [
          2,
          "firmwareUpdate",
          "UpdateFirmwareRequest",
          {
            location:
              "https://drive.google.com/uc?export=download&id=1d5oEZ0T6Fm7IVBYx-OnW852px8Pyr4fb",
            retrieveDate: new Date(),
          },
        ],
      };
      await socket.send(JSON.stringify(sendData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[5] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[3] ===
          "FirmwareStatusNotification"
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[7];
        setResponseMessage(message);
      } else if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] ===
          "FirmwareStatusNotification"
      ) {
        setResponseMessage(rawResponseData.split("*")[2]);
      }
    }
  }, [rawResponseData]);

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      let sendData = {
        From: "BD8746G645VWU83",
      };
      ws.send(sendData);

      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            setCharegrStatusArray(array);
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              // setChargerStatus(chargersMap);
            }
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <Modal
        // title="Confirm"
        centered
        open={triggerMessageModalOpen}
        onCancel={() => {
          setTriggerMessageModalOpen(false);
          setResponseMessage("Waiting for response...");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setTriggerMessageModalOpen(false);
              setResponseMessage("Waiting for response...");
            }}
          >
            Close
          </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">Update firmware</p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Update Firmware
      </p>
      <p className="text-slate-600">
        Central System can notify a Charge Point that it needs to update its
        firmware. The Central System SHALL send an UpdateFirmware.req PDU to
        instruct the Charge Point to install new firmware. The PDU SHALL contain
        a date and time after which the Charge Point is allowed to retrieve the
        new firmware and the location from which the firmware can be downloaded.
        Upon receipt of an UpdateFirmware.req PDU, the Charge Point SHALL
        respond with a UpdateFirmware.conf PDU. The Charge Point SHOULD start
        retrieving the firmware as soon as possible after retrieve-date. 58
        During downloading and installation of the firmware, the Charge Point
        MUST send FirmwareStatusNotification.req PDUs to keep the Central System
        updated with the status of the update process. The Charge Point SHALL,
        if the new firmware image is "valid", install the new firmware as soon
        as it is able to. If it is not possible to continue charging during
        installation of firmware, it is RECOMMENDED to wait until Charging
        Session has ended (Charge Point idle) before commencing installation. It
        is RECOMMENDED to set connectors that are not in use to UNAVAILABLE
        while the Charge Point waits for the Session to end. The sequence
        diagram above is an example. It is good practice to first reboot the
        Charge Point to check the new firmware is booting and able to connect to
        the Central System, before sending the status: Installed. This is not a
        requirement.
      </p>

      <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              if (charger?.status !== "Unavailable") {
                return (
                  <option value={charger?.chargerName}>
                    {charger?.chargerName}
                  </option>
                );
              }
            })}
        </Select>
      </div>

      {selectedCharger ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Screen;
