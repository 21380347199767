import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const FAQScreen = () => {
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);
  const [addNewFaqModalOpen, setAddNewFaqModalOpen] = useState(false);
  const [updateFaqModalOpen, setUpdateFaqModalOpen] = useState(false);

  const [newFaqQuestion, setNewFaqQuestion] = useState();
  const [newFaqAnswer, setNewFaqAnswer] = useState();

  const [selectedFaqId, setSelectedFaqId] = useState();
  const [selectedFaqQuestion, setSelectedFaqQuestion] = useState();
  const [selectedFaqAnswer, setSelectedFaqAnswer] = useState();

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/dashboard/Faqs`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setFaqs(res?.data?.faqs);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddNewFaq = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/add/faq/from/dashboard`, {
        token: localStorage.getItem("token"),
        question: newFaqQuestion,
        answer: newFaqAnswer,
      });
      if (res?.data?.success) {
        setLoading(false);
        message?.success("FAQ added successfully");
      } else {
        setLoading(false);
        message?.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteFAQ = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/delete/faq/dashboard`, {
        token: localStorage.getItem("token"),
        _id: selectedFaqId,
      });
      if (res?.data?.success) {
        setUpdateFaqModalOpen(false);
        setLoading(false);
        getFaqs();
        message?.success("Deleted successfully");
      } else {
        setLoading(false);
        message?.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleUpdateFAQ = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/update/faq/dashboard`, {
        token: localStorage.getItem("token"),
        _id: selectedFaqId,
        question: selectedFaqQuestion,
        answer: selectedFaqAnswer,
      });
      if (res?.data?.success) {
        setUpdateFaqModalOpen(false);
        setLoading(false);
        getFaqs();
        message?.success("Updated successfully");
      } else {
        setLoading(false);
        message?.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Add new FAQ Modal*/}
      <Modal
        title="Add FAQ"
        centered
        open={addNewFaqModalOpen}
        onCancel={() => {
          setAddNewFaqModalOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setAddNewFaqModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            primary
            onClick={() => {
              setAddNewFaqModalOpen(false);
              handleAddNewFaq();
            }}
          >
            Post
          </Button>,
        ]}
      >
        <p>Question : </p>
        <textarea
          autoComplete="on"
          autoCapitalize="on"
          autoFocus={true}
          placeholder="Enter question"
          rows={3}
          style={{
            width: "100%",
            borderWidth: 1,
            borderColor: "gray",
            borderRadius: 8,
            padding: 5,
          }}
          onChange={(e) => setNewFaqQuestion(e.target.value)}
        />

        <p className="mt-2">Answer : </p>
        <textarea
          autoComplete="on"
          autoCapitalize="on"
          autoFocus={true}
          placeholder="Enter answer"
          rows={5}
          style={{
            width: "100%",
            borderWidth: 1,
            borderColor: "gray",
            borderRadius: 8,
            padding: 5,
          }}
          onChange={(e) => setNewFaqAnswer(e.target.value)}
        />
        {/* <p style={{ fontSize: 12 }}>
          Note : For changing to the next line in the form answer input, please
          use '\n' as the escape sequence.{" "}
        </p> */}
      </Modal>

      {/* Edit FAQ Modal */}
      <Modal
        title="Update FAQ"
        centered
        open={updateFaqModalOpen}
        onCancel={() => {
          setUpdateFaqModalOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setUpdateFaqModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            //   danger
            onClick={() => {
              setUpdateFaqModalOpen(false);
              handleDeleteFAQ();
            }}
          >
            Delete
          </Button>,
          <Button
            primary
            onClick={() => {
              setUpdateFaqModalOpen(false);
              handleUpdateFAQ();
            }}
          >
            Update
          </Button>,
        ]}
      >
        <p>Question : </p>
        <textarea
          defaultValue={selectedFaqQuestion}
          autoComplete="on"
          autoCapitalize="on"
          autoFocus={true}
          placeholder="Enter question"
          rows={3}
          style={{
            width: "100%",
            borderWidth: 1,
            borderColor: "gray",
            borderRadius: 8,
            padding: 5,
          }}
          onChange={(e) => setSelectedFaqQuestion(e.target.value)}
        />

        <p className="mt-2">Answer : </p>
        <textarea
          defaultValue={selectedFaqAnswer}
          autoComplete="on"
          autoCapitalize="on"
          autoFocus={true}
          placeholder="Enter answer"
          rows={5}
          style={{
            width: "100%",
            borderWidth: 1,
            borderColor: "gray",
            borderRadius: 8,
            padding: 5,
          }}
          onChange={(e) => setSelectedFaqAnswer(e.target.value)}
        />
        {/* <p style={{ fontSize: 12 }}>
          Note : For changing to the next line in the form answer input, please
          use '\n' as the escape sequence.{" "}
        </p> */}
      </Modal>

      <div className="h-dvh" style={{ overflow: "scroll" }}>
        <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
          <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
            FAQs
          </p>
          <a
            onClick={() => setAddNewFaqModalOpen(true)}
            className="px-3 py-1 rounded"
            style={{
              position: "relative",
              left: "87%",
              backgroundColor: "#001628",
              color: "white",
              marginTop: 10,
            }}
          >
            Add FAQ
          </a>
        </div>
        {faqs &&
          faqs?.map((faq) => {
            return (
              <div className="my-10  px-5 rounded-xl py-5 shadow-xl drop-shadow-2xl">
                <div style={{ position: "absolute", right: "5%" }}>
                  <i
                    onClick={() => {
                      setSelectedFaqId(faq?._id);
                      setSelectedFaqQuestion(faq?.question);
                      setSelectedFaqAnswer(faq?.answer);
                      setUpdateFaqModalOpen(true);
                    }}
                    style={{ color: "#001628" }}
                    class="fa-regular fa-pen-to-square cursor-pointer"
                  ></i>
                </div>
                <h1 style={{ color: "#001628" }} className="text-lg">
                  {faq?.question}
                </h1>
                {faq?.answer?.split("\n")?.map((text) => {
                  return <p className="text-gray-700 py-1 px-5">{text}</p>;
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FAQScreen;
