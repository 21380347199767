import axios from "axios";
import React, { useState, useEffect } from "react";
import { BaseUrl } from "../../static/BaseUrl.js";
import { Modal, message } from "antd";
import LoadingComponent from "../../Components/LoadingComponent.js";

import { DeleteOutlined } from "@ant-design/icons";

const EvManufacturersScreen = () => {
  const [photo, setPhoto] = useState();
  const [manufacturerName, setManufacturerName] = useState();

  const [manufacturers, setManufacturers] = useState();

  const [loadingScreen, setLoadingScreen] = useState(true);

  const [addManufacturerModelOpen, setAddManufacturermodelOpen] =
    useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState();

  const [deleteManufacturerModalOpen, setDeleteManufacturerModalOpen] =
    useState(false);

  const getManufacturers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/all/manufacturers`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setManufacturers(res?.data?.manufacturers);
        setLoadingScreen(false);
      } else {
        setLoadingScreen(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingScreen(false);
    }
  };

  useEffect(() => {
    getManufacturers();
  }, []);

  const ImageComponent = (manufacturer) => {
    try {
      const base64String = btoa(
        String.fromCharCode.apply(null, manufacturer.photo.data.data)
      );

      return (
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              borderRadius: 20,
              borderWidth: 2,
              paddingRight: 40,
              paddingLeft: 40,
              paddingTop: 20,
              paddingBottom: 20,
            }}
            className="shadow-xl items-center content-center"
            onClick={() => {
              setSelectedManufacturer(manufacturer);
              setDeleteManufacturerModalOpen(true);
            }}
          >
            <img
              style={{ height: 60, width: 60 }}
              src={`data:${manufacturer.photo.contentType};base64,${base64String}`}
              alt="Rendered Image"
            />
            <h2 style={{ textAlign: "center", color: "grey", marginTop: 7 }}>
              {manufacturer?.name}
            </h2>
          </div>
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      if (!manufacturerName || !photo) {
        return message.warning("All field required");
      }

      e.preventDefault();

      const ManufacturerData = new FormData();
      ManufacturerData.append("name", manufacturerName);
      ManufacturerData.append("photo", photo);
      ManufacturerData.append("token", localStorage.getItem("token"));

      const res = await axios.post(
        `${BaseUrl}/add/manufacturerImage`,
        ManufacturerData
      );
      if (res?.data?.success) {
        setAddManufacturermodelOpen(false);
        getManufacturers();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteManufacturer = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/delete/manufacturer`, {
        token: localStorage.getItem("token"),
        manufacturerId: selectedManufacturer?._id,
      });
      if (res?.data?.success) {
        setDeleteManufacturerModalOpen(false);
        getManufacturers();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return loadingScreen ? (
    <LoadingComponent />
  ) : (
    <>
      <Modal
        title="Add Ev Manufacturer"
        centered
        open={addManufacturerModelOpen}
        onCancel={() => setAddManufacturermodelOpen(false)}
        footer={[
          null,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </button>,
        ]}
      >
        <label className="btn btn-outline-secondary col-md-12">
          Manufacturer name
          <input
            type="text"
            required
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderRadius: 4,
              marginLeft: 10,
              padding: 4,
            }}
            onChange={(e) => setManufacturerName(e.target.value)}
            // hidden
          />
        </label>

        <div className="mb-3">
          <label
            className="btn btn-outline-secondary col-md-12"
            style={{ textAlign: "center" }}
          >
            {/* {photo ? photo.name : "Upload Photo"} */}
            Upload Photo
            <input
              type="file"
              name="photo"
              accept="image/*"
              onChange={(e) => setPhoto(e.target.files[0])}
              style={{ marginLeft: 48, marginTop: 30 }}
              // hidden
            />
          </label>
        </div>
        <div className="mb-3">
          {photo && (
            <div className="text-center justify-center items-center ml-28">
              <img
                src={URL.createObjectURL(photo)}
                alt="product_photo"
                height={"200px"}
                className="img img-responsive"
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title="Delete Ev Manufacturer"
        centered
        open={deleteManufacturerModalOpen}
        onCancel={() => setDeleteManufacturerModalOpen(false)}
        footer={[
          null,
          <button
            style={{
              // backgroundColor: "#001529",
              // color: "white",
              padding: 10,
              borderRadius: 3,
              marginRight: 10,
              borderWidth: 1,
            }}
            className="btn btn-primary"
            onClick={() => setDeleteManufacturerModalOpen(false)}
          >
            Cancel
          </button>,
          <button
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: 10,
              borderRadius: 3,
            }}
            className="btn btn-primary"
            onClick={() => handleDeleteManufacturer()}
          >
            Delete
          </button>,
        ]}
      >
        <p>
          Are you sure to delete {selectedManufacturer?.name} manufacturer ?
        </p>
      </Modal>

      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Ev Manufacturer
        </p>
        <span className="ml-0 sm:ml-4 flex justify-center items-center">
          <a
            onClick={() => setAddManufacturermodelOpen(true)}
            className="text-center pr-2 pl-2 pt-2 pb-2 rounded-md  text-slate-500"
          >
            Add manufacturer
          </a>
        </span>
      </div>

      {/* Show All Manufacturers */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: 40,
        }}
      >
        {manufacturers &&
          manufacturers?.map((manufacturer) => {
            return ImageComponent(manufacturer);
          })}
      </div>
    </>
  );
};

export default EvManufacturersScreen;
