import React, { useState, useEffect } from "react";

const ReserveNowScreen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">CMS Operation : Reserve Now</p>
      <p className="text-slate-600">
        A Central System can issue a ReserveNow.req to a Charge Point to reserve
        a connector for use by a specific idTag. To request a reservation the
        Central System SHALL send a ReserveNow.req PDU to a Charge Point. The
        Central System MAY specify a connector to be reserved. Upon receipt of a
        ReserveNow.req PDU, the Charge Point SHALL respond with a
        ReserveNow.conf PDU.
      </p>
    </div>
  );
};

export default ReserveNowScreen;
