import React, { useState, useEffect } from "react";

const Screen = () => {
  const [chargerStatuses, setChargerStatus] = useState();

  const [wsGlobal, setWsGlobal] = React.useState();

  useEffect(() => {
    var ws = new WebSocket("wss://acdcelectric.in");
    ws.onopen = () => {
      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            const chargersMap = new Map();
            array.map((charger) => {
              return chargersMap.set(charger.chargerName, charger.status);
            });
            if (refresh === true) {
              refresh = false;
              setChargerStatus(chargersMap);
            }
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <p className="font-semibold text-xl mb-1">
        CMS Operation : Get Diagnostics
      </p>
      <p className="text-slate-600">
        Central System can request a Charge Point for diagnostic information.
        The Central System SHALL send a GetDiagnostics.req PDU for getting
        diagnostic information of a Charge Point with a location where the
        Charge Point MUST upload its diagnostic data to and optionally a begin
        and end time for the requested diagnostic information.respond with a
        GetDiagnostics.conf PDU stating the name of the file containing the
        diagnostic information that will be uploaded. Charge Point SHALL upload
        a single file. Format of the diagnostics file is not prescribed. If no
        diagnostics file is available, then GetDiagnostics.conf SHALL NOT
        contain a file name. During uploading of a diagnostics file, the Charge
        Point MUST send DiagnosticsStatusNotification.req PDUs to keep the
        Central System updated with the status of the upload process.
      </p>
    </div>
  );
};

export default Screen;
