import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const UsersConcernsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [pendingUserConcerns, setPendingUserConcerns] = useState([]);
  const [formatedTransactions, setFormatedTransactions] = useState();
  const [unchangedFormatedTransactions, setUnchangedFormatedTransactions] =
    useState();
  const [selectedConcern, setSelectedConcern] = useState();
  const [editConcernStatusModalOpen, setEditConcernStatusModalOpen] =
    useState(false);
  const [updatedConcernStatus, setUpdatedConcernStatus] = useState();

  const getUserConcerns = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/user/concerns`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setPendingUserConcerns(res?.data?.pendingConcerns);
        formatConcernData(res?.data?.pendingConcerns);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserConcerns();
  }, []);

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }

  const formatConcernData = async (Transactions) => {
    setLoading(true);
    try {
      const array = Transactions;
      await array?.map((concern) => {
        concern.time =
          concern?.time?.split(" ")[1] +
          "/" +
          concern?.time?.split(" ")[2] +
          "/" +
          concern?.time?.split(" ")[3] +
          " " +
          convertTo12HourFormat(concern?.time?.split(" ")[4]);
      });
      setLoading(false);

      setUnchangedFormatedTransactions(array);
      return setFormatedTransactions(array);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "time",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: "User Name",
      dataIndex: `name`,
    },
    {
      title: "Mobile No.",
      dataIndex: `phone`,
      width: 200,
    },
    {
      title: "Booking Id",
      dataIndex: `transactionId`,
      width: 200,
    },
    {
      title: "Type",
      dataIndex: `type`,
      width: 200,
    },
    {
      title: "Description",
      dataIndex: `description`,
      width: 300,
    },
    {
      title: "Status",
      dataIndex: `status`,
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <a
            className="ml-5"
            onClick={() => {
              setSelectedConcern(record);
              setEditConcernStatusModalOpen(true);
            }}
          >
            <i
              style={{ color: "blue" }}
              class="fa-regular fa-pen-to-square"
            ></i>
          </a>
        </>
      ),
    },
  ];

  const updateConcernStatus = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/update/user/concern/status`, {
        token: localStorage.getItem("token"),
        _id: selectedConcern?._id,
        status: updatedConcernStatus,
      });
      if (res?.data?.success) {
        setEditConcernStatusModalOpen(false);
        getUserConcerns();
        message.success("Updated successfully");
      } else {
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* Heading */}
      <div className="w-full  flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Customer Concerns
        </p>

        <a
          onClick={() => {
            getUserConcerns();
          }}
          className="text-center pr-2 pl-2 right-0 pt-1 pb-1 border rounded-md ml-10 mt-1 bg-slate-500 text-white"
        >
          Refresh
        </a>
      </div>

      {/* Update User Concern Modal */}
      <Modal
        title="Update concern status:"
        centered
        open={editConcernStatusModalOpen}
        onCancel={() => {
          setEditConcernStatusModalOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setEditConcernStatusModalOpen(false);
            }}
          >
            Close
          </Button>,
          <Button
            danger
            onClick={() => {
              setEditConcernStatusModalOpen(false);
              updateConcernStatus();
            }}
          >
            Update
          </Button>,
        ]}
      >
        <div className="mt-5 flex justify-center">
          <p style={{ width: 220 }} className="text-center pt-1 font-bold">
            User Name
          </p>
          <p className=" text-center pt-1 font-bold">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1 font-bold">
            {selectedConcern?.name}
          </p>
        </div>
        <div className="mt-3 flex justify-center">
          <p style={{ width: 220 }} className="text-center pt-1">
            Concern Type
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1">
            {selectedConcern?.type}
          </p>
        </div>

        <div className="mt-3 flex justify-center">
          <p style={{ width: 220 }} className="text-center pt-1">
            Description
          </p>
          <p className=" text-center pt-1 ">:</p>
          <p style={{ width: 220 }} className=" text-center pt-1">
            {selectedConcern?.description}
          </p>
        </div>

        <p className="text-center mt-9">Update Status</p>

        <div className="flex w-full justify-center pt-3 pb-2">
          <Select
            style={{ width: 300 }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={selectedConcern?.status}
            onChange={(value) => setUpdatedConcernStatus(value)}
          >
            <option value={"resolved"}>Resolved</option>
            {/* <option value={"In Transit"}>In Transit</option>
            <option value={"Delivered"}>Delivered</option> */}
          </Select>
        </div>
        <div className="flex w-full justify-center pt-3 pb-2"></div>
      </Modal>

      {/* Showing Chargers */}
      <div className="mt-4 overflow-auto">
        <Table columns={columns} dataSource={formatedTransactions} />
      </div>
    </>
  );
};

export default UsersConcernsScreen;
