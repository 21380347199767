import React, { useState } from "react";
import { Input, Select, message } from "antd";
import LoadingComponent from "../WebsiteComponents/LoadingComponent";
import { BaseUrl } from "../static/BaseUrl.js";

import Layout from "../WebsiteComponents/Layout";
import "../WebsiteComponents/HeaderCss.css";
import axios from "axios";

const { Option } = Select;

const PartnerPage = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [selectedMobile, setSelectedmobile] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [businessDetails, setBusinessDetails] = useState();

  const [loading, setLoading] = useState(false);

  const [cities, setCities] = useState([]);

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(undefined);

    setCities(stateCityMap[value] || []);
    // console.log(stateCityMap[value]);
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "New Delhi",
    "Delhi",
  ];

  const stateCityMap = {
    "Andaman and Nicobar Islands": ["Port Blair"],
    "Andhra Pradesh": [
      "Visakhapatnam",
      "Vijayawada",
      "Guntur",
      "Nellore",
      "Kurnool",
      "Rajahmundry",
    ],
    "Arunachal Pradesh": ["Itanagar", "Naharlagun"],
    Assam: ["Guwahati", "Silchar", "Dibrugarh", "Jorhat"],
    Bihar: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
    Chandigarh: ["Chandigarh"],
    Chhattisgarh: ["Raipur", "Bhilai", "Bilaspur"],
    "Dadra and Nagar Haveli": ["Silvassa"],
    "Daman and Diu": ["Daman", "Diu"],
    Delhi: ["New Delhi"],
    Goa: ["Panaji", "Margao"],
    Gujarat: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
    Haryana: [
      "Ambala",
      "Bahadurgarh",
      "Bhiwani",
      "Charkhi Dadri",
      "Faridabad",
      "Fatehabad",
      "Gurugram",
      "Hisar",
      "Jhajjar",
      "Jind",
      "Kaithal",
      "Karnal",
      "Kurukshetra",
      "Mahendragarh",
      "Narnaul",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Rewari",
      "Rohtak",
      "Sirsa",
      "Sonipat",
      "Yamunanagar",
    ],
    "Himachal Pradesh": ["Shimla", "Mandi", "Dharamshala"],
    "Jammu and Kashmir": ["Srinagar", "Jammu", "Anantnag"],
    Jharkhand: ["Ranchi", "Jamshedpur", "Dhanbad"],
    Karnataka: ["Bengaluru", "Mysuru", "Hubballi", "Mangaluru"],
    Kerala: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Thrissur"],
    Ladakh: ["Leh", "Kargil"],
    Lakshadweep: ["Kavaratti"],
    "Madhya Pradesh": ["Bhopal", "Indore", "Jabalpur", "Gwalior"],
    Maharashtra: ["Mumbai", "Pune", "Nagpur", "Nashik"],
    Manipur: ["Imphal"],
    Meghalaya: ["Shillong"],
    Mizoram: ["Aizawl"],
    Nagaland: ["Kohima"],
    Odisha: ["Bhubaneswar", "Cuttack", "Rourkela"],
    Puducherry: ["Puducherry"],
    Punjab: ["Chandigarh", "Ludhiana", "Amritsar", "Jalandhar"],
    Rajasthan: ["Jaipur", "Jodhpur", "Udaipur", "Kota"],
    Sikkim: ["Gangtok"],
    "Tamil Nadu": ["Chennai", "Coimbatore", "Madurai", "Tiruchirappalli"],
    Telangana: ["Hyderabad", "Warangal"],
    Tripura: ["Agartala"],
    "Uttar Pradesh": [
      "Agra",
      "Aligarh",
      "Allahabad",
      "Amroha",
      "Ayodhya",
      "Azamgarh",
      "Bareilly",
      "Basti",
      "Bijnor",
      "Bulandshahr",
      "Deoria",
      "Etah",
      "Etawah",
      "Faizabad",
      "Farrukhabad",
      "Fatehpur",
      "Firozabad",
      "Ghaziabad",
      "Ghazipur",
      "Gonda",
      "Gorakhpur",
      "Hapur",
      "Hardoi",
      "Hathras",
      "Jalaun",
      "Jaunpur",
      "Jhansi",
      "Kannauj",
      "Kanpur",
      "Lakhimpur",
      "Lalitpur",
      "Lucknow",
      "Mathura",
      "Meerut",
      "Mirzapur",
      "Moradabad",
      "Muzaffarnagar",
      "Noida",
      "Pilibhit",
      "Pratapgarh",
      "Raebareli",
      "Rampur",
      "Saharanpur",
      "Sambhal",
      "Shahjahanpur",
      "Sitapur",
      "Sultanpur",
      "Unnao",
      "Varanasi",
    ],
    Uttarakhand: ["Dehradun", "Haridwar", "Rishikesh"],
    "West Bengal": ["Kolkata", "Asansol", "Siliguri", "Durgapur"],
    "New Delhi": [
      "Connaught Place",
      "Karol Bagh",
      "Chanakyapuri",
      "Saket",
      "Dwarka",
      "Paharganj",
      "Rajouri Garden",
      "Lajpat Nagar",
      "South Extension",
      "Hauz Khas",
      "Greater Kailash",
      "Nehru Place",
      "Chandni Chowk",
      "Vasant Vihar",
      "Khan Market",
      "Janakpuri",
      "Vasant Kunj",
      "Laxmi Nagar",
      "Mayur Vihar",
      "Green Park",
      "Indirapuram",
    ],
    Delhi: [
      "North Delhi",
      "East Delhi",
      "South Delhi",
      "West Delhi",
      "Old Delhi",
      "Narela",
      "Sarita Vihar",
      "Shahdara",
      "Punjabi Bagh",
      "Model Town",
      "Najafgarh",
      "Dilshad Garden",
      "Yamuna Vihar",
      "Vivek Vihar",
      "Patel Nagar",
      "Karawal Nagar",
      "Seelampur",
      "Mehrauli",
      "Vasant Kunj",
      "Shastri Park",
      "Nand Nagri",
    ],
  };

  const handleSubmitRequest = async () => {
    try {
      if (
        !name ||
        !email ||
        !selectedMobile ||
        !selectedCity ||
        !selectedState ||
        !businessDetails
      ) {
        message.warning("Fill all required fields *");
      } else {
        setLoading(true);
        const res = await axios.post(`${BaseUrl}/partner/query`, {
          name,
          email,
          mobile: selectedMobile,
          city: selectedCity,
          state: selectedState,
          businessDetails,
        });
        if (res?.data?.success) {
          setLoading(false);
          message.success("Request Sent...");
        } else {
          setLoading(false);
          message.success(res?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <div style={{ position: "absolute" }}>
      <LoadingComponent />
    </div>
  ) : (
    <Layout>
      <div className="font-serif">
        {/*  */}
        <div className="flex flex-col sm:flex-row">
          <div className="sm:hidden block">
            <img
              style={{ width: "100%", height: 250 }}
              src="../static/202.jpeg"
            />
          </div>

          <div className="sm:w-2/4 px-5 sm:px-0 sm:pl-10 pt-3 pb-10 sm:hidden">
            <p style={{ color: "grey" }} className="text-4xl font-bold">
              EW for
            </p>
            <p style={{ color: "grey" }} className="text-4xl font-bold">
              Group Housing
            </p>

            <p style={{ color: "#545454" }} className="text-1xl pt-2">
              EW enables group housing societies to efficiently manage existing
              power infra and offer in-house charging amenity to meet the
              growing demand for electric vehicle charging by residents.
            </p>
          </div>

          <div className="hidden sm:block sm:w-2/4 pl-10 pt-20">
            <p style={{ color: "#545454" }} className="text-6xl font-bold">
              EW for
            </p>
            <p style={{ color: "#545454" }} className="text-6xl font-bold">
              Group Housing
            </p>

            <p style={{ color: "#545454" }} className="text-3xl pt-10 pr-10">
              EW enables group housing societies to efficiently manage existing
              power infra and offer in-house charging amenity to meet the
              growing demand for electric vehicle charging by residents.
            </p>
          </div>
          <div className="hidden sm:block w-2/4">
            <img
              style={{ width: "100%", height: 490 }}
              src="../static/202.jpeg"
            />
          </div>
        </div>

        {/*  */}
        <div className="flex flex-col sm:flex-row">
          <div className="block sm:hidden">
            <img
              style={{ width: "100%", height: 250 }}
              src="../static/21.jpeg"
            />
          </div>
          <div className="sm:block hidden w-2/4">
            <img
              style={{ width: "100%", height: 490 }}
              src="../static/21.jpeg"
            />
          </div>
          <div className="sm:block hidden w-2/4 pl-10 pt-20">
            <p style={{ color: "#545454" }} className="text-6xl font-bold">
              EW for
            </p>
            <p style={{ color: "#545454" }} className="text-6xl font-bold">
              Institutions
            </p>

            <p style={{ color: "#545454" }} className="text-3xl pt-10">
              EW offers state-of-the-art EV charging solutions for
              institutions/Commercial Office Complexes to promote sustainability
              and encourage EV adoption.
            </p>
          </div>

          <div className="sm:w-2/4 px-5 sm:px-0 sm:pl-10 pt-3 pb-10 sm:hidden">
            <p style={{ color: "grey" }} className="text-4xl font-bold">
              EW for
            </p>
            <p style={{ color: "grey" }} className="text-4xl font-bold">
              Institutions
            </p>

            <p style={{ color: "grey" }} className="text-1xl pt-1">
              EW offers state-of-the-art EV charging solutions for
              institutions/Commercial Office Complexes to promote sustainability
              and encourage EV adoption.
            </p>
          </div>
        </div>

        {/*  */}
        <div className="flex flex-col sm:flex-row">
          <div className="sm:hidden block  ">
            <img
              style={{ width: "100%", height: 250 }}
              src="../static/evparking.jpeg"
            />
          </div>
          <div className="sm:w-2/4 px-5 sm:px-0 sm:pl-10 pt-3 pb-10 sm:hidden">
            <p style={{ color: "grey" }} className="text-4xl font-bold">
              EW for
            </p>
            <p style={{ color: "grey" }} className="text-4xl font-bold">
              Charge Point Operators (CPOs)
            </p>

            <p style={{ color: "grey" }} className="text-1xl pt-1">
              EW empowers CPOs to establish robust EV charging infrastructure
              (turn-key basis) and earn electrifying returns on their
              investment.
            </p>
          </div>

          <div className="sm:block hidden w-2/4 pr-2 pl-10 pt-20">
            <p style={{ color: "#545454" }} className="text-6xl font-bold">
              EW for
            </p>
            <p style={{ color: "#545454" }} className="text-6xl font-bold">
              Charge Point Operators (CPOs)
            </p>

            <p style={{ color: "#545454" }} className="text-3xl pt-10">
              EW empowers CPOs to establish robust EV charging infrastructure
              (turn-key basis) and earn electrifying returns on their
              investment.
            </p>
          </div>

          <div className=" hidden sm:block w-2/4 ">
            <img
              style={{ width: "100%", height: 490 }}
              src="../static/evparking.jpeg"
            />
          </div>
        </div>
      </div>

      {/*  */}
      <div
        style={{ backgroundColor: "#171440" }}
        className="bg-white font-serif flex flex-col sm:flex-row"
      >
        {/* Left Portion */}
        <div className="py-10 sm:py-10 sm:w-3/5 flex flex-col justify-center">
          <div className="flex justify-center">
            <div className="flex  justify-center items-center">
              <div
                style={{ backgroundColor: "#E7EAEF", borderRadius: 10 }}
                className="px-5 py-3 hidden sm:block"
              >
                <p style={{ color: "#545454" }} className="  sm:font-bold">
                  EW provides complete technical consultation
                </p>
                <p style={{ color: "#545454" }} className="sm:font-bold">
                  to meet your requirements.
                </p>
              </div>
              <div
                style={{ backgroundColor: "#E7EAEF", borderRadius: 10 }}
                className="px-5 py-3 block sm:hidden "
              >
                <p
                  style={{ color: "#545454" }}
                  className="text-sm sm:font-bold"
                >
                  EW provides complete technical consultation to meet your
                  requirements.
                </p>
              </div>
            </div>
            <div className="ml-10">
              <img className="h-30 sm:h-40" src="../static/1.png" />
            </div>
          </div>
          {/* 2 */}
          <div className="flex justify-center my-10 sm:my-auto">
            <div className="mr-10">
              <img className="h-30 sm:h-40" src="../static/2.png" />
            </div>
            <div className="flex  justify-center items-center">
              <div
                style={{ backgroundColor: "#E7EAEF", borderRadius: 10 }}
                className="px-5 py-3 hidden sm:block"
              >
                <p style={{ color: "#545454" }} className="font-bold">
                  EW conducts detailed site surveys to
                </p>
                <p style={{ color: "#545454" }} className="font-bold">
                  recommend/suggest optimal
                </p>
                <p style={{ color: "#545454" }} className="font-bold">
                  {" "}
                  charging infra layout.
                </p>
              </div>
              <div
                style={{ backgroundColor: "#E7EAEF", borderRadius: 10 }}
                className="px-5 py-3 sm:hidden block"
              >
                <p style={{ color: "#545454" }} className="text-sm">
                  EW conducts detailed site surveys to recommend/suggest optimal
                  charging infra layout.
                </p>
              </div>
            </div>
          </div>
          {/* 3 */}
          <div className="flex justify-center">
            <div className="flex  justify-center items-center">
              <div
                style={{ backgroundColor: "#E7EAEF", borderRadius: 10 }}
                className="px-5 py-3 hidden sm:block"
              >
                <p style={{ color: "#545454" }} className="font-bold">
                  Post agreement execution, Team EW
                </p>
                <p style={{ color: "#545454" }} className="font-bold">
                  under takes installation and commissioning
                </p>
                <p style={{ color: "#545454" }} className="font-bold">
                  of EV infra in a time-bound manner.
                </p>
              </div>
              <div
                style={{ backgroundColor: "#E7EAEF", borderRadius: 10 }}
                className="px-5 py-3 sm:hidden block"
              >
                <p style={{ color: "#545454" }} className="text-sm">
                  Post agreement execution, Team EW under takes installation and
                  commissioning of EV infra in a time-bound manner.
                </p>
              </div>
            </div>
            <div className="ml-10">
              <img className="h-30  sm:h-40" src="../static/6.png" />
            </div>
          </div>
        </div>
        {/* right portion */}
        <div
          className="sm:w-2/5 py-10 sm:py-10 "
          style={{ backgroundColor: "#171440" }}
        >
          <div>
            <p
              style={{ color: "white" }}
              className=" text-center mb-5 text-2xl"
            >
              Get in touch
            </p>
            <div
              style={{
                borderColor: "#A0A1A4",
                borderWidth: 1,
                borderRadius: 20,
              }}
              className="sm:mt-12 sm:mx-12 py-10 flex flex-col justify-center "
            >
              <div className="flex justify-center pt-0">
                <Input
                  maxLength={20}
                  minLength={4}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter your name*"
                  size="small"
                  style={{
                    width: "70%",
                    backgroundColor: "white",
                    borderWidth: 0,
                    fontSize: 18,
                    padding: 6,
                    textAlign: "center",
                  }}
                />
              </div>
              <div className="flex justify-center pt-5">
                <Input
                  maxLength={50}
                  minLength={5}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Enter your email*"
                  size="small"
                  style={{
                    width: "70%",
                    backgroundColor: "white",
                    borderWidth: 0,
                    fontSize: 18,
                    padding: 6,
                    textAlign: "center",
                  }}
                />
              </div>
              <div className="flex justify-center pt-5 ">
                <Input
                  maxLength={10}
                  minLength={10}
                  onChange={(e) => {
                    setSelectedmobile(e.target.value);
                  }}
                  placeholder="Enter your mobile*"
                  size="small"
                  style={{
                    width: "70%",
                    backgroundColor: "white",
                    borderWidth: 0,
                    fontSize: 18,
                    padding: 6,
                    textAlign: "center",
                  }}
                />
              </div>
              <div className="flex justify-center pt-5 ">
                <div style={{ width: "70%" }}>
                  <Select
                    showSearch
                    style={{
                      backgroundColor: "grey",
                      borderWidth: 0,
                      fontSize: 18,
                      textAlign: "center",
                      borderRadius: 5,
                      width: "100%",
                      height: 36,
                    }}
                    placeholder="Select State*"
                    optionFilterProp="children"
                    onChange={(value) => {
                      handleStateChange(value);
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {indianStates.map((state) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex justify-center pt-5 ">
                <div style={{ width: "70%" }}>
                  <Select
                    showSearch
                    style={{
                      backgroundColor: "grey",
                      borderWidth: 0,
                      fontSize: 18,
                      textAlign: "center",
                      borderRadius: 5,
                      width: "100%",
                      height: 36,
                    }}
                    placeholder="Select City*"
                    optionFilterProp="children"
                    onChange={(value) => setSelectedCity(value)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cities.map((city) => (
                      <Option key={city} value={city}>
                        {city}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex justify-center pt-5 ">
                <Input
                  // maxLength={10}
                  minLength={10}
                  onChange={(e) => {
                    setBusinessDetails(e.target.value);
                  }}
                  placeholder="Enter your query*"
                  size="small"
                  style={{
                    width: "70%",
                    backgroundColor: "white",
                    borderWidth: 0,
                    fontSize: 18,
                    padding: 6,
                    textAlign: "center",
                  }}
                />
              </div>
              <div className="flex justify-center pt-8 ">
                <a
                  onClick={() => handleSubmitRequest()}
                  style={{
                    backgroundColor: "#B91C1B",
                    width: "70%",
                    textAlign: "center",
                    color: "white",
                    borderRadius: 5,
                    padding: 7,
                    cursor: "pointer",
                  }}
                >
                  Submit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Header */}
      <div className="w-full" style={{ position: "absolute", top: 0 }}>
        <div
          style={{
            background:
              "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
          }}
          className="px-5 sm:px-14 flex shadow-xl justify-between fixed w-full"
        >
          <div className="block sm:hidden mt-2">
            <i
              onClick={() => setNavbarOpen(!navbarOpen)}
              class="fa-solid fa-bars-staggered"
              style={{
                color: "white",
                fontSize: 30,
                fontWeight: 600,
                marginTop: 18,
              }}
            ></i>
          </div>
          <div>
            <a
              href="/"
              // className="text-right sm:text-left flex"
              style={{
                color: "white",
                // fontSize: 30,
                // fontWeight: 600,
                cursor: "pointer",
              }}
            >
              <img
                className="sm:block hidden mr-5 "
                alt="Images"
                style={{ height: 115, width: 125 }}
                src="../../static/ElectricWeb-Dark-Logo.png"
              />
              <img
                className="block sm:hidden mr-3 my-2"
                alt="Images"
                style={{ height: 70, width: 75 }}
                src="../../static/ElectricWeb-Dark-Logo.png"
              />
              {/* ElectricWeb */}
            </a>
          </div>

          {/* Navigation For Pc Scrren */}
          <div className="hidden sm:flex  justify-center">
            <div className="hidden sm:flex w-full h-full pt-10 flex-row border-0">
              <div id="wrapper">
                <div
                  id="button"
                  className="hover:text-blue-700 text-xl text-white cursor-pointer font-serif pb-5 px-6"
                >
                  Company
                  <i
                    style={{ marginLeft: 10 }}
                    class="fa-solid fa-caret-down"
                  ></i>
                  <div
                    id="drop"
                    style={{ borderRadius: 5, overflow: "hidden" }}
                    className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                  >
                    <a
                      href="aboutus"
                      className="cursor-pointer rounded font-serif text-blue-700"
                      // id="drop"
                    >
                      About us
                      <p
                        className="text-sm"
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        EW stands for reliable, sustainable & cost-effective
                        charging services.
                      </p>
                      {/* <p className="h-4"></p> */}
                    </a>
                    {/* <a
                    href="aboutus"
                    className="cursor-pointer  rounded text-blue-700"
                    // id="drop"
                  >
                    Products
                    <p
                      className="text-sm"
                      style={{
                        textWrap: "wrap",
                        color: "#545454",
                        width: "100%",
                      }}
                    >
                      hdqgduyiq hiqudhquiodj hiohquihi uguihuiqhuiq
                      dhqiudhioqhioq
                    </p>
                  </a> */}
                  </div>
                </div>
              </div>
              <div id="wrapper">
                <div
                  id="button"
                  className="cursor-pointer text-xl font-serif hover:text-blue-700 text-white pb-5 px-6"
                >
                  Business
                  <i
                    style={{ marginLeft: 10 }}
                    class="fa-solid fa-caret-down"
                  ></i>
                  <div
                    id="drop"
                    style={{ borderRadius: 5, overflow: "hidden" }}
                    className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                  >
                    <a
                      href="services"
                      className="font-serif cursor-pointer rounded text-blue-700"
                      // id="drop"
                    >
                      Services
                      <p
                        className="text-sm"
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        Software backbone for seamless charging and monitoring
                      </p>
                      <p className="h-4"></p>
                    </a>
                    <a
                      href="products"
                      className="cursor-pointer font-serif rounded text-blue-700"
                      // id="drop"
                    >
                      Products
                      <p
                        className="text-sm "
                        style={{
                          textWrap: "wrap",
                          color: "#545454",
                          width: "100%",
                        }}
                      >
                        Custom built hardware to democratize EV adoption.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div id="wrapper">
                <div id="button" className="text-xl cursor-pointer px-6">
                  <a
                    href="partner"
                    className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Partner
                  </a>
                </div>
              </div>
              <div id="wrapper">
                <div
                  id="button"
                  className="text-xl font-serif cursor-pointer px-6"
                >
                  <a
                    href="resources"
                    className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Resources
                  </a>
                </div>
              </div>

              <div id="wrapper">
                <div
                  id="button"
                  className="text-xl font-serif cursor-pointer px-6"
                >
                  <a
                    href="dashboard"
                    className="text-white w-max font-serif h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:block hidden" style={{ height: 115 }}></div>
        <div className="block sm:hidden" style={{ height: 80 }}></div>

        {/* Navigation For Mobile Scrren */}
        <div
          className="flex-wrap flex-row justify-center shadow-2xl fixed top-20 w-full"
          style={{
            display: navbarOpen ? "block" : "none",
            backgroundColor: "white",
            paddingTop: 25,
          }}
        >
          {/* <a onClick={() => setNavbarOpen(false)} className="absolute right-6">
          <i class="fa-regular text-2xl fa-rectangle-xmark"></i>
        </a> */}

          <div id="wrapper">
            <div
              id="button"
              className="hover:text-blue-700 text-left cursor-pointer font-serif pb-5 px-6"
            >
              Company
              <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
              <div
                id="dropMobile"
                style={{ borderRadius: 5, overflow: "hidden" }}
                className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
              >
                <a
                  href="aboutus"
                  className="cursor-pointer rounded font-serif text-blue-700"
                  // id="drop"
                >
                  About us
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    EW stands for reliable, sustainable & cost-effective
                    charging services.
                  </p>
                  {/* <p className="h-4"></p> */}
                </a>
              </div>
            </div>
          </div>

          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              Business
              <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
              <div
                id="dropMobile"
                style={{ borderRadius: 5, overflow: "hidden" }}
                className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
              >
                <a
                  href="services"
                  className="cursor-pointer rounded  font-serif text-blue-700"
                  // id="drop"
                >
                  Services
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    Software backbone for seamless charging and monitoring.
                  </p>
                  <p className="h-4"></p>
                </a>
                <a
                  href="products"
                  className="cursor-pointer rounded font-serif text-blue-700"
                  // id="drop"
                >
                  Products
                  <p
                    className="text-sm"
                    style={{
                      textWrap: "wrap",
                      color: "#545454",
                      width: "100%",
                    }}
                  >
                    Custom built hardware to democratize EV adoption.
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="partner">Partner</a>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="resources">Resources</a>
            </div>
          </div>
          <div id="wrapper" className="">
            <div
              id="button"
              className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
            >
              <a href="dashboard">Login</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PartnerPage;
