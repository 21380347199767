import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const FeedbackScreen = () => {
  const [loading, setLoading] = useState(false);
  const [requests, setNewRfidRequests] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [unchangedRequests, setUnchangedRequests] = useState();
  const [formattedRequestsData, setFormattedRequestsData] = useState();
  const [selectedRequest, setSelectedRequest] = useState();
  const [editRequestModalOpen, setEditRequestModalOpen] = useState();
  const [cardIssuedAmount, setCardIssuedAmount] = useState(0);
  const [rfidBlockedAmount, setRfidBlockedAmount] = useState(0);
  const [idTag, setIdTag] = useState();

  useEffect(() => {
    getnewRfidRequests();
  }, []);

  const getnewRfidRequests = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/new/rfid/requests`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setNewRfidRequests(res?.data?.requests);
        setUnchangedRequests(res?.data?.requests);
        formatrequestData(res?.data?.requests);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formatrequestData = (requests) => {
    setLoading(true);
    try {
      let array = requests;
      array?.map((request) => {
        request.time = request?.createdAt?.split("T")[0];
        request.address = request?.addressLine1 + ", " + request?.addressLine2;
      });
      setLoading(false);
      return setFormattedRequestsData(array);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    formatrequestData(unchangedRequests);
  }, [unchangedRequests]);

  const columns = [
    {
      title: "Date",
      dataIndex: "time",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.sortingDate - b.sortingDate,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Pin Code",
      dataIndex: "pinCode",
    },
    {
      title: "Status",
      dataIndex: "status",
    },

    {
      title: "  Action",
      fixed: "right",
      // width: 100,
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            className="m-5"
            onClick={() => {
              setSelectedRequest(record);
              setEditRequestModalOpen(true);
            }}
          >
            <i
              style={{ color: "blue" }}
              class="fa-regular fa-pen-to-square"
            ></i>
          </a>
        </div>
      ),
    },
  ];

  const [selectedStatus, setSelectedStatus] = useState();

  const handleEditRequest = async () => {
    setLoading(true);
    try {
      if (!idTag && selectedStatus != "Delivered" && selectedStatus != "Delete") {
        setLoading(false);
        return message.warning("IdTag info not found...");
       
      } else {
        const res = await axios.post(`${BaseUrl}/edit/rfid/request`, {
          token: localStorage.getItem("token"),
          _id: selectedRequest?._id,
          status: selectedStatus,
          rfidBlockedAmount,
          cardIssuedAmount,
          idTag,
        });
        if (res?.data?.success) {
          getnewRfidRequests();
          message.success(res?.data?.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setIdTag()
        setCardIssuedAmount(0)
        setRfidBlockedAmount(0)
      }
    } catch (error) {
      setIdTag()
      setCardIssuedAmount(0)
      setRfidBlockedAmount(0)
      setLoading(false);
      console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Confirm delete Location Modal */}
      <Modal
        // title="Confirm"
        centered
        open={editRequestModalOpen}
        onCancel={() => {
          getnewRfidRequests();
          setEditRequestModalOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              // getnewRfidRequests();
              setEditRequestModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            primary
            onClick={() => {
              setEditRequestModalOpen(false);
              handleEditRequest();
            }}
          >
            Update
          </Button>,
        ]}
      >
        <p className=" text-center p-4 pt-8">
          Change {selectedRequest?.name}'s RFID card status from{" "}
          {selectedRequest?.status} to
        </p>

        <div className="flex w-full justify-center pt-3 pb-2">
          <Form.Item>
            <div className=" flex justify-center mt-3">
              <p style={{ width: 220 }} className=" text-center pt-1 ">
                Status
              </p>
              <p className=" text-center pt-1 ">:</p>
              <p style={{ width: 220 }} className=" text-center pt-0 ">
                <Select
                  style={{ width: 100 }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={selectedRequest?.status}
                  onChange={(value) => setSelectedStatus(value)}
                >
                  {selectedRequest?.status === "Pending" ? (
                    <>
                      <option value={"Issued"}>Issued</option>
                      <option value={"Delete"}>Delete</option>
                    </>
                  ) : (
                    <option value={"Delivered"}>Delivered</option>
                  )}
                </Select>
              </p>
            </div>

            {selectedStatus === "Issued" ? (
              <>
                <div className=" flex justify-center mt-3">
                  <p style={{ width: 220 }} className=" text-center pt-1 ">
                    RFID Card Info
                  </p>
                  <p className=" text-center pt-1 ">:</p>
                  <p style={{ width: 220 }} className=" text-center pt-0 ">
                    <Input
                      className="text-center"
                      // maxLength={4}
                      // showCount
                      placeholder={0}
                      onChange={(e) => {
                        setIdTag(e?.target?.value);
                      }}
                      style={
                        {
                          width: 100,
                        }
                      }
                    />
                  </p>
                </div>
                <div className=" flex justify-center mt-3">
                  <p style={{ width: 220 }} className=" text-center pt-1 ">
                    RFID Issued Amount
                  </p>
                  <p className=" text-center pt-1 ">:</p>
                  <p style={{ width: 220 }} className=" text-center pt-0 ">
                    <InputNumber
                      className="text-center"
                      maxLength={4}
                      showCount
                      placeholder={0}
                      onChange={(e) => {
                        setCardIssuedAmount(e);
                      }}
                      style={
                        {
                          // width: 120,
                        }
                      }
                    />
                  </p>
                </div>
                <div className=" flex justify-center mt-3">
                  <p style={{ width: 220 }} className=" text-center pt-1 ">
                    RFID Blocked Amount
                  </p>
                  <p className=" text-center pt-1 ">:</p>
                  <p style={{ width: 220 }} className=" text-center pt-0 ">
                    <InputNumber
                      className="text-center"
                      maxLength={4}
                      showCount
                      placeholder={0}
                      onChange={(e) => {
                        setRfidBlockedAmount(e);
                      }}
                      style={
                        {
                          // width: 120,
                        }
                      }
                    />
                  </p>
                </div>
              </>
            ) : (
              ""
            )}
          </Form.Item>
        </div>
      </Modal>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          RFID Card Requests
        </p>
      </div>

      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getnewRfidRequests();
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getnewRfidRequests();
              setfilterSet(false);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>
      {/* Showing Locations */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formattedRequestsData && formattedRequestsData}
        />
      </div>
    </div>
  );
};

export default FeedbackScreen;
