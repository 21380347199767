import React from "react";

const LoadingComponent = ({ title }) => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div>
        <i
          style={{ fontSize: 20 }}
          className="fa-solid fa-spinner fa-spin-pulse fa-2xl"
        ></i>
      </div>
      {title ? <p className="mt-5 text-gray-500">{title}</p> : ""}
    </div>
  );
};

export default LoadingComponent;
