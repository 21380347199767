import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./Screens";
import AboutUsScreen from "./Screens/AboutUsScreen";
import ServicesScreen from "./Screens/ServicesScreen";
import ProductsScreen from "./Screens/ProductsScreen";
import PartnerPage from "./Screens/PartnerPage";
import ResourcesScreen from "./Screens/ResourcesScreen";
import DeleteAccountScreen from "./Screens/DeleteAccountScreen";
import DownloadApplicationScreen from "./Screens/DownloadApplicationScreen";
import LayoutComponent from "./Components/LayoutComponent";
import RateAndReviewScreen from "./Screens/RateAndReviewApplicationScreen";

function App() {
  return (
    <div style={{ userSelect: "none" }} className="App ">
      {/* <Provider store={store}> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/aboutus" element={<AboutUsScreen />} />
          <Route path="/services" element={<ServicesScreen />} />
          <Route path="/products" element={<ProductsScreen />} />
          <Route path="/partner" element={<PartnerPage />} />
          <Route path="/resources" element={<ResourcesScreen />} />
          <Route path="/app/download" element={<DownloadApplicationScreen />} />
          <Route path="/rate/us" element={<RateAndReviewScreen />} />
          <Route path="/deleteAccount" element={<DeleteAccountScreen />} />
          <Route path="/dashboard" element={<LayoutComponent />} />
        </Routes>
      </BrowserRouter>
      {/* </Provider> */}
    </div>
  );
}

export default App;
