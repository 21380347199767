import React, { useState } from "react";
import "./HeaderCss.css";

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <div className="w-screen">
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,46,100,1) 48%, rgba(60,66,140,1) 100%)",
        }}
        className="px-5 sm:px-14 flex shadow-xl justify-between fixed w-full"
      >
        <div className="block sm:hidden mt-2">
          <i
            onClick={() => setNavbarOpen(!navbarOpen)}
            class="fa-solid fa-bars-staggered"
            style={{
              color: "white",
              fontSize: 30,
              fontWeight: 600,
              marginTop: 18,
            }}
          ></i>
        </div>
        <div>
          <a
            href="/"
            // className="text-right sm:text-left flex"
            style={{
              color: "white",
              // fontSize: 30,
              // fontWeight: 600,
              cursor: "pointer",
            }}
          >
            <img
                className="sm:block hidden mr-5 "
                alt="Images"
                style={{ height: 115, width: 125 }}
                src="../../static/ElectricWeb-Dark-Logo.png"
              />
            <img
              className="block sm:hidden mr-3 my-2"
              alt="Images"
              style={{ height: 70, width: 75 }}
              src="../../static/ElectricWeb-Dark-Logo.png"
            />
            {/* ElectricWeb */}
          </a>
        </div>

        {/* Navigation For Pc Scrren */}
        <div className="hidden sm:flex  justify-center">
          <div className="hidden sm:flex w-full h-full pt-10 flex-row border-0">
            <div id="wrapper">
              <div
                id="button"
                className="hover:text-blue-700 text-xl text-white cursor-pointer font-serif pb-5 px-6"
              >
                Company
                <i
                  style={{ marginLeft: 10 }}
                  class="fa-solid fa-caret-down"
                ></i>
                <div
                  id="drop"
                  style={{ borderRadius: 5, overflow: "hidden" }}
                  className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                >
                  <a
                    href="aboutus"
                    className="cursor-pointer rounded font-serif text-blue-700"
                    // id="drop"
                  >
                    About us
                    <p
                      className="text-sm"
                      style={{
                        textWrap: "wrap",
                        color: "#545454",
                        width: "100%",
                      }}
                    >
                      EW stands for reliable, sustainable & cost-effective
                      charging services.
                    </p>
                    {/* <p className="h-4"></p> */}
                  </a>
                  {/* <a
                    href="aboutus"
                    className="cursor-pointer  rounded text-blue-700"
                    // id="drop"
                  >
                    Products
                    <p
                      className="text-sm"
                      style={{
                        textWrap: "wrap",
                        color: "#545454",
                        width: "100%",
                      }}
                    >
                      hdqgduyiq hiqudhquiodj hiohquihi uguihuiqhuiq
                      dhqiudhioqhioq
                    </p>
                  </a> */}
                </div>
              </div>
            </div>
            <div id="wrapper">
              <div
                id="button"
                className="cursor-pointer font-serif text-xl hover:text-blue-700 text-white pb-5 px-6"
              >
                Business
                <i
                  style={{ marginLeft: 10 }}
                  class="fa-solid fa-caret-down"
                ></i>
                <div
                  id="drop"
                  style={{ borderRadius: 5, overflow: "hidden" }}
                  className="bg-white shadodw-2xl px-6 py-6 shadow-xl mt-3"
                >
                  <a
                    href="services"
                    className="font-serif cursor-pointer rounded text-blue-700"
                    // id="drop"
                  >
                    Services
                    <p
                      className="text-sm"
                      style={{
                        textWrap: "wrap",
                        color: "#545454",
                        width: "100%",
                      }}
                    >
                      Software backbone for seamless charging and monitoring
                    </p>
                    <p className="h-4"></p>
                  </a>
                  <a
                    href="products"
                    className="cursor-pointer font-serif rounded text-blue-700"
                    // id="drop"
                  >
                    Products
                    <p
                      className="text-sm "
                      style={{
                        textWrap: "wrap",
                        color: "#545454",
                        width: "100%",
                      }}
                    >
                      Custom built hardware to democratize EV adoption.
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div id="wrapper">
              <div id="button" className="text-xl cursor-pointer px-6">
                <a
                  href="partner"
                  className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                >
                  Partner
                </a>
              </div>
            </div>
            <div id="wrapper">
              <div
                id="button"
                className="text-xl font-serif cursor-pointer px-6"
              >
                <a
                  href="resources"
                  className="text-white font-serif w-max h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                >
                  Resources
                </a>
              </div>
            </div>

            <div id="wrapper">
              <div
                id="button"
                className=" text-xl font-serif cursor-pointer px-6"
              >
                <a
                  href="dashboard"
                  className="text-white w-max font-serif h-max p-2 mx-1 cursor-pointer hover:bg-white hover:text-black rounded-lg"
                >
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:block hidden" style={{ height: 115 }}></div>
      <div className="block sm:hidden" style={{ height: 80 }}></div>

      {/* Navigation For Mobile Scrren */}
      <div
        className="flex-wrap flex-row justify-center shadow-2xl fixed top-20 w-full"
        style={{
          display: navbarOpen ? "block" : "none",
          backgroundColor: "white",
          paddingTop: 25,
        }}
      >
        {/* <a onClick={() => setNavbarOpen(false)} className="absolute right-6">
          <i class="fa-regular text-2xl fa-rectangle-xmark"></i>
        </a> */}

        <div id="wrapper">
          <div
            id="button"
            className="hover:text-blue-700 text-left cursor-pointer font-serif pb-5 px-6"
          >
            Company
            <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
            <div
              id="dropMobile"
              style={{ borderRadius: 5, overflow: "hidden" }}
              className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
            >
              <a
                href="aboutus"
                className="cursor-pointer rounded font-serif text-blue-700"
                // id="drop"
              >
                About us
                <p
                  className="text-sm"
                  style={{
                    textWrap: "wrap",
                    color: "#545454",
                    width: "100%",
                  }}
                >
                  EW stands for reliable, sustainable & cost-effective charging
                  services.
                </p>
                {/* <p className="h-4"></p> */}
              </a>
            </div>
          </div>
        </div>

        <div id="wrapper" className="">
          <div
            id="button"
            className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
          >
            Business
            <i style={{ marginLeft: 10 }} class="fa-solid fa-caret-down"></i>
            <div
              id="dropMobile"
              style={{ borderRadius: 5, overflow: "hidden" }}
              className="bg-white w-full shadodw-2xl ml-4 mt-2 pt-1"
            >
              <a
                href="services"
                className="cursor-pointer rounded  font-serif text-blue-700"
                // id="drop"
              >
                Services
                <p
                  className="text-sm"
                  style={{
                    textWrap: "wrap",
                    color: "#545454",
                    width: "100%",
                  }}
                >
                  Software backbone for seamless charging and monitoring.
                </p>
                <p className="h-4"></p>
              </a>
              <a
                href="products"
                className="cursor-pointer rounded font-serif text-blue-700"
                // id="drop"
              >
                Products
                <p
                  className="text-sm"
                  style={{
                    textWrap: "wrap",
                    color: "#545454",
                    width: "100%",
                  }}
                >
                  Custom built hardware to democratize EV adoption.
                </p>
              </a>
            </div>
          </div>
        </div>
        <div id="wrapper" className="">
          <div
            id="button"
            className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
          >
            <a href="partner">Partner</a>
          </div>
        </div>
        <div id="wrapper" className="">
          <div
            id="button"
            className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
          >
            <a href="resources">Resources</a>
          </div>
        </div>
        <div id="wrapper" className="">
          <div
            id="button"
            className="hover:text-blue-700  text-left  cursor-pointer font-serif pb-5 px-6"
          >
            <a href="dashboard">Login</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
