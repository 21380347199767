import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import LoadingComponent from "../../Components/LoadingComponent";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const PartnerQueryScreen = () => {
  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState();
  const [filterSet, setfilterSet] = useState(false);
  const [unchangedFeedback, setUnchangedFeedbacks] = useState();
  const [formatedFeedbacksData, setformatedFeedbacksData] = useState();
  const [selectedFeedback, setSelectedFeedback] = useState();
  const [showFeedbackDeleteModal, setShowFeedbackDeleteModal] = useState();

  useEffect(() => {
    getFeedbacks();
  }, []);
  const getFeedbacks = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/partner/queries`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setQueries(res?.data?.queries);
        setUnchangedFeedbacks(res?.data?.queries);
        formatFeedbacksData(res?.data?.queries);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function convertTo12HourFormat(timeString) {
    // Parse the time string into a Date object
    const time = new Date(`2023-09-10T${timeString}`);

    // Get the hours, minutes, and seconds
    const hours = time.getHours();
    const minutes = time.getMinutes();
    // const seconds = time.getSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time in 12-hour format
    const formattedTime = `${hours12 < 10 ? "0" + hours12 : hours12}:${String(
      minutes < 10 ? "0" + minutes : minutes
    )} ${period}`;

    return formattedTime;
  }
  const formatFeedbacksData = (feedbacks) => {
    setLoading(true);
    try {
      let array = feedbacks;
      array?.map((feedback) => {
        feedback.time =
          feedback.time.split(" ")[1] +
          "/" +
          feedback.time.split(" ")[2] +
          "/" +
          feedback.time.split(" ")[3] +
          " " +
          convertTo12HourFormat(feedback.time.split(" ")[4]);
      });
      setLoading(false);
      return setformatedFeedbacksData(array);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "time",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.sortingDate - b.sortingDate,
      width: 120,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Business Details",
      dataIndex: "businessDetails",
    },
    {
      title: "  Action",
      fixed: "right",
      // width: 100,
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            className="m-5"
            onClick={() => {
              setSelectedFeedback(record);
              setShowFeedbackDeleteModal(true);
            }}
          >
            <i style={{ color: "red" }} class="fa-solid fa-trash-can"></i>
          </a>
        </div>
      ),
    },
  ];

  const handleDeleteFeedback = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/delete/partner/query`, {
        token: localStorage.getItem("token"),
        _id: selectedFeedback?._id,
      });
      if (res?.data?.success) {
        setLoading(false);
        getFeedbacks();
        message.success(res?.data?.message);
      } else {
        setLoading(false);
        message.warning(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      {/* Confirm delete Location Modal */}
      <Modal
        // title="Confirm"
        centered
        open={showFeedbackDeleteModal}
        onCancel={() => {
          getFeedbacks();
          setShowFeedbackDeleteModal(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setShowFeedbackDeleteModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            danger
            onClick={() => {
              setShowFeedbackDeleteModal(false);
              handleDeleteFeedback();
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Are you sure to delete {selectedFeedback && selectedFeedback?.name}'s
          Partner Query ?
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Partner Queries
        </p>
      </div>

      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getFeedbacks();
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getFeedbacks();
              setfilterSet(false);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>
      {/* Showing Locations */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={formatedFeedbacksData && formatedFeedbacksData}
          scroll={{
            y: 600,
          }}
        />
      </div>
    </div>
  );
};

export default PartnerQueryScreen;
