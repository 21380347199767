export const BaseUrl = "https://acdcelectric.in/api/v1";

export const websocketConnectionUrl = "wss://acdcelectric.in";

// "http://ec2-3-6-73-151.ap-south-1.compute.amazonaws.com:9099/api/v1"
// 'ws://ec2-3-6-73-151.ap-south-1.compute.amazonaws.com:9099'

// "https://acdcelectric.in/api/v1"

//  "http://localhost:9099/api/v1";
