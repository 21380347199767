import { useEffect, useState } from "react";
import React from "react";
import LayoutComponent from "../Components/LayoutComponent";
import { InputNumber, message, Modal, Button, Input } from "antd";
import axios from "axios";
import { BaseUrl } from "../static/BaseUrl";

const DeleteAccountScreen = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [otp, setOTP] = useState("");

  const handleChange = (e) => {
    setOTP(e.target.value);
  };

  const deleteAccountRequestOtp = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/send/otp/delete/account`, {
        phone: mobileNumber,
      });
      if (res?.data?.success) {
        setModalOpen(true);
        message.success(res?.data?.message);
      } else {
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/delete/account/from/website`, {
        phone: mobileNumber,
        OTP: otp,
      });
      if (res?.data?.success) {
        setModalOpen(false);
        message.success("Account deleted...");
      } else {
        message?.warning(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{ justifyContent: "center", alignItems: "center" }}
      className="flex flex-col w-full h-screen"
    >
      {/* Update CGL Fees Modal */}
      <Modal
        centered
        open={modalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Close
          </Button>,
          <Button
            danger
            onClick={() => {
              setModalOpen(false);
              handleDeleteAccount();
            }}
          >
            Delete Account
          </Button>,
        ]}
      >
        <div className="flex flex-col w-full justify-center pt-3 pb-2">
          <p className="text-center font-bold">
            Are you sure you want to delete your account?
          </p>
          <p className="text-center mt-3">
            If you're sure you want to proceed, please enter the OTP (One Time
            Password) sent to your registered email or phone number below:
          </p>

          <div className="flex justify-center mt-10">
            <Input
              maxLength={6}
              placeholder="Enter OTP"
              value={otp}
              onChange={handleChange}
              style={{ width: 200, marginBottom: 16, textAlign: "center" }}
            />
          </div>
        </div>
      </Modal>
      <p className="text-center mb-28 text-4xl font-bold">
        Delete ElectricWeb Account
      </p>

      <div className="flex">
        <p className="text-center mt-3 mr-5">Enter Mobile number : </p>
        <InputNumber
          onChange={(text) => {
            setMobileNumber(text);
          }}
          maxLength={10}
          minLength={10}
          style={{
            width: 150,
            marginTop: 10,
          }}
          controls={false}
        ></InputNumber>
      </div>
      <a
        onClick={() =>
          mobileNumber?.toString()?.length === 10
            ? deleteAccountRequestOtp()
            : ""
        }
        style={{
          backgroundColor:
            mobileNumber?.toString()?.length === 10 ? "red" : "gray",
          color: "white",
          padding: 5,
          borderRadius: 5,
          marginTop: 30,
          cursor: "pointer",
        }}
        className=""
      >
        Delete Account
      </a>
    </div>
  );
};

export default DeleteAccountScreen;
