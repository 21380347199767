import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../static/BaseUrl.js";
import { message } from "antd";
import { DatePicker, Table, Select } from "antd";
const Option = Select.Option;

const SendPushNotification = () => {
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [users, setUsers] = useState();
  const [imageUri, setImageUri] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [tableData, setTableData] = useState([]);

  const getUsers = async () => {
    // setLoading(true);
    try {
      const res = await axios.post(`${BaseUrl}/get/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const fromatTableData = async (selectedUsers) => {
    try {
      let resultedArray = [];

      users?.map((user) => {
        selectedUsers?.map((selectedUser) => {
          if (user?.phone === selectedUser) {
            resultedArray.push({
              userDetails: `${user?.name} ${user?.phone} ( ₹ ${user?.walletAmount} )`,
              phone: user?.phone,
            });
          }
        });
      });
      setTableData(resultedArray);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: `email`,
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Wallet Amount",
      dataIndex: "walletAmount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.walletAmount - b.walletAmount,
    },
    {
      title: "  Action",
      fixed: "right",
      // width: 100,
    },
  ];
  useEffect(() => {
    getUsers();
  }, []);

  const handleSendPushNotifications = async () => {
    try {
      if (!title || !body) {
        message.warning("Enter title and body of notification.");
      } else {
        if (selectedUsers?.length) {
          const res = await axios.post(`${BaseUrl}/send/push/notification`, {
            token: localStorage?.getItem("token"),
            title: title,
            body: body,
            imageUri: imageUri || undefined,
            sentToAllUsers: false,
            selectedUsers,
          });
          if (res?.data?.success) {
            message.success("Notifications sent Successfully");
          } else {
            message?.warning(res?.data?.message);
          }
        } else {
          const res = await axios.post(`${BaseUrl}/send/push/notification`, {
            token: localStorage?.getItem("token"),
            title: title,
            body: body,
            imageUri: imageUri || undefined,
            sentToAllUsers: true,
          });
          if (res?.data?.success) {
            message.success("Notifications sent Successfully");
          } else {
            message?.warning(res?.data?.message);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* Heading */}
      <div className="w-full flex flex-col sm:flex-row justify-center sm:justify-start">
        <p style={{ marginTop: 4 }} className="font-bold text-lg text-center">
          Send Push Notifications
        </p>
      </div>
      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        {/* State */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>Select User</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 300 }}
            placeholder="Select User"
            optionFilterProp="children"
            onChange={(value) => {
              let users = selectedUsers;
              if (!selectedUsers?.includes(value)) {
                users.push(value);
              }
              setSelectedUsers(users);
              fromatTableData(users);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {users &&
              users?.map((user) => {
                return (
                  <Option style={{ fontSize: 12 }} value={user?.phone}>
                    {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
      <div
        style={{ display: tableData ? "flex" : "none" }}
        className="flex mt-5 justify-start flex-wrap"
      >
        {tableData?.map((data) => {
          return (
            <p
              style={{ fontSize: 12, backgroundColor: "#ADD8E6" }}
              className="my-1 ml-4 px-2 py-1 rounded-2xl text-gray-700 shadow-sm font-semi-bold"
            >
              {data?.userDetails}
              <a>
                <i
                  onClick={() => {
                    const newArray = selectedUsers.filter(
                      (phone) => phone !== data?.phone
                    );

                    const deleteUser = tableData?.filter(
                      (data1) => data1 !== data
                    );

                    setSelectedUsers(newArray);
                    setTableData(deleteUser);
                  }}
                  class="fa-solid fa-xmark mx-2"
                ></i>
              </a>
            </p>
          );
        })}
      </div>
      <div className="flex flex-col justify-around">
        <div>
          <div className="mt-10 flex justify-center">
            <p className="text-xl"> Title</p>
          </div>
          <div className="mt-2 flex justify-center">
            <textarea
              style={{
                borderColor: "grey",
                borderWidth: 2,
                borderRadius: 10,
                padding: 5,
                width: "80%",
                textAlign: "center",
                lineHeight: 2,
                placeholder: "Enter notification title",
              }}
              autoComplete="on"
              autoCapitalize="on"
              autoFocus={true}
              placeholder="Enter notification title"
              rows={2}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="mt-10 flex justify-center">
            <p className="text-xl"> Body</p>
          </div>
          <div className="mt-2 flex justify-center">
            <textarea
              style={{
                borderColor: "grey",
                borderWidth: 2,
                borderRadius: 10,
                padding: 5,
                width: "80%",
                textAlign: "center",
                lineHeight: 2,
              }}
              placeholder="Enter notification body"
              autoComplete="on"
              autoCapitalize="on"
              autoFocus={true}
              rows={4}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="mt-10 flex justify-center">
            <p> Image Uri ( optional )</p>
          </div>
          <div className="mt-2 flex justify-center">
            <input
              onChange={(e) => setImageUri(e.target.value)}
              style={{
                borderColor: "grey",
                borderWidth: 2,
                borderRadius: 10,
                padding: 5,
                width: 330,
                textAlign: "center",
                lineHeight: 2,
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-2 flex justify-center">
        {imageUri ? (
          <img
            style={{
              height: 120,
              width: 120,
            }}
            src={imageUri}
          />
        ) : (
          ""
        )}
      </div>
      <div className="mt-8 flex justify-center">
        <button
          onClick={() => handleSendPushNotifications()}
          style={{
            backgroundColor: "green",
            color: "white",
            padding: 12,
            borderRadius: 10,
            paddingRight: 30,
            paddingLeft: 30,
          }}
        >
          Send
        </button>
      </div>
    </>
  );
};

export default SendPushNotification;
